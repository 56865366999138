import React, { useState } from 'react'
import { Grid, TextField, Autocomplete, Box, Fab, } from '@mui/material'
import AutoComplete from '../../components/AutoComplete'
import CheckboxesMultiSelectTags from '../../components/CheckBoxesMultiSelectTags'
import Button from '@mui/material/Button';

import { useDispatch } from 'react-redux';


import { useLocalStorage } from '../../custom-hook/useLocalStorage';
import { GetAllFolders_Json_Redux, GetClientsByFolder_Json_Redux, Json_GetClientCardDetails_Redux } from '../../redux/api_helper';


function CreateRequest() {
  const [chgObj, setChgObj] = React.useState({});

  let objReq = useLocalStorage("accid");

  let dispatch = useDispatch();
  const [getFolder, setGetFolders] = React.useState([]);

  const [portalUser, setPortalUser] = React.useState([]);
  const [portalUserCC, setPortalUserCC] = React.useState([]);
  const [portalUserTo, setPortalUserTo] = React.useState([]);

  React.useEffect(() => {
    dispatch(GetAllFolders_Json_Redux(function (res) {
      console.log("GetAllFolders_Json11111", res);
      setGetFolders(res);
    }))
    getAllClientByFolderid({ folder: localStorage.getItem("ProjectId") })



  }, [dispatch])

  const getFolderObject = (event, value) => {
    console.log(event, value)
    setChgObj(value);
    getAllClientByFolderid({ folder: value.FolderID });
  }
  const getClientObject = (event, value) => {
    console.log(event, value);
    GetPortlEmailList(value?.ClientID)
  }
  function GetPortlEmailList(cid) {

    try {
      let obj = useLocalStorage("required");
      let o = {
        intProjectId: chgObj.FolderID ? chgObj.FolderID : localStorage.getItem("FolderId"),
        strOrignatorNumber: cid
      }
      let p = { ...obj, ...o };
      dispatch(Json_GetClientCardDetails_Redux(p, function (data) {
        if (data) {

          let tble6 = data.Table6;
          if (tble6.length > 0) {
            let filteredUsers = tble6.filter(el => el["Portal User"] === true && el["Portal User"] !== null);
            if (filteredUsers.length > 0) {


              setPortalUser(filteredUsers.length > 0 ? filteredUsers : null);
              setPortalUserCC(filteredUsers.length > 0 ? filteredUsers : null);
              setPortalUserTo(filteredUsers.length > 0 ? filteredUsers : null);

            }
            console.log("Json_GetClientCardDetails111", filteredUsers);
          }
          else {
            // setPortalUser([]);
          }
        }
      }))
    } catch (error) {
      console.log("Network error: No response received from server, Json_GetClientCardDetails", error)
    }
  }

  const [getClient, setGetClient] = useState([]);
  function getAllClientByFolderid(pid) {
    let p = { ...objReq, ...pid }
    // dispatch(GetClientsByFolder_Json_Redux(p, function (res) {
    //   console.log("GetClientsByFolder_Json_Redux", res);
    //   setGetClient(res);
    // }))

  }

  function CreateRequest() {
    return (
      <Box className='mt-5 pt-4'>
        <Box className="container">
          <Box className="row">
            <Box className='col-md-6 m-auto'>
              <Box className='white-box'>


                <h2 className='title'>Create Request</h2>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box className="custom-autocomplete">
                      <AutoComplete ComData={getFolder} target="Folder" comFun={getFolderObject} lable="Folders"></AutoComplete>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="custom-autocomplete">
                      <AutoComplete ComData={getClient} target="Client" comFun={getClientObject} lable="Clients" ></AutoComplete>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {portalUserTo && portalUserTo.length > 0 && (
                      <CheckboxesMultiSelectTags portalUserTo={portalUserTo} label={'To'} />
                    )}

                  </Grid>
                </Grid>

                <hr />

                <Box className='mt-4'>
                  <Grid item xs={12}>
                    {/* <CheckboxesMultiSelectTags label={'Select Template'} /> */}
                  </Grid>
                </Box>

                <Box className="table-responsive mt-4">
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th className='text-center'>Expire In</th>
                        <th className='text-center'>Remind In</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array(5).fill("").map(() => {
                        return <>
                          <tr>
                            <td>Bank statement (Last 3 months)</td>
                            <td className='text-center'>30</td>
                            <td className='text-center'>2</td>
                          </tr>
                        </>
                      })}
                    </tbody>
                  </table>
                </Box>

                <hr />

                <Box className='py-2 text-end'>
                  <Button variant="contained" className='btn-blue me-3'>
                    Create Request
                  </Button>
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default CreateRequest;