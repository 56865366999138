import DrawIcon from "@mui/icons-material/Draw";
import GradingIcon from "@mui/icons-material/Grading";
import InfoIcon from "@mui/icons-material/Info";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Button, Chip, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import FormFillRequest from "../components/RequestComponant/FormFillRequest";
import ReplaceRequestDoc from "../components/RequestComponant/ReplaceRequestDoc";
import SignatureRequest from "../components/RequestComponant/SignatureRequest";
import UploadRequestDoc from "../components/RequestComponant/UploadRequestDoc";

import { useDispatch } from "react-redux";
import csv from "../../src/assets/images/files-icon/csv.png";
import doc from "../../src/assets/images/files-icon/doc.png";
import jpg from "../../src/assets/images/files-icon/jpg.png";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import png from "../../src/assets/images/files-icon/png.png";
import text from "../../src/assets/images/files-icon/text.png";
import xls from "../../src/assets/images/files-icon/xls.png";
import UpdateFormFill from "../components/RequestComponant/UpdateFormFill";
import { portalUser } from "../redux/AxiosInstance";
import {
  GetAllSentMessages_Json_Redux,
  RequestedDocumentAsByteArray_Json_Redux,
} from "../redux/api_helper";
// import UpdateFormFill from "../components/RequestComponant/UpdateFormFill";

export const clientBodyaccIdData = {
  accId: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient"),
};

export const userBodyDAtaa = {
  accid: localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

export const userBodyaccId = {
  accId: localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

export const clientLoginStatus = localStorage.getItem("LoginClient");

export const clientBodyaccidData = {
  accid: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient"),
};

export const GetStatusChipRequests = (res, requestStatus, statusForInfo) => {
  if (statusForInfo && Number(statusForInfo) === 3) {
    return null; // Return null instead of an empty fragment for clarity.
  }

  const statusColors = {
    1: "#0000FF", // Blue
    2: "#eea706", // Orange
    3: "#008000", // Green
    4: "#008000", // Green (same as 3)
    5: "#195e83", // Teal
    6: "#FF0000", // Red
    7: "#A52A2A", // Brown
  };

  const color = statusColors[res];

  return color ? (
    <Chip
      label={requestStatus}
      size="small"
      sx={{ background: color }}
      className="chips"
    />
  ) : null; // Use null to avoid rendering unnecessary elements
};

export const GetStatusRequests = (data) => {
  const statusConfig = {
    0: {
      title: "Request Document",
      icon: <GradingIcon className="font-18 text-black" />,
    },
    1: {
      title: "Request Signature",
      icon: <DrawIcon className="font-18 text-black" />,
    },
    2: {
      title: "Send Form",
      icon: <MarkEmailReadIcon className="font-18 text-black" />,
    },
    3: { title: "For Info", icon: <InfoIcon className="font-18 text-black" /> },
  };

  const config = statusConfig[data.Status];
  return config ? (
    <Tooltip title={config.title} arrow>
      <Button size="small" className="min-width-auto">
        {config.icon}
      </Button>
    </Tooltip>
  ) : null;
};

const detectMimeTypeFromBase64 = (base64Data) => {
  // console.log('✌️base64Data --->', base64Data);
  const magicNumbers = {
    rtf: "7B5C7274", // RTF starts with "{\rtf" in hex
    pdf: "25504446", // PDF starts with "%PDF"
    // docx: "504B0304", // DOCX and XLSX start with "PK"
    xlsx: "504B0304",
    xls: "D0CF11E0", // XLS starts with a specific signature
  };

  const hexHeader = Array.from(atob(base64Data).slice(0, 4))
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
    .join("")
    .toUpperCase();

  if (hexHeader.startsWith(magicNumbers.rtf)) return "application/rtf";
  if (hexHeader.startsWith(magicNumbers.pdf)) return "application/pdf";
  if (hexHeader.startsWith(magicNumbers.docx))
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  if (hexHeader.startsWith(magicNumbers.xlsx))
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  if (hexHeader.startsWith(magicNumbers.xls)) return "application/vnd.ms-excel";

  return "application/octet-stream"; // Default fallback
};

export const getStatusBaseButtonRequests = (
  accordionIndex,
  accordionItem,
  setPreview
) => {
  if (
    accordionItem &&
    accordionItem.Status == "0" &&
    accordionItem.RequestDocStatus == "3"
  ) {
    return (
      <ReplaceRequestDoc
        accordionItem={accordionItem}
        setPreview={setPreview}
      />
    );
  } else {
    switch (true) {
      case accordionItem &&
        ((accordionItem.Status == "0" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "0" &&
            accordionItem.RequestDocStatus == "5") ||
          (accordionItem.Status == "0" &&
            accordionItem.RequestDocStatus == "7")):
        return (
          <UploadRequestDoc
            accordionItem={accordionItem}
            setPreview={setPreview}
          />
        );

      case accordionItem &&
        ((accordionItem.Status == "1" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "1" &&
            accordionItem.RequestDocStatus == "5")):
        return <SignatureRequest accordionItem={accordionItem} />;
      case accordionItem &&
        accordionItem.Status == "2" &&
        accordionItem.RequestDocStatus == "2":
        return <FormFillRequest accordionItem={accordionItem} />;

      case accordionItem &&
        ((accordionItem.Status == "2" &&
          accordionItem.RequestDocStatus == "5") ||
          (accordionItem.Status == "2" &&
            accordionItem.RequestDocStatus == "3")):
        return <UpdateFormFill accordionItem={accordionItem} />;

      default:
        return <></>;
    }
  }
};

export const UserStatusClass = (data) => {
  switch (true) {
    case data.RequestDocStatus == 1:
      return "approved";
    case data.RequestDocStatus == 2:
      return "pending";
    case data.RequestDocStatus == 3:
      return "approved";
    case data.RequestDocStatus == 4:
      return "approved";
    case data.RequestDocStatus == 5:
      return "rejected";
    case data.RequestDocStatus == 6:
      return "expiring";
    case data.RequestDocStatus == 7:
      return "expired";
    default:
      return "pending";
  }
};

export const GetDocTypeIconRequests = (target, n) => {
  const normalizedExtension = target.toLowerCase();

  switch (normalizedExtension) {
    case ".pdf":
    case "pdf":
      return <img src={pdf} style={{ width: 30 }} alt="documents" />;
    case ".png":
      return <img src={png} style={{ width: 30 }} alt="documents" />;
    case ".docx":
    case "docx":
      return <img src={doc} style={{ width: 30 }} alt="documents" />;
    case ".xlsx":
    case "xlsx":
    case ".xls":
      return <img src={xls} style={{ width: 30 }} alt="documents" />;
    case ".jpg":
    case ".jpeg":
      return <img src={jpg} style={{ width: 30 }} alt="documents" />;
    case ".text":
      return <img src={text} style={{ width: 30 }} alt="documents" />;
    case ".csv":
      return <img src={csv} style={{ width: 30 }} alt="documents" />;
    default:
      return null;
  }
};

export const GetDocTypeIcon2Requests = (target) => {
  switch (target) {
    case "pdf":
      return pdf;
    case ".pdf":
      return pdf;
    case ".txt":
      return text;
    case ".docx":
      return doc;
    case ".jpg":
      return jpg;
    case ".jpeg":
      return jpg;
    case ".png":
      return png;
    case ".xls":
      return xls;
    case ".csv":
      return csv;
    default:
      return text;
  }
};

export const GetrequestTime = (time) => {
  const timestamp = parseInt(time.match(/\d+/)[0], 10);

  // Format the date using Day.js
  const formattedDate = dayjs(timestamp).format("ddd DD MMM YYYY [at] HH:mm");
  return formattedDate;
};

export const convertToDateFormat = (dateString) => {
  if (!dateString) return ""; // Handle null or undefined cases gracefully

  // Extract the timestamp from the /Date(...) format
  const timestamp = parseInt(dateString.match(/\d+/)[0], 10);

  // Format the date using Day.js
  const formattedDate = dayjs(timestamp).format("DD/MM/YYYY");
  return formattedDate;
};

export const portalUserExcuteAPI = async (route, payload) => {
  try {
    let res = await portalUser.post(route, payload);

    if (res) {
      // console.log(res?.data, "========= res.data >>>>");

      const data = res?.data?.d;

      // Handle cases where `d` is an empty string or empty object
      if (
        data === "" ||
        (typeof data === "object" && Object.keys(data).length === 0)
      ) {
        return true;
      }

      // Check if `data` is a string and parse it as JSON
      if (typeof data === "string") {
        try {
          const parsedData = JSON.parse(data);
          return parsedData;
        } catch (parseError) {
          console.error("Failed to parse string as JSON:", parseError);
          throw new Error("Invalid JSON string received.");
        }
      }

      // If `data` is neither a string nor empty, return it as is
      return data;
    }
  } catch (error) {
    console.error(
      "Network error: No response received from server, RequestedDocumentCreated_Json",
      error
    );
    throw error; // Re-throw error for further handling
  }
};

export const useGetUserSentMessage = () => {
  const dispatch = useDispatch();

  const fetchSentMessages = () => {
    let sentObj = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };
    dispatch(GetAllSentMessages_Json_Redux(sentObj, function (res) {}));
  };

  return fetchSentMessages;
};

export const RequestDownloadFile = (e, doc) => {
  e.preventDefault();

  if (doc && (doc.DocData || doc.DocData2)) {
    let extension = doc.DocExtension.startsWith(".")
      ? doc.DocExtension.toLowerCase()
      : `.${doc.DocExtension.toLowerCase()}`;

    const mimeTypeMap = {
      ".pdf": "application/pdf",
      ".docx":
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".xlsx":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".xls": "application/vnd.ms-excel",
    };

    const base64Data = doc.DocData || doc.DocData2;
    const cleanBase64 = base64Data.replace(/^data:.*;base64,/, ""); // Clean Base64
    const byteArray = base64ToUint8Array(cleanBase64);

    let mimeType = detectMimeTypeFromBase64(
      base64Data.replace(/^data:.+;base64,/, "")
    );

    // Fallback to known extensions if MIME type is not detected
    const mimeTypeToExtension = {
      "application/pdf": ".pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        ".docx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        ".xlsx",
      "application/vnd.ms-excel": ".xls",
      "application/rtf": ".rtf",
    };
    if (!mimeTypeToExtension[extension.toLowerCase()]) {
      mimeType = mimeType || mimeTypeToExtension[extension.toLowerCase()];
    }
    // return;
    const correctExtension = mimeTypeToExtension[mimeType] || extension;
    const filename = `${doc?.DocName}${correctExtension}`;

    try {
      const blob = new Blob([byteArray], { type: mimeType });
      const url = URL.createObjectURL(blob);

      console.log("Filename:", filename); // Debugging output

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating blob or downloading file:", error);
    }
  } else {
    console.error("Document data is not available for download");
  }
};

export const DownloadBase64File = (
  base64Data,
  fileName = "example.pdf",
  fileType = "application/pdf"
) => {
  // Convert Base64 string to binary data
  const binaryData = atob(base64Data);
  const byteNumbers = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob and download
  const blob = new Blob([byteNumbers], { type: fileType });
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger download
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link); // Append to body for Firefox compatibility
  link.click();

  // Cleanup
  document.body.removeChild(link); // Remove link after download
  window.URL.revokeObjectURL(url);
};

const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

// User request function

export const UserRequestFileDownloadAndPreview = (
  data,
  objReq,
  dispatch,
  actionType
) => {
  let newObjectFile = {
    ...objReq,
    accId: objReq.accid,
    username: data.EmailId,
    template: data.DocTempId,
    docid: data.DocId,
  };
  delete newObjectFile.accid;

  dispatch(
    RequestedDocumentAsByteArray_Json_Redux(newObjectFile, (res) => {
      if (res) {
        const extension = data.DocExtension.startsWith(".")
          ? data.DocExtension
          : `.${data.DocExtension}`;

        let mimeType = detectMimeTypeFromBase64(
          res.replace(/^data:.+;base64,/, "")
        );

        // Fallback to known extensions if MIME type is not detected
        const mimeTypeToExtension = {
          "application/pdf": ".pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            ".docx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            ".xlsx",
          "application/vnd.ms-excel": ".xls",
          "application/rtf": ".rtf",
        };
        if (!mimeTypeToExtension[extension.toLowerCase()]) {
          mimeType = mimeType || mimeTypeToExtension[extension.toLowerCase()];
        }
        // return;
        const correctExtension = mimeTypeToExtension[mimeType] || extension;
        const filename = `${data.DocName}${correctExtension}`;

        // Remove the data URL prefix if present
        const base64Data = res.replace(/^data:.+;base64,/, "");

        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters).map((char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType });
        const objectUrl = URL.createObjectURL(blob);

        if (actionType === "download") {
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else if (actionType === "preview") {
          if (mimeType.startsWith("application/pdf")) {
            window.open(objectUrl, "_blank");
          } else if (mimeType.includes("application/vnd")) {
            window.open(
              `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                objectUrl
              )}`,
              "_blank"
            );
          } else {
            console.error("Unsupported file type for preview");
          }
        }
      } else {
        console.log("File not found");
      }
    })
  );
};
