import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Button,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutoComplete from "../components/AutoComplete";
import {
  addCompany_Redux,
  Json_GetConfiguration,
  Json_GetFolders_Redux,
  Json_UpdateClient_Redux,
  SetClientAddress_Redux,
} from "../redux/ak_api_helper";
import { setOpenCompanyModal } from "../redux/modalSlice";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import dayjs from "dayjs";
import { EditCompanyOpenMdalReducer } from "../redux/adityaReducer";
import { setcompanyCardDitiels_Redux } from "../redux/akSlice";
import { GetAllFolders_Json_Redux } from "../redux/api_helper";
import {
  ad_AddFolders_Toclient_Action,
  ad_ClientAddress_Update_Action,
  ad_Json_CRMSaveUDFValues_Action,
  ad_Json_GetClientAssignedUnassignedFolderList_Action,
  ad_Json_GetClientCardDetails_Action,
  ad_Json_GetConfiguration_Action,
  ad_Json_UpdateClient_Action,
} from "../redux/dev_aditya_action";
import UserUdfEditField from "../user/componants/UserUdfEditField";
import { CountryList } from "../utils/CountryList";

const EditCompany = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenCompanyModal(false));
  };
  const { GetFolders } = useSelector((state) => state.ak);
  const { companyAddress } = useSelector((state) => state.ak);
  const { getClientIdName } = useSelector((state) => state.ak);
  const { getClientIdMethod } = useSelector((state) => state.ak);
  const { getFolderObject } = useSelector((state) => state.ak);
  const { companyCardDitiels, companyCardDitielsAddresses } = useSelector(
    (state) => state.ak
  );

  const { bussiness, sources, mangers, companyStatus } = useSelector(
    (state) => state.ak
  );

  //start editing code for company
  const [value, setValue] = useState("");
  const [manager, setManager] = useState(null);
  const [bussines, setbussines] = useState(null);
  const [sources1, setsources1] = useState(null);
  const [comStatus, setcomStatus] = useState(null);
  const [FolderIdtolabel, setFolderIdtolabel] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [storedValue, setStoredValue] = useState("");
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [assignFolders, setAssignFolders] = useState([]);
  const [tabValue, setTabValue] = React.useState("1");
  const [description, setDescription] = React.useState("");
  const [companyDetailData, setCompanyDetailData] = useState({});
  const [getConfiguration, setGetConfiguration] = useState();
  const [udfFieldChange, setUdfFieldChange] = useState([]);
  // const [allAddressdata, setAllAddressdata] = useEffect([])
  const [getFolder, setGetFolders] = useState([]);
  const [allAddressData, setAllAddressData] = useState([]);
  const [allClientCardData, setAllClientCardData] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [CompanyFormData, setCompanyFormData] = useState({
    ID: "",
    Name: "",
    TelPhoneNo: "",
    Town: "",
    MobileNo: "",
    contactEmail: "",
    "Post Code": "",
    "Address Line 1": "",
    "Address Line 2": "",
    "Address Line 3": "",
    status: "",
    PassWord: "",
    Manager: "",
    County: "",
    Country: "",
    Business: "",
    Source: "",
    Folder: [],
    OriginatorNo: getClientIdMethod,
  });

  let body = {
    strOrignatorNumber: getClientIdMethod,
    intProjectId: getFolderObject,
  };
  console.log(getFolderObject, "=== getFolderObject");

  React.useEffect(() => {
    dispatch(Json_GetFolders_Redux());
    dispatch(Json_GetConfiguration());
    dispatch(
      ad_Json_GetClientCardDetails_Action(body, (res) => {
        setCompanyDetailData(res?.Table1?.[0]);
        setAllAddressData(res?.Table2);
        setAllClientCardData(res);
      })
    );

    dispatch(
      ad_Json_GetConfiguration_Action((res) => {
        setGetConfiguration(res);
      })
    );
    getAssignFolder();
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        if (res) {
          setGetFolders(res);
          setFolderId(
            res.length > 0
              ? res.find(
                  (option) =>
                    Number(option?.FolderID) === Number(getFolderObject)
                )
              : null
          );
        }
      })
    );
  }, []);

  const getAssignFolder = () => {
    dispatch(
      ad_Json_GetClientAssignedUnassignedFolderList_Action(
        { strOrignatorno: body.strOrignatorNumber },
        (res) => {
          setAssignFolders(res?.Table);
        }
      )
    );
  };
  //  address functions start
  const onHandleChange = (e, index, field) => {
    const { value } = e.target;
    setAllAddressData((prevData) =>
      prevData.map((address, i) =>
        i === index ? { ...address, [field]: value } : address
      )
    );
  };

  useEffect(() => {
    if (allAddressData[0]) {
      const formattedAddress = [
        allAddressData[0].Add1,
        allAddressData[0].Add2,
        allAddressData[0].Add3,
        allAddressData[0].Town,
        allAddressData[0].County,
        allAddressData[0].Postcode,
        allAddressData[0].Country,
      ]
        .filter((value) => value) // Exclude empty values
        .join("\n"); // Join values with a comma
      setDescription(formattedAddress);
    }
  }, [allAddressData]);
  // address function finish
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    updateCompanyData();
  }, [companyDetailData, assignFolders]);

  const updateCompanyData = () => {
    try {
      // Find related objects
      const foundManager = mangers.find(
        (manager) => manager.UserId === companyDetailData?.ManagerID
      ) || { UserName: "" };
      const foundBusiness = bussiness.find(
        (business) => business.BussId === companyDetailData?.BussID
      ) || { BussName: "" };
      const foundSource = sources.find(
        (source) => source.SourceId === companyDetailData?.SourceId
      ) || { SourceName: "" };
      const foundStatus = companyStatus.find(
        (status) => status?.StatusId === companyDetailData?.StatusId
      ) || { StatusName: "" };
      const foundFolder = GetFolders.find(
        (folder) => folder.FolderID === getFolderObject
      ) || { Folder: "" };

      const matchedFolders = GetFolders.filter((item) =>
        assignFolders.some((folder) => folder.ProjectId === item.FolderID)
      );
      if(matchedFolders){
        setFolderId(matchedFolders[0])
      setSelectedFolders(matchedFolders);
      }

      // Update individual state values
      setManager(foundManager);
      setbussines(foundBusiness);
      setsources1(foundSource);
      setcomStatus(foundStatus);
      setFolderIdtolabel(foundFolder);

      // Update the form data
      if (companyDetailData) {
        setCompanyFormData({
          ID: companyDetailData.OriginatorNo || "",
          Name: companyDetailData.OriginatorName || "",
          TelPhoneNo: companyDetailData?.AltTelNo || "",
          Town: companyCardDitielsAddresses.Town || "",
          MobileNo: companyDetailData?.TelNo || "",
          contactEmail: companyDetailData.Email || "",
          "Post Code": companyCardDitielsAddresses.Postcode || "",
          "Address Line 1": companyCardDitielsAddresses.Add1 || "",
          "Address Line 2": companyCardDitielsAddresses.Add2 || "",
          "Address Line 3": companyCardDitielsAddresses.Add3 || "",
          status: foundStatus,
          PassWord: companyDetailData.OrgPassword || "",
          Manager: foundManager,
          County: companyCardDitielsAddresses.County || "",
          Country: companyDetailData.Country || "",
          Business: foundBusiness,
          Source: foundSource,
          // Folder: foundFolder,
          OriginatorNo: getClientIdMethod,
        });
      }
    } catch (err) {
      console.error("Error updating company data:", err);
    }
  };

  const HandleUpdateClientDetail = () => {
    const Body = {
      OriginatorNo: CompanyFormData.ID,
      OriginatorName: CompanyFormData?.Name,
      Address: "ktg 1,khategaon,455336",
      TelNo: CompanyFormData?.MobileNo,
      AlteTelNo: CompanyFormData?.TelPhoneNo,
      Faxno: "",
      ContactName: "",
      OrgActive: "Yes",
      StickyNote: "",
      ContactEmail: CompanyFormData.contactEmail,
      BussId: CompanyFormData?.Business?.BussId,
      SourceId: CompanyFormData?.Source?.SourceId
        ? `${CompanyFormData?.Source?.SourceId}`
        : "",
      StatusId: CompanyFormData?.status?.StatusId,
      Description: "",
      OrgPassword: "",
      ManagerId: CompanyFormData.Manager.UserId,
      CCode: CompanyFormData.ID,
    };

    dispatch(ad_Json_UpdateClient_Action(Body, (res) => {}));

    let ProjectIdList = selectedFolders.map((itm) => itm.FolderID).join(",");
    const newBody = {
      OriginatorNo: getClientIdMethod,
      ProjectIdList,
    };
    dispatch(
      ad_AddFolders_Toclient_Action(newBody, (res) => {
        getAssignFolder();
      })
    );
  };

  //end editing code for company

  const handleFnameInputChange = (event) => {
    const { name, value } = event.target;
    setCompanyFormData({ ...CompanyFormData, [name]: value });
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (Object.keys(companyCardDitiels).length === 0) setIsEmpty(true);
    if (Object.keys(companyDetailData).length === 0) setIsEmpty(true);
  }, [companyCardDitiels, companyDetailData]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange1 = (event) => {
    const newEmail = event.target.value;
    const { name, value } = event.target;
    setCompanyFormData({ ...CompanyFormData, [name]: value });
    setEmail(newEmail);
    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email address");
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const { name, value } = event.target;
    setCompanyFormData({ ...CompanyFormData, [name]: value });

    if (
      value.includes(CompanyFormData.ID) ||
      event.nativeEvent.inputType === "deleteContentBackward"
    ) {
      setIsChecked(false);
    }
  };

  React.useEffect(() => {
    if (isChecked) {
      setStoredValue(" " + textFieldValue);
    } else {
      setStoredValue("");
    }
  }, [isChecked, textFieldValue]);

  React.useEffect(() => {
    if (storedValue && value.includes(storedValue)) {
      setValue(value.replace(storedValue, ""));
    }
  }, [storedValue, value]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      if (!CompanyFormData.Name.includes(CompanyFormData.ID)) {
        setCompanyFormData({
          ...CompanyFormData,
          Name: `${CompanyFormData.Name} ${CompanyFormData.ID}`,
        });
      }
    } else {
      setCompanyFormData({
        ...CompanyFormData,
        Name: CompanyFormData.Name.split(" ").slice(0, -1).join(" ").toString(),
      });
    }
  };

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z0-9-]*$/; // Regex pattern: only letters, numbers, and hyphens allowed

    if (regex.test(value)) {
      setTextFieldValue(value);
      setCompanyFormData({ ...CompanyFormData, [name]: value });
    }
  };

  const mainAddress = () => {
    let obj = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      OriginatorNo: CompanyFormData.ID ? CompanyFormData.ID : "",
      AddressId: 1,
      AddressType: "Main Address",
      Add1: CompanyFormData["Address Line 1"]
        ? CompanyFormData["Address Line 1"]
        : "",
      Add2: CompanyFormData["Address Line 2"]
        ? CompanyFormData["Address Line 2"]
        : "",
      Add3: CompanyFormData["Address Line 3"]
        ? CompanyFormData["Address Line 3"]
        : "",
      Town: CompanyFormData.Town ? CompanyFormData.Town : "",
      County: CompanyFormData.County ? CompanyFormData.County : "",
      Postcode: CompanyFormData["Post Code"]
        ? CompanyFormData["Post Code"]
        : "",
      Country: CompanyFormData.Country
        ? CompanyFormData.Country
        : "United Kingdom",
    };
    dispatch(SetClientAddress_Redux(obj));
  };

  function todayDate() {
    var today = new Date().toJSON().slice(0, 10);
    return today;
  }

  const companyCreate = () => {
    let clientdata = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      ProjectIdList: CompanyFormData.Folder ? CompanyFormData.Folder : "",
      OriginatorNo: CompanyFormData.ID ? CompanyFormData.ID : "",
      OriginatorName: CompanyFormData.Name ? CompanyFormData.Name : "",
      Address: CompanyFormData.fullAddress ? CompanyFormData.fullAddress : "",
      TelNo: CompanyFormData.TelPhoneNo ? CompanyFormData.TelPhoneNo : "",
      AlteTelNo: CompanyFormData.MobileNo ? CompanyFormData.MobileNo : "",
      Faxno: "",
      ContactName: "",
      UDF1: "",
      UDF2: "",
      UDF3: "",
      StickyNote: "",
      ContactEmail: CompanyFormData.contactEmail
        ? CompanyFormData.contactEmail
        : "",
      MParameter: "",
      CDate: todayDate(),
      BussId: CompanyFormData.Business.BussId
        ? CompanyFormData.Business.BussId
        : -1,
      SourceId: CompanyFormData.Source.SourceId
        ? CompanyFormData.Source.SourceId
        : -1,
      StatusId: CompanyFormData?.status?.StatusId
        ? CompanyFormData?.status?.StatusId
        : "8",
      Description: "",
      OrgPassword: CompanyFormData.PassWord ? CompanyFormData.PassWord : "",
      ManagerId: CompanyFormData.Manager.UserId
        ? CompanyFormData.Manager.UserId
        : localStorage.getItem("FolderId"),
      OrgActive: "Yes",
    };
    if (CompanyFormData.Name !== "" && CompanyFormData.contactEmail !== "") {
      dispatch(addCompany_Redux(clientdata))
        .then(() => {
          mainAddress(); // Call mainAddress on success
          toast.success("Company added successfully"); // Show error toast message
          dispatch(EditCompanyOpenMdalReducer(true));
        })
        .catch((error) => {
          // Handle any errors if the addCompany_Redux action fails
          console.error("Failed to add contact:", error);
        });
    } else {
      // alert("Please fill all the required fields");
      toast.error("Please fill all the required fields");
    }
  };

  // console.log(CompanyFormData, "===== CompanyFormData ");
  // console.log(companyDetailData, "===== companyDetailData");

  const companyUpdate = () => {
    let UpdateClient = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),

      OriginatorNo: CompanyFormData.ID ? CompanyFormData.ID : "",
      OriginatorName: getClientIdName,
      Address: "",
      TelNo: CompanyFormData.TelPhoneNo ? CompanyFormData.TelPhoneNo : "",
      AlteTelNo: CompanyFormData.MobileNo ? CompanyFormData.MobileNo : "",
      Faxno: "",
      ContactName: CompanyFormData.Name ? CompanyFormData.Name : "",
      OrgActive: "Yes",
      StickyNote: "",
      ContactEmail: "",
      BussId: CompanyFormData.Business.BussId
        ? CompanyFormData.Business.BussId
        : -1,
      SourceId: CompanyFormData.Source.SourceId
        ? CompanyFormData.Source.SourceId
        : -1,
      StatusId: CompanyFormData.status?.StatusId
        ? CompanyFormData.status?.StatusId
        : "8",
      Description: "",
      OrgPassword: CompanyFormData.PassWord ? CompanyFormData.PassWord : "",
      ManagerId: CompanyFormData.Manager.UserId
        ? CompanyFormData.Manager.UserId
        : "1",
      CCode: getClientIdMethod,
    };
    dispatch(Json_UpdateClient_Redux(UpdateClient)).then(() => {
      mainAddress(); // Call mainAddress on success
      toast.success("Company added successfully"); // Show error toast message
      dispatch(
        setcompanyCardDitiels_Redux({
          companyCardDitiels: [],
        })
      );
    });
  };

  // update modal
  const HandleCompanyUpdate = async () => {
    if (!CompanyFormData.ID)
      return toast.error("There is empty any required field");
    if (!CompanyFormData.ID)
      return toast.error("There is empty any required field");
    if (Object.keys(CompanyFormData.Business) === 0)
      return toast.error("There is empty any required field");

    await HandleUpdateClientDetail();
    await handleAddressUpdate();
    await HandleUpdateUdf();
    setCompanyDetailData({});
    setAllAddressData([]);
    setAllClientCardData([]);
    const newData = await dispatch(
      ad_Json_GetClientCardDetails_Action(body, (res) => {
        setCompanyDetailData(res?.Table1?.[0]);
        setAllAddressData(res?.Table2);
        setAllClientCardData(res);
        dispatch(EditCompanyOpenMdalReducer(true));
      })
    );
  };

  const handleChange1 = (event, newValue) => {
    setSelectedFolders(newValue);
    // setCompanyFormData({...CompanyFormData,Folder:newValue});
    event.preventDefault();
    if (newValue) {
      const folderIds = newValue.map((folder) => folder.FolderID).join(",");
      let data = { ...CompanyFormData };
      data = { ...data, ["FolderId"]: folderIds };
      setCompanyFormData({ ...CompanyFormData, Folder: folderIds });
    }
  };

  const HandleUdfField = (event, index) => {
    // Helper function to update Table3
    const updateTable3 = (value) => {
      setAllClientCardData((prevData) => ({
        ...prevData,
        Table3: prevData?.Table3.map((item) =>
          item.UserDefFieldID === index ? { ...item, UdfValue: value } : item
        ),
      }));
    };

    if (event?.target?.value || event?.target?.id) {
      // Handle text or dropdown fields
      const { value, innerHTML } = event.target;
      const fieldValue =
        (value && value.trim()) || (innerHTML && innerHTML.trim());

      updateTable3(fieldValue);
      HandleUdfFieldChange(fieldValue, index);
    } else if (event) {
      // Handle date fields
      const formattedDate = dayjs(event).format("DD/MM/YYYY");
      console.log("Selected date:", formattedDate);

      // Update the value in Table3
      updateTable3(formattedDate);
      HandleUdfFieldChange(formattedDate, index);
    }
  };

  const HandleUdfFieldChange = (fieldValue, index) => {
    setUdfFieldChange((prevData) => {
      // Find the matching field from Table3
      const matchedField = allClientCardData?.Table3.find(
        (item) => item?.UserDefFieldID === index
      );

      if (!matchedField) {
        console.error("Field not found in Table3");
        return prevData; // Return unchanged state if the field is not found
      }

      // Update the matched field with the new UdfValue
      const updatedField = { ...matchedField, UdfValue: fieldValue };

      if (prevData.length > 0) {
        // Check if the folder exists in udfFieldChange
        const folderIndex = prevData.findIndex(
          (item) => item.folderId === folderId?.FolderID
        );

        if (folderIndex !== -1) {
          // Folder exists; update or add the field
          const updatedFolder = {
            ...prevData[folderIndex],
            field: prevData[folderIndex].field.some(
              (field) => field.UserDefFieldID === index
            )
              ? prevData[folderIndex].field.map((field) =>
                  field.UserDefFieldID === index
                    ? updatedField // Update the field
                    : field
                )
              : [...prevData[folderIndex].field, updatedField], // Add the field
          };

          // Return updated state with the modified folder
          return prevData.map((item, i) =>
            i === folderIndex ? updatedFolder : item
          );
        }
      }

      // Add a new folder entry if it doesn't exist
      return [
        ...prevData,
        {
          folderId: folderId?.FolderID,
          field: [updatedField],
        },
      ];
    });
  };

  const HandleUdfSwitch = (event, id) => {
    const { checked } = event.target;

    // Update the state immutably
    setAllClientCardData((prevData) => ({
      ...prevData,
      Table3: prevData.Table3.map((item) =>
        item.UserDefFieldID === id
          ? { ...item, blnProcess: checked } // Toggle blnProcess for the matched item
          : item
      ),
    }));
  };

  const HandleUpdateUdf = async () => {
    if (udfFieldChange.length > 0) {
      udfFieldChange.forEach((udf) => {
        let ProjectId = udf.folderId;

        if (udf.field.length > 0) {
          let ClientUDFString = udf.field
            .map((udfField) => {
              // Only include fields that have a valid UdfValue
              if (udfField?.UdfValue) {
                console.log(udfField, "=== udfField");

                return `${udfField?.UserDefFieldID}_${
                  udfField?.UserDefFieldTypeID
                }_${
                  udfField?.TextControlValue
                }_UDF:${udfField?.UdfValue.trim()}`;
              }
              return null; // Return null for fields that don't meet the condition
            })
            .filter(Boolean) // Remove null or undefined values
            .join(","); // Join the array into a single string separated by commas
          let udfBody = {
            OriginatorNo: getClientIdMethod,
            ProjectId,
            ClientUDFString: ClientUDFString,
            ContactUDFString: "",
            ContactNo: "",
          };

          dispatch(
            ad_Json_CRMSaveUDFValues_Action(udfBody, (res) => {
              // console.log(res, "===== res");
            })
          );
        }
      });
    }
    dispatch(
      ad_Json_GetClientCardDetails_Action(body, (res) => {
        setCompanyDetailData(res?.Table1?.[0]);
        setAllAddressData(res?.Table2);
        setAllClientCardData(res);
      })
    );
  };

  const handleAddressUpdate = async () => {
    const updatePromises = allAddressData.map(
      (address) =>
        new Promise((resolve, reject) => {
          dispatch(
            ad_ClientAddress_Update_Action(address, (res) =>
              res ? resolve(res) : reject("error")
            )
          );
        })
    );

    try {
      await Promise.all(updatePromises);
      toast.success("All Address processed successfully!");
    } catch (error) {
      console.error("Error processing documents:", error);
      toast.error("Failed to process some Address.");
    }
  };

  const HandleFolderChange = (event, value) => {
    console.log(value, "==== value");
    setAllClientCardData([]);
    setFolderId(value);
    let newBody = {
      strOrignatorNumber: getClientIdMethod,
      intProjectId: value?.FolderID,
    };
    dispatch(
      ad_Json_GetClientCardDetails_Action(newBody, (res) => {
        setAllClientCardData(res);
        console.log(res, "=====setAllClientCardData");
      })
    );
  };

  return (
    <>
      <Box>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h6" clasName="poppins-semibold title">
                  Update Company Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={2} columnSpacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <TextField
                      className="w-100"
                      label="ID"
                      id="outlined-size-small"
                      size="small"
                      sx={{ marginRight: 1 }}
                      required
                      name="ID"
                      value={CompanyFormData.ID}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  {/* Ch number */}
                  {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      className="w-100"
                      label="CH Number"
                      id="outlined-size-small"
                      size="small"
                      sx={{ marginRight: 1 }}
                      name="CH_Number"
                      // value={CompanyFormData.ID}
                      // onChange={handleTextFieldChange}
                      InputProps={{
                        readOnly: true, // Makes the field read-only
                      }}
                    />
                  </Grid> */}

                  {/* Attach Id */}
                  <Grid item xs={12} sm={6} md={12} lg={6}>
                    <Box className="d-flex align-items-center ms-3">
                      <Typography>Attach ID</Typography>
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    </Box>
                  </Grid>
                  {/* Name */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      id="outlined-size-small"
                      size="small"
                      value={CompanyFormData?.Name}
                      name="Name"
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  {/* Business list */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Box className="custom-autocomplete">
                      <AutoComplete
                        ComData={bussiness}
                        defaultValue={CompanyFormData.Business}
                        target="BussName"
                        comFun={(e, newValue) => {
                          setCompanyFormData({
                            ...CompanyFormData,
                            Business: newValue,
                          });
                        }}
                        lable="Business"
                        required
                      ></AutoComplete>
                    </Box>
                  </Grid>
                  {/* status */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Box className="custom-autocomplete">
                      <AutoComplete
                        ComData={companyStatus}
                        defaultValue={
                          CompanyFormData?.status
                            ? CompanyFormData?.status
                            : "Active"
                        }
                        target="StatusName"
                        comFun={(e, newValue) => {
                          setCompanyFormData({
                            ...CompanyFormData,
                            status: newValue,
                          });
                        }}
                        lable="Status"
                      ></AutoComplete>
                    </Box>
                  </Grid>
                  {/* Source */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Box className="custom-autocomplete">
                      <AutoComplete
                        ComData={sources}
                        defaultValue={
                          CompanyFormData?.Source ? CompanyFormData?.Source : ""
                        }
                        target="SourceName"
                        comFun={(e, newValue) => {
                          setCompanyFormData({
                            ...CompanyFormData,
                            Source: newValue,
                          });
                        }}
                        lable="Source"
                      ></AutoComplete>
                    </Box>
                  </Grid>
                  {/* Manager */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Box className="custom-autocomplete">
                      <AutoComplete
                        ComData={mangers}
                        defaultValue={
                          CompanyFormData?.Manager
                            ? CompanyFormData?.Manager
                            : ""
                        }
                        target="UserName"
                        comFun={(e, newValue) => {
                          setCompanyFormData({
                            ...CompanyFormData,
                            Manager: newValue,
                          });
                        }}
                        lable="Manager"
                      ></AutoComplete>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Box className="custom-autocomplete">
                      <Autocomplete
                        multiple
                        size="small"
                        options={GetFolders}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.Folder}
                        isOptionEqualToValue={(option, value) =>
                          option.FolderID === value.FolderID
                        }
                        defaultValue={
                          FolderIdtolabel &&
                          Object.keys(FolderIdtolabel).length > 0
                            ? FolderIdtolabel
                            : selectedFolders
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectedFolders.some(
                                (selected) =>
                                  selected.FolderID === option.FolderID
                              )}
                            />
                            {option?.Folder}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Folder"
                            placeholder="Select folders"
                          />
                        )}
                        onChange={handleChange1}
                        value={selectedFolders}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <TextField
                      className="w-100"
                      fullWidth
                      label="Email"
                      id="outlined-size-small"
                      size="small"
                      required
                      name="contactEmail"
                      value={CompanyFormData.contactEmail}
                      onChange={handleEmailChange1}
                      error={!!emailError}
                      helperText={emailError}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="MobileNo"
                      id="outlined-size-small"
                      size="small"
                      name="MobileNo"
                      value={CompanyFormData.MobileNo}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="TelePhone No"
                      id="outlined-size-small"
                      size="small"
                      name="TelPhoneNo"
                      value={CompanyFormData.TelPhoneNo}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h6" clasName="poppins-semibold title">
                  Edit Company Address
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{ width: "100%", typography: "body1" }}
                    className="custom-tabs"
                  >
                    <TabContext value={tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleTabChange}
                          aria-label="Address Tabs"
                          className="mb-3 flex-wrap-tabs"
                        >
                          <Tab label="Main Address" value="1" />
                          <Tab label="Billing Address" value="2" />
                          <Tab label="Registered Address" value="3" />
                          <Tab label="Full Address" value="4" />
                        </TabList>
                      </Box>

                      {allAddressData &&
                        allAddressData.length > 0 &&
                        allAddressData.map((address, index) => {
                          let selectedCountry = CountryList.find(
                            (conty) => conty.label === address.Country
                          );
                          return (
                            <TabPanel
                              key={index}
                              value={`${index + 1}`}
                              className="p-0 mt-"
                            >
                              <Grid container rowSpacing={2} columnSpacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Address 1"
                                    size="small"
                                    value={address.Add1}
                                    onChange={(e) =>
                                      onHandleChange(e, index, "Add1")
                                    }
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Address 2"
                                    size="small"
                                    value={address.Add2}
                                    onChange={(e) =>
                                      onHandleChange(e, index, "Add2")
                                    }
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Address 3"
                                    size="small"
                                    value={address.Add3}
                                    onChange={(e) =>
                                      onHandleChange(e, index, "Add3")
                                    }
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Town"
                                    size="small"
                                    value={address.Town}
                                    onChange={(e) =>
                                      onHandleChange(e, index, "Town")
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="County"
                                    size="small"
                                    value={address.County}
                                    onChange={(e) =>
                                      onHandleChange(e, index, "County")
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Postcode"
                                    size="small"
                                    value={address.Postcode}
                                    onChange={(e) =>
                                      onHandleChange(e, index, "Postcode")
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} md={12} lg={6}>
                                  <Box className="custom-autocomplete">
                                    <AutoComplete
                                      ComData={CountryList}
                                      target="label"
                                      defaultValue={
                                        selectedCountry ? selectedCountry : ""
                                      }
                                      comFun={(e, newValue) => {
                                        setAllAddressData((prevData) =>
                                          prevData.map((address, i) =>
                                            i === index
                                              ? {
                                                  ...address,
                                                  Country: newValue.label,
                                                }
                                              : address
                                          )
                                        );
                                      }}
                                      lable="Country"
                                    ></AutoComplete>
                                  </Box>
                                </Grid>
                              </Grid>
                            </TabPanel>
                          );
                        })}

                      <TabPanel value="4" className="p-0 mt-">
                        <TextField
                          className="w-100"
                          label="Full Address"
                          id="outlined-multiline-static"
                          multiline
                          rows={7}
                          variant="outlined"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          sx={{ marginTop: 2 }}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid container rowSpacing={2} columnSpacing={3} className="mt-4">
          <Grid item xs={12} className="mb-2">
            <div className="d-flex flex-column">
              <Typography className="mb-3 poppins-semibold title" variant="h6">
                UDF Field
              </Typography>
              <Box className="custom-autocomplete">
                <AutoComplete
                  ComData={selectedFolders}
                  target="Folder"
                  comFun={HandleFolderChange}
                  lable={"Folder"}
                  defaultValue={folderId}
                />
              </Box>
            </div>
          </Grid>

          {allClientCardData &&
            allClientCardData?.Table &&
            allClientCardData?.Table.length > 0 &&
            allClientCardData?.Table.map((udfTable, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Accordion className="accordian-box">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    spacing={2}
                  >
                    <Typography expandIcon={<ExpandMoreIcon />}>
                      {udfTable?.TagName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="py-4">
                    <Grid container spacing={2}>
                      {allClientCardData?.Table3.length > 0 ? (
                        allClientCardData?.Table3.some(
                          (udfFieId) => udfTable?.TagId === udfFieId.Tag
                        ) ? (
                          allClientCardData?.Table3.map(
                            (udfFieId, index) =>
                              udfTable?.TagId === udfFieId.Tag && (
                                <Grid item xs={12} md={12} key={index}>
                                  <Box className="d-flex gap-2">
                                    <Box className="d-flex gap-2 align-items-center">
                                      <Tooltip title={udfFieId?.Name}>
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Typography className="">
                                        {udfFieId?.Name}
                                      </Typography>
                                    </Box>
                                    <Switch
                                      checked={udfFieId?.blnProcess}
                                      name={`blnProcess-${udfFieId?.UserDefFieldID}`}
                                      onChange={(e) =>
                                        HandleUdfSwitch(
                                          e,
                                          udfFieId?.UserDefFieldID
                                        )
                                      }
                                    />
                                    <UserUdfEditField
                                      udfFieId={udfFieId}
                                      changeFun={HandleUdfField}
                                    />
                                  </Box>
                                </Grid>
                              )
                          )
                        ) : (
                          <Grid item xs={12}>
                            <Typography>No data found</Typography>
                          </Grid>
                        )
                      ) : (
                        <Grid item xs={12}>
                          <Typography>No data found</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
        </Grid>

        <Box className="mt-4 mb-2 d-flex">
          {!isEmpty ? (
            <Button
              onClick={companyCreate}
              autoFocus
              variant="outlined"
              className="btn-blue me-2"
              startIcon={<CreateIcon />}
            >
              Create Company
            </Button>
          ) : (
            <Button
              onClick={HandleCompanyUpdate}
              autoFocus
              variant="outlined"
              className="btn-blue me-2"
              startIcon={<CreateIcon />}
            >
              Update Company
            </Button>
          )}
          <Button
            onClick={handleClose}
            className="btn-red"
            variant=""
            startIcon={<DeleteIcon />}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditCompany;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];
