import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesMultiSelectTags({
  portalUserTo = [],
  label,
  comFun,
  defaultValue = [],
  fildName,
}) {
  // Ensure defaultValue is always an array
  const [selectedValues, setSelectedValues] = React.useState(
    Array.isArray(defaultValue) ? defaultValue : []
  );

  // Sync with external changes in defaultValue
  React.useEffect(() => {
    setSelectedValues(Array.isArray(defaultValue) ? defaultValue : []);
  }, [defaultValue]);

  const handleChange = (event, newValue) => {
    setSelectedValues(newValue);
    comFun(event, newValue); // Pass the updated values up
  };

  return (
    <Box className="custom-autocomplete">
      <Autocomplete
        multiple
        limitTags={2}
        id="checkboxes-tags-demo"
        options={portalUserTo}
        disableCloseOnSelect
        getOptionLabel={(option) => option[fildName]}
        value={selectedValues} // Use value instead of defaultValue for controlled input
        onChange={handleChange}
        size="small"
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              size="small"
            />
            {option[fildName]}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder="Favorites" size="small" />
        )}

      />
    </Box>
  );
}
