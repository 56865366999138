// import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../components/AutoComplete";
import CheckboxesMultiSelectTags from "../../../components/CheckBoxesMultiSelectTags";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import {
  GetAllFolders_Json_Redux,
  GetClientsByFolder_Json_Redux,
  GetPortalDocReqTemplates_Json_Redux,
  GetSectionsByFolder_Json_Redux,
  GetUsersEmailSignature_Json_Redux,
  Json_GetClientCardDetails_Redux,
  Json_GetForwardUserList_Redux,
  Json_GetHtmlFromRtf_Redux,
  Json_GetStandardLetterData_Redux,
  Json_GetWebTemplatesList_Redux,
  sonamConfirmMessage,
} from "../../../redux/api_helper";
// import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  handleOpenContactModal,
  setComposePortalMgsObj_Redux,
  setGUID_Redux,
} from "../../../redux/docuSlice";
import HtmlEditorDX from "./HtmlEditor";
// import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation } from "react-router-dom";

import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import CustomLoader from "../../../components/CustomLoader";
import {
  ad_Json_GetHtmlFromRtf_Action,
  ad_Json_GetStandardLetterData_Action,
  ad_Json_GetWebTemplates_Action,
} from "../../../redux/dev_aditya_action";
import { setOpenCompanyModal } from "../../../redux/modalSlice";

function ComposePortalMessage() {
  const agrno = localStorage.getItem("agrno");
  const password = localStorage.getItem("password");
  const FolderId = localStorage.getItem("FolderId");
  const Email = localStorage.getItem("Email");

  const [chgObj, setChgObj] = React.useState("");
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const folder = searchParams.get("folder");
  const refrence = searchParams.get("refrence");
  const OriginatorNo = searchParams.get("OriginatorNo");

  const user = searchParams.get("user");

  let objReq = useLocalStorage("accid");
  let objAgr = useLocalStorage("required");
  let dispatch = useDispatch();

  const ComposePortalMgsObj = useSelector(
    (state) => state.docu.ComposePortalMgsObj
  );

  const selector = useSelector((state) => state.docu);

  const [getFolder, setGetFolders] = React.useState([]);
  const [portalUser, setPortalUser] = React.useState([]);
  const [portalUserCC, setPortalUserCC] = React.useState([]);
  const [portalUserTo, setPortalUserTo] = React.useState([]);
  const [txtFolderData, settxtFolderData] = React.useState(null);
  const [txtSectionData, settxtSectionData] = React.useState(null);
  const [txtClientData, settxtClientData] = React.useState(null);
  const [txtTemplateData, settxtTemplateData] = React.useState(null);
  const [txtFromData, settxtFromData] = React.useState(null);
  const [txtSubject, settxtSubject] = React.useState(null);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [userList, setUserList] = React.useState([]);
  const [ownerID, setOwnerID] = React.useState("");
  const [getClient, setGetClient] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [contactData, setContactData] = useState(null);
  const [templateDataMarkup, setTemplateDataMarkup] = useState(null);
  const [templateDataList, setTemplateDataList] = useState([]);
  const [newSectionList, setNewSectionList] = useState([]);

  const [defaltFolderData, setDefaltFolderData] = useState({});
  const [smsTemplate, setSMSTemplate] = useState([]);
  const [isSingDisabled, setIsSingDisabled] = useState(false);
  const [mainObject, setMainObject] = useState({
    Folder: folder ? folder : "",
    Section: "",
    Client: refrence ? refrence : "",
    FromUser: "",
    CCEmail: "",
    ToEmail: "",
    Subject: "",
    TextEditor: "",
    Template: "",
    SMSTemplate: "",
    isSignature: null,
    isMessage: null,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      await loadPageData();
      await getFlderData();
    };

    fetchData(); // Initiates data loading on component mount
  }, [dispatch]);

  const getFlderData = async (count) => {
    if (count) {
      count++;
    }
    setLoader(true);
    await dispatch(
      GetAllFolders_Json_Redux((res) => {
        if (Array.isArray(res) && res.length > 0) {
          setGetFolders(res);
          let result = res.filter((e) => e.FolderID === parseInt(FolderId));
          if (result.length > 0) {
            setDefaltFolderData(result[0]);
            handleObjectDispatch(
              "Folder",
              ComposePortalMgsObj.Folder || result[0]
            );
          } else {
            console.log("no result");
          }

          setLoader(false);
        } else {
          let count = 0;
          getFlderData(count);
        }
      })
    );
  };

  const loadPageData = async () => {
    setMainObject({ ...ComposePortalMgsObj });

    setTemplateDataMarkup(
      Object.keys(ComposePortalMgsObj).length !== 0
        ? ComposePortalMgsObj?.TextEditor
        : ""
    );

    setIsCheckedSignature(ComposePortalMgsObj.isSignature === "true");

    if (ComposePortalMgsObj.isMessage === "true") {
      setIsChecked(true);
      setIsSingDisabled(true);
      setIsCheckedSignature(false);
    } else {
      setIsSingDisabled(false);
      setIsChecked(false);
    }

    if (ComposePortalMgsObj.Client) {
      setTimeout(() => {
        GetPortlEmailList(ComposePortalMgsObj.Client.ClientID);
      }, 1500);
    }

    let strGuid = uuidv4().replace(/-/g, "");
    localStorage.setItem("GUID", strGuid);

    if (!selector.GUID) {
      await dispatch(setGUID_Redux(strGuid));
    }

    await dispatch(
      ad_Json_GetWebTemplates_Action((res) => {
        const uniqueData = Array.from(
          new Map(res?.Table.map((item) => [item.ItemTypeId, item])).values()
        );
        setTemplateDataList(res?.Table);
        setNewSectionList(uniqueData);
      })
    );

    // Non-blocking synchronous calls (ensure they do not depend on awaiting)
    getAllClientByFolderid({ folder: FolderId });
    getAllSectionByFolderid({ folderID: FolderId });
    Json_GetForwardUserList(FolderId);
    getAllTemplates(FolderId);
    setChgObj(FolderId);
    GetSMSTemplate();
    GetUsersEmailSignature_Json();
  };

  useEffect(() => {
    const data = sessionStorage.getItem("contactData");
    if (data) {
      const JsonData = JSON.parse(data);
      if (JsonData) {
        setContactData(JsonData);
      }
    }
  }, []);

  useEffect(() => {
    if (folder && getFolder) {
      const result = getFolder.find((item) => item.Folder === folder);
      if (result) {
        handleObjectDispatch("Folder", result);
      }
    }

    if (OriginatorNo && getClient) {
      const result = getClient.find((item) => item.ClientID === OriginatorNo);
      if (result) {
        handleObjectDispatch("Client", result);
      }
    }
    if (user) handleObjectDispatch("ToEmail", contactData);
  }, [folder, refrence, user, contactData, getFolder, getClient]);

  function GetUsersEmailSignature_Json() {
    let object = {};
    object.accId = localStorage.getItem("agrno");
    object.email = localStorage.getItem("Email");
    object.password = localStorage.getItem("password");
    object.managerEmail = localStorage.getItem("Email");
    try {
      dispatch(GetUsersEmailSignature_Json_Redux(object));
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  }

  function GetSMSTemplate() {
    try {
      dispatch(
        Json_GetWebTemplatesList_Redux(objAgr, function (data) {
          if (data) {
            if (data?.Table.length > 0) {
              console.log("template...", data.Table);
              setSMSTemplate(data.Table);
            }
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetWebTemplatesList",
        error
      );
    }
  }

  useEffect(() => {
    dispatch(setComposePortalMgsObj_Redux(mainObject));
  }, [mainObject]); // Only re-run the effect if mainObject changes

  // Define a single function to handle dispatching actions based on object type
  const handleObjectDispatch = (objectType, value) => {
    // Update mainObject state
    setMainObject((prevState) => ({
      ...prevState,
      [objectType]: value,
    }));
  };

  const getFolderObject = (event, value) => {
    handleObjectDispatch("Folder", value);
    setDefaltFolderData(value);
    settxtFolderData(value);
    if (value) {
      setChgObj(value.FolderID);
      getAllClientByFolderid({ folder: value.FolderID });
      getAllSectionByFolderid({ folderID: value.FolderID });
      Json_GetForwardUserList(value.FolderID);
      getAllTemplates(value.FolderID);
    }
  };

  const [templateList, setTemplatesList] = useState([]);

  function getAllTemplates(pid) {
    try {
      let o = {
        allTemplates: "true",
        folder: pid,
        forCompose: "false",
      };

      dispatch(
        GetPortalDocReqTemplates_Json_Redux(o, (res) => {
          if (res) {
            setTemplatesList(res);
            handleObjectDispatch("Template", res);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalDocReqTemplates_Json",
        error
      );
    }
  }

  const getClientObject = (event, value) => {
    if (value && value.ClientID) {
      handleObjectDispatch("Client", value);

      settxtClientData(value);

      setTimeout(() => {
        GetPortlEmailList(value.ClientID);
      }, 1500);
    } else {
      console.error("Invalid client object: ", value);
    }
  };

  const getSectionObject = (event, value) => {
    settxtSectionData((prev) => ({
      ...prev,
      [event]: value,
    }));

    handleObjectDispatch("Section", value);
  };

  const getForwardUsernObject = (event, value) => {
    settxtFromData(value);
    handleObjectDispatch("FromUser", value);
  };

  const getTemplatesObject = (event, value) => {
    settxtTemplateData(value);
    Json_GetStandardLetterData(value);
    handleObjectDispatch("SMSTemplate", value);
  };

  function Json_GetStandardLetterData(data) {
    if (!mainObject?.Folder.FolderID)
      return toast.error("Folder id is not found");
    if (!mainObject?.Client?.ClientID)
      return toast.error("Client id is not found");

    try {
      if (selectedEmail && selectedEmail.length > 0) {
        console.log(
          "✌️mainObject?.Client?.ClientID; --->",
          mainObject?.Client?.ClientID
        );
        return;
        let obj = {};
        obj.agrno = agrno;
        obj.UserEmail = Email;
        obj.password = password;
        obj.strFolderId = mainObject.Folder.FolderID;
        obj.strClientId = mainObject?.Client?.ClientID;
        obj.strSectionId = data.SectionId;
        obj.strTemplateId = data.TemplateId;
        obj.ContactEmail = "patrick@docusoft.net"
          ? selectedEmail[0]["E-Mail"]
          : toast.error("Please Select Email In To!");

        dispatch(
          Json_GetStandardLetterData_Redux(obj, function (data) {
            if (data) {
              if (data.includes("File Not Found")) {
              } else {
                Json_GetHtmlFromRtf(data);
              }
            }
          })
        );
      } else {
        toast.error("Please Select a Email in To");
      }
    } catch (error) {
      console.log("Error for Tempalte", error);
    }
  }

  function Json_GetHtmlFromRtf(rtfdata) {
    try {
      let obj = {};
      obj.strRtf = rtfdata;
      dispatch(
        Json_GetHtmlFromRtf_Redux(obj, function (data) {
          if (data) {
            let json = JSON.parse(data);
            setTemplateDataMarkup(json);
          }
        })
      );
    } catch (error) {
      console.log("Error for Tempalte", error);
    }
  }

  function GetPortlEmailList(cid) {
    try {
      let o = {
        intProjectId: chgObj ? chgObj : localStorage.getItem("FolderId"),
        strOrignatorNumber: cid,
      };
      let p = { ...objAgr, ...o };
      dispatch(
        Json_GetClientCardDetails_Redux(p, function (data) {
          try {
            if (data) {
              let tble6 = data.Table6;
              if (tble6?.length > 0) {
                let filteredUsers = tble6?.filter(
                  (el) =>
                    el["Portal User"] === true && el["Portal User"] !== null
                );
                if (filteredUsers.length > 0) {
                  setPortalUser(
                    filteredUsers.length > 0 ? filteredUsers : null
                  );
                  setPortalUserCC(
                    filteredUsers.length > 0 ? filteredUsers : null
                  );
                  setPortalUserTo(
                    filteredUsers.length > 0 ? filteredUsers : null
                  );
                  setSelectedEmail(ComposePortalMgsObj.ToEmail);
                }
              } else {
                // setPortalUser([]);
              }
            }
          } catch (error) {
            console.log(
              "Network error: No response received from server, Json_GetClientCardDetails",
              error
            );
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, Json_GetClientCardDetails",
        error
      );
    }
  }

  function getAllClientByFolderid(pid) {
    try {
      let p = { ...objReq, ...pid };
      dispatch(
        GetClientsByFolder_Json_Redux(p, function (res) {
          if (res) {
            // let result = res.length>0?res.map((el)=>{
            //   let o =Object.assign({},el);
            //   o.Client=el.Client+" ("+el.ClientID+")";
            //   return o;
            // }):[];
            setGetClient(
              res.length > 0
                ? res.map((el) => ({
                    ...el,
                    Client: `${el.Client} (${el.ClientID})`,
                  }))
                : []
            );
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetClientsByFolder_Json",
        error
      );
    }
  }

  function getAllSectionByFolderid(pid) {
    try {
      let p = { ...objReq, ...pid };
      dispatch(
        GetSectionsByFolder_Json_Redux(p, function (res) {
          if (res) {
            setSectionList(res);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetClientsByFolder_Json",
        error
      );
    }
  }

  function Json_GetForwardUserList(fid) {
    try {
      let o = {};
      o.ProjectId = fid;
      o.SectionId = "-1";
      let p = { ...objAgr, ...o };
      dispatch(
        Json_GetForwardUserList_Redux(p, function (data) {
          if (data) {
            let dt = data.Table;
            if (dt.length > 0) {
              let result = dt.filter((el) => {
                return el.CGroup !== "Yes";
              });
              if (result.length > 0) {
                result.map((el) => {
                  if (el.ID === parseInt(localStorage.getItem("UserId"))) {
                    setOwnerID(el.ID);
                    handleObjectDispatch("FromUser", el);
                  }
                });
              }
              setUserList(result);
            }
          }
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleAutocompleteChangeTo = (event, newValue) => {
    setSelectedEmail(newValue ? newValue : null);
    handleObjectDispatch("ToEmail", newValue ? newValue : null);
    if (newValue) {
      let res = portalUser.filter((user) => {
        let unk = newValue.find((u) => u.ContactNo === user.ContactNo);
        return unk === undefined; // If unk is undefined, it means there's no matching ContactNo in newValue
      });
      setPortalUserCC(res);
    } else {
      console.log("selected email11", portalUser); // If newValue is null, log the entire portalUser
    }
  };

  const [selectedEmailCC, setSelectedEmailCC] = useState(null);

  const handleAutocompleteChangeCC = (event, newValue) => {
    setSelectedEmailCC(newValue ? newValue : null);

    handleObjectDispatch("CCEmail", newValue ? newValue : null);
    if (newValue) {
      let res = portalUser.filter((user) => {
        let unk = newValue.find((u) => u.ContactNo === user.ContactNo);
        return unk === undefined; // If unk is undefined, it means there's no matching ContactNo in newValue
      });
      setPortalUserTo(res);
    } else {
      console.log("selected email11", portalUser); // If newValue is null, log the entire portalUser
    }
  };

  const [isCheckedWithOutmgs, setisCheckedWithOutmgs] = useState(false);

  const handleCheckboxChangeisCheckedWithOutmgs = (event) => {
    setisCheckedWithOutmgs(event.target.checked);
  };

  const handleEditorChange = (content, editor) => {
    setTemplateDataMarkup(content);
    handleObjectDispatch("TextEditor", content);
  };

  const [isChecked, setIsChecked] = useState(false);
  // const location = useLocation()
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    handleObjectDispatch("isMessage", "true");

    if (event.target.checked) {
      handleObjectDispatch("TextEditor", "");
      handleObjectDispatch("isMessage", "true");

      setIsSingDisabled(true);
      setIsCheckedSignature(false);
    } else {
      handleObjectDispatch("isMessage", "false");

      setIsSingDisabled(false);
      // setIsCheckedSignature(true);
    }
  };

  const [isCheckedSignature, setIsCheckedSignature] = useState(true);

  const handleCheckboxChangeSignature = (event) => {
    setTemplateDataMarkup("");
    setIsCheckedSignature(event.target.checked);
    handleObjectDispatch("isSignature", "true");

    if (event.target.checked) {
      let data =
        templateDataMarkup +
        window.atob(
          selector.GetUsersEmailSignature ? selector.GetUsersEmailSignature : ""
        );
      setTemplateDataMarkup(data);
    } else {
      let data = templateDataMarkup.replace(
        window.atob(
          selector.GetUsersEmailSignature ? selector.GetUsersEmailSignature : ""
        ),
        ""
      );
      handleObjectDispatch("isSignature", "false");
      setTemplateDataMarkup(data);
      handleObjectDispatch("TextEditor", data);
    }
  };

  const handleClickOpen = () => {
    dispatch(handleOpenContactModal(true));
  };

  const handleResetForm = () => {
    dispatch(dispatch(setComposePortalMgsObj_Redux([])));
  };

  const handleResetMessageBody = () => {
    setTemplateDataMarkup("");
    handleObjectDispatch("TextEditor", "");
  };

  const MessaageCompile = (msg) => {
    if (isCheckedSignature) {
      let data = msg.replace(
        window.atob(
          selector.GetUsersEmailSignature ? selector.GetUsersEmailSignature : ""
        ),
        ""
      );
      setTemplateDataMarkup(
        data +
          window.atob(
            selector.GetUsersEmailSignature
              ? selector.GetUsersEmailSignature
              : ""
          )
      );
    } else {
      setTemplateDataMarkup(msg);
    }
  };

  const getMessageTemplate = (msg) => {
    let body = {
      strFolderId: msg?.ProjectID,
      strSectionId: msg?.ItemTypeId,
      strTemplateId: msg?.TemplateID,
      strClientId:
        ComposePortalMgsObj?.ToEmail?.length > 0
          ? ComposePortalMgsObj?.ToEmail[0]?.OriginatorNo
          : 1,
      ContactEmail: "aakashr.diploma2022@ssism.org",
    };
    handleObjectDispatch("Section", msg);
    dispatch(
      ad_Json_GetStandardLetterData_Action(body, (res) => {
        dispatch(
          ad_Json_GetHtmlFromRtf_Action({ strRtf: res }, (res) => {
            if (templateDataMarkup?.length > 0) {
              let text =
                "Selected template will be appended to the current message, do you wish to proceed?";
              dispatch(
                sonamConfirmMessage(text, (confirm) => {
                  if (confirm) {
                    MessaageCompile(templateDataMarkup + JSON.parse(res));
                    // setTemplateDataMarkup(templateDataMarkup + JSON.parse(res));
                  } else {
                    setTemplateDataMarkup(templateDataMarkup);
                  }
                })
              );
            } else {
              MessaageCompile(JSON.parse(res));
              // setTemplateDataMarkup(JSON.parse(res));
            }
          })
        );
      })
    );
  };

  // console.log("✌️ComposePortalMgsObj.ToEmail --->", ComposePortalMgsObj);
  return loader ? (
    <CustomLoader />
  ) : (
    <Box className="mt-2 container-fluid">
      {/* <h2 className="title mt-4 mb-3">Compose Portal Message  </h2> */}

      <Grid container spacing={2}>
        <Grid item xl={4} md={6} xs={12}>
          <Box className="custom-autocomplete">
            <AutoComplete
              ComData={getFolder}
              target="Folder"
              comFun={getFolderObject}
              lable={"Folder"}
              size="small"
              defaultValue={
                Object.keys(ComposePortalMgsObj).length !== 0 &&
                ComposePortalMgsObj.Folder
                  ? ComposePortalMgsObj?.Folder
                  : ""
              }
            />
          </Box>
          {/* <AutoComplete  lable={'Folder'} /> */}
        </Grid>
        {ComposePortalMgsObj?.isMessage && (
          <Grid item xl={4} md={6} xs={12}>
            <AutoComplete
              ComData={sectionList}
              target="Sec"
              comFun={getSectionObject}
              defaultValue={
                Object.keys(mainObject).length !== 0 && mainObject.Section
                  ? mainObject?.Section
                  : ""
              }
              lable={"Section"}
            />
          </Grid>
        )}
        <Grid item xl={4} md={6} xs={12}>
          <Box className="d-flex">
            <Box className="custom-autocomplete w-100">
              <AutoComplete
                ComData={getClient}
                target="Client"
                comFun={getClientObject}
                size="small"
                defaultValue={
                  Object.keys(ComposePortalMgsObj).length !== 0 &&
                  ComposePortalMgsObj?.Client
                    ? ComposePortalMgsObj.Client
                    : ""
                }
                lable={"Reference"}
              />
            </Box>
            <Button
              className="btn-blue ms-2"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => dispatch(setOpenCompanyModal(true))}
            >
              Add
            </Button>
          </Box>
        </Grid>

        <Grid item xl={4} md={6} xs={12}>
          <Box className="custom-autocomplete">
            <AutoComplete
              ComData={userList}
              target="ForwardTo"
              size="small"
              comFun={getForwardUsernObject}
              defaultValue={
                Object.keys(ComposePortalMgsObj).length !== 0 &&
                ComposePortalMgsObj.FromUser
                  ? ComposePortalMgsObj.FromUser
                  : ""
              }
              lable={"From"}
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box className="d-flex">
            <Box className="w-100">
              <CheckboxesMultiSelectTags
                defaultValue={
                  ComposePortalMgsObj &&
                  ComposePortalMgsObj.ToEmail &&
                  ComposePortalMgsObj.ToEmail.length > 0
                    ? ComposePortalMgsObj.ToEmail
                    : []
                }
                portalUserTo={portalUserTo ? portalUserTo : ""}
                comFun={handleAutocompleteChangeTo}
                label={"To"}
                fildName={"E-Mail"}
                className="w-100"
                size="small"
              />{" "}
            </Box>
            {/* <AutoComplete lable={'To'} /> */}
            {/* defaultValue={Object.keys(mainObject).length !== 0 && mainObject.ToEmail?.ToEmail?mainObject.ToEmail:""} */}
            <Button
              className="btn-blue ms-2"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <CheckboxesMultiSelectTags
            defaultValue={
              ComposePortalMgsObj &&
              ComposePortalMgsObj.CCEmail &&
              ComposePortalMgsObj.CCEmail.length > 0
                ? ComposePortalMgsObj.CCEmail
                : []
            }
            portalUserTo={portalUserCC ? portalUserCC : ""}
            comFun={handleAutocompleteChangeCC}
            label={"CC"}
            fildName={"E-Mail"}
          />
        </Grid>

        <Grid item xs={12} className="mb-2">
          <TextField
            // required
            label="Subject"
            variant="outlined"
            value={ComposePortalMgsObj?.Subject || ""}
            fullWidth
            size="small"
            fontSize="small"
            onChange={(e) => {
              settxtSubject(e.target.value);
              handleObjectDispatch("Subject", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Box className="mb-2">
            <FormControlLabel
              checked={isCheckedWithOutmgs}
              onChange={handleCheckboxChangeisCheckedWithOutmgs}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  size="small"
                />
              }
              label="Exclude Message"
              className="font-12"
              size="small"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isSingDisabled}
                  checked={isCheckedSignature}
                  onChange={handleCheckboxChangeSignature}
                  size="small"
                />
              }
              label="Append Signature"
            />

            {/* <AutoComplete
            ComData={smsTemplate}
            target="Name"
            comFun={getTemplatesObject}
            lable={"Tamplate"}
            defaultValue={
               Object.keys(mainObject).length !== 0 && mainObject.Name
                ? mainObject?.Name
                : ""
            }
          /> */}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="d-flex justify-content-end">
            <Button
              className="btn-blue ms-2"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleResetForm}
            >
              Reset Form
            </Button>
            {setTemplateDataMarkup.length > 0 && (
              <Button
                className="btn-blue ms-2"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleResetMessageBody}
              >
                Reset Massage Body
              </Button>
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={6}>
          <Box className="custom-autocomplete">
            <AutoComplete
              ComData={smsTemplate}
              target="Name"
              comFun={getTemplatesObject}
              lable={"Message Template"}
              defaultValue={
                Object.keys(mainObject).length !== 0 && mainObject.SMSTemplate
                  ? mainObject?.SMSTemplate
                  : ""
              }
            />
          </Box>
        </Grid> */}
      </Grid>

      {/* <div className="row">
        <div className="col-lg-7">
          
        </div>
      </div> */}

      {/* <hr /> */}

      {!isChecked && (
        <Box className="row">
          <Box className="col-lg-5">
            <Box sx={{}} className="message-template-list">
              <SimpleTreeView>
                {newSectionList.length > 0 &&
                  newSectionList.map((sec, secInd) => (
                    <TreeItem
                      itemId={`tree-item-${secInd}`}
                      label={sec?.ItemName}
                      key={secInd}
                      className="font-12"
                      onClick={(e) => getSectionObject(e, sec)}
                    >
                      {templateDataList?.length > 0
                        ? templateDataList.map(
                            (tempMessge, tempInd) =>
                              tempMessge?.ItemTypeId === sec?.ItemTypeId &&
                            tempMessge?.ProjectID ===
                                ComposePortalMgsObj?.Folder?.FolderID && (
                                <TreeItem
                                  itemId={`grid-community${tempInd}`}
                                  label={tempMessge?.Description}
                                  onClick={() => getMessageTemplate(tempMessge)}
                                  className="font-12"
                                />
                              )
                          )
                        : "No data"}
                    </TreeItem>
                  ))}
              </SimpleTreeView>
            </Box>
          </Box>

          <Box className="col-lg-7">
            <Grid item xs={7}>
              <HtmlEditorDX
                templateDataMarkup={templateDataMarkup}
                setTemplateDataMarkup={setTemplateDataMarkup}
                handleEditorChange={handleEditorChange}
                height={`calc(100vh - 440px)`}
              />
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ComposePortalMessage;
