import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SentMessages from "./SentMessages";
import ReceivedMessages from "./ReceivedMessages";
import RetractedMessages from "./RetractedMessages";
import { useDispatch } from "react-redux";
import { getClientIdMethod_Redux, getClientIdName_Redux } from "../../../redux/akSlice";
export default function PortalMessages() {
const dispatch = useDispatch()
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    return () => {
      dispatch(getClientIdMethod_Redux([]))
      dispatch(getClientIdName_Redux([]));
    }
  },[])
  const { pathname } = window.location;
  const getPageTitle = () => {
    switch (true) {
      case pathname.includes("templates"):
        return "Template List";
      case pathname.includes("portalmessage/sent"):
        return "Portal Sent Messages ";
      case pathname.includes("share-box"):
        return "Share Box";
      case pathname.includes("contacts/contact-details"):
        return "Contact Detail";
      case pathname.includes("contact/contacts"):
        return "Contact List";
      case pathname.includes("contact/companies"):
        return "Company List";
      case pathname.includes("user/client-details"):
        return "Company Details";
      case pathname.includes("incoming-message-viewer"):
        return "Incoming Message";
      case pathname.includes("all-requests"):
        return "All Request";
      default:
        return "Portal Messages";
    }
  };


  return (
    <Box>
      <h2 className="title mb-0 nowrap d-sm-none d-block font-16">{getPageTitle()}</h2>
      <Box sx={{ width: "100%", typography: "body1" }} className="custom-tabs">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} className='pt-1'>
            <TabList onChange={handleChange} aria-label="lab API tabs example" className="mb-0 flex-wrap-tabs min-h-auto">
              <Tab label="Sent Messages" value="1" />
              <Tab label="Received Messages" value="2" />
              <Tab label="Retracted Messages" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" className="p-0 mt-">
            <SentMessages value={value}/>
          </TabPanel>
          <TabPanel value="2" className="p-0 mt-">
            <ReceivedMessages value={value}/>
          </TabPanel>
          <TabPanel value="3" className="p-0 mt-">
            <RetractedMessages value={value}/>
          </TabPanel>
          
        </TabContext>
      </Box>
    </Box>
  );
}
