import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
// import CreateTemplate from './CreateTemplate';
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomLoader from "../../../components/CustomLoader";
import NoData from "../../../components/NoData";
import {
  GetAllFolders_Json_Redux,
  GetPortalDocReqTemplates_Json_Redux,
  PortalDocTemplateDeleted_Json_Redux,
} from "../../../redux/api_helper";
import { setRequestDocument_Redux } from "../../../redux/docuSlice";
import CreateTemplate from "./CreateTemplate";
import TemplateMenuItem from "./TamplateMenuItem";

const FolderId = localStorage.getItem("FolderId");

const ITEM_HEIGHT = 48;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 12 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Templates() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const allTempList = useSelector(
    (state) => state.docu.PortalDocReqTemplatesLiset
  );

  const [getFolderList, setGetFoldersList] = React.useState([]);
  const [defaltFolderData, setDefaltFolderData] = React.useState({});
  const [showTemplateList, setShowTemplateList] = React.useState(allTempList);
  const [editTemplate, setEditTemplate] = React.useState(false)
  const [searchString, setsearchString] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [txtFolderid, setTxtFolderid] = React.useState(FolderId);
  const [isLoding, setIsLoding] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        if (res) {
          setGetFoldersList(res);
          setIsLoding(false);
          let result = res.filter((e) => e.FolderID === parseInt(FolderId));
          if (result.length > 0) {
            setDefaltFolderData(result[0]);
          }
        }
      })
    );
    getAllTemplates(FolderId);
    if (selectedTemplate) {
      try {
        if (
          selectedTemplate.action === "Edit" ||
          selectedTemplate.action === "Delete"
        ) {
          console.log("set selected item111...", selectedTemplate.data);

          dispatch(setRequestDocument_Redux(selectedTemplate.data));
        }
      } catch (error) {}
    }
  }, [dispatch, selectedTemplate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFolder = (e) => {
    try {
      getAllTemplates(e.FolderID);
      setTxtFolderid(e);
    } catch (error) {
      console.log(error);
    }
  };

  function getAllTemplates(pid) {
    try {
      let o = {
        accId: localStorage.getItem("agrno"),
        email: localStorage.getItem("Email"),
        password: localStorage.getItem("password"),
        allTemplates: "true",
        folder: pid,
        forCompose: "false",
      };
      dispatch(GetPortalDocReqTemplates_Json_Redux(o, (res) => {}));
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalDocReqTemplates_Json",
        error
      );
    }
  }

  const handleClickOpenModal = () => {
    setOpenModal(true);
    setEditTemplate(true)
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const deleteTemplate = (item) => {
    console.log("selectedTemplate", item);
    try {
      Swal.fire({
        // title: "Are you sure you want to delete this item?",
        text: "Are you sure you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let o = { id: item.DocTempID };
          dispatch(
            PortalDocTemplateDeleted_Json_Redux(o, (res) => {
              if (res) {
                toast.success("Template Deleted");
                getAllTemplates(txtFolderid?.FolderID);
              }
            })
          );
        }
      });
    } catch (error) {
      console.log("Delete Template", error);
    }
  };

  const handleSearchChange = (e) => {
    setsearchString(e.target.value);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (searchString.length > 2) {
        const filteredTemplates = allTempList.filter(
          (template) =>
            template.TemplateName &&
            template.TemplateName.toLowerCase().includes(
              searchString.toLowerCase()
            )
        );
        setShowTemplateList(filteredTemplates);
      } else {
        setShowTemplateList(allTempList);
      }
    }, 1000);
  }, [searchString, allTempList]);

  const { pathname } = window.location;
  const getPageTitle = () => {
    switch (true) {
      case pathname.includes("templates"):
        return "Template List";
      case pathname.includes("portalmessage/sent"):
        return "Portal Sent Messages ";
      case pathname.includes("share-box"):
        return "Share Box";
      case pathname.includes("contacts/contact-details"):
        return "Contact Detail";
      case pathname.includes("contact/contacts"):
        return "Contact List";
      case pathname.includes("contact/companies"):
        return "Company List";
      case pathname.includes("user/client-details"):
        return "Company Details";
      case pathname.includes("incoming-message-viewer"):
        return "Incoming Message";
      case pathname.includes("all-requests"):
        return "All Request";
      default:
        return "Portal Messages";
    }
  };

  return (
    <Box className="container-fluid p-0 p-sm-2">
      <Box className="row">
        <Box className="col-xxl-12 p-1">
          <Box className="">
            <Box className="title mt-2 nowrap d-sm-none d-block font-16">
              {getPageTitle()}
            </Box>
            <Box className="d-flex align-items-center justify-content-between my-2">
              <Box
                className="search-input"
                sx={{
                  width: 340,
                  maxWidth: "100%",
                }}
              >
                <SearchIcon />
                <TextField
                  fullWidth
                  label="Search"
                  size="small"
                  onChange={handleSearchChange}
                />
              </Box>
              {/* <Button variant='contained' className='btn-blue'>Create Folder</Button> */}

              <Button
                variant="contained" // Button variant 'contained' for a filled style
                className="btn-blue" // Custom class for additional styling
                onClick={() => {
                  // Event handler triggered on button click
                  navigate("/user/templates/create-templates"); // Navigate to a new route
                  dispatch(setRequestDocument_Redux({})); // Dispatch a Redux action
                }}
                startIcon={<CreateIcon />} // Adds an icon before the text in the button
              >
                Create Template
              </Button>
            </Box>

            {/* <hr /> */}

            {isLoding ? (
              <>
                <CustomLoader />
              </>
            ) : getFolderList.length > 0 ? (
              <>
                <Box
                  sx={{ flexGrow: 1 }}
                  className="custom-tabs d-flex flex-wrap flex-sm-nowrap"
                >
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className="text-left tabs-vertical justify-content-start"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      width: '300px',
                      textAlign: 'left',
                    }}
                  >
                    {getFolderList && getFolderList.length > 0
                      ? getFolderList.map((item, index) => {
                          // if(item.FolderID===FolderId){
                          //     return <Tab className='text-left' icon={<PersonIcon />} iconPosition="start" label={item.Folder} {...a11yProps(index)} />
                          // }
                          return (
                            <Box style={{ overflow: "hidden", padding: '3px 15px 3px 0' }} key={index}>
                              <Tab
                                className="text-left w-100 justify-content-start"
                                key={index}
                                onClick={() => handleClickFolder(item)}
                                icon={<PersonIcon className="font-16" />}
                                iconPosition="start"
                                label={item.Folder}
                                {...a11yProps(index)}
                              />
                            </Box>
                          );
                        })
                      : ""}
                  </Tabs>

                  <Box className="w-100 custom-tabs-box tabs-vertical">
                    <TabPanel value={value} className="p-0 tabs-list" index={0}>
                      <MenuList className="p-0">
                        {/* {templateList.length>0? <CustomLoader></CustomLoader>:null} */}

                        {showTemplateList && showTemplateList.length > 0 ? (
                          showTemplateList.map((item, index) => (
                            <TemplateMenuItem
                              key={index}
                              item={item}
                              setSelectedTemplate={setSelectedTemplate}
                              handleClickOpenModal={handleClickOpenModal}
                              deleteTemplate={deleteTemplate}
                            />
                          ))
                        ) : (
                          <NoData message="No Attachments here"></NoData>
                        )}
                      </MenuList>
                    </TabPanel>
                    <TabPanel value={value} className="p-0" index={1}>
                      Item Two
                    </TabPanel>
                    <TabPanel value={value} className="p-0" index={2}>
                      Item Three
                    </TabPanel>
                    <TabPanel value={value} className="p-0" index={3}>
                      Item Four
                    </TabPanel>
                    <TabPanel value={value} className="p-0" index={4}>
                      Item Five
                    </TabPanel>
                    <TabPanel value={value} className="p-0" index={5}>
                      Item Six
                    </TabPanel>
                    <TabPanel value={value} className="p-0" index={6}>
                      Item Seven
                    </TabPanel>
                  </Box>
                </Box>
              </>
            ) : getFolderList == 0 ? (
              <>
                <NoData messsage="No Attachments here" />
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        // sx={{ maxWidth: '1600px', width: '100%' }}
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-request-document modal-lg"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Edit Template"}</DialogTitle>
          </Box>
          <Button onClick={handleCloseModal} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateTemplate
              isHideTitle={false}
              selectedTemplate={selectedTemplate}
              EditTemplate={editTemplate}
              TempFolderid={txtFolderid}
            />
          </DialogContentText>

          <DialogActions className="pt-4 px-0">
            <Button
              onClick={handleCloseModal}
              className="btn-red"
              variant="outlined"
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCloseModal}
              className="btn-blue"
              autoFocus
              variant="outlined"
              startIcon={<CreateIcon />}
            >
              Save Template
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
