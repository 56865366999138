import PrintIcon from "@mui/icons-material/Print";
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import NoData from "../../components/NoData";

// import pdf from "../../assets/images/files-icon/pdf.png";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import csv from "../../assets/images/files-icon/csv.png";
import doc from "../../assets/images/files-icon/doc.png";
import jpg from "../../assets/images/files-icon/jpg.png";
import pdf from "../../assets/images/files-icon/pdf.png";
import png from "../../assets/images/files-icon/png.png";
import text from "../../assets/images/files-icon/text.png";
import xls from "../../assets/images/files-icon/xls.png";
import userImg from "../../assets/images/user-2.svg";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import { GetSignature_Json_Redux } from "../../redux/ak_api_helper";
import {
  setOpenDocumentPreviewModal,
  setOpenSignDocument,
} from "../../redux/modalSlice";

// import Signature from "../../assets/images/icons/icons8-autograph-24.png"
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ReactToPrint from "react-to-print";
import Comments from "../../components/Comments";
import CustomLoader from "../../components/CustomLoader";
import TimelineModal from "../../modals/TimelineModal";
import {
  GetAllSentMessagesByFolder_Json_Redux,
  GetMessageAttachments_Json_Redux,
} from "../../redux/api_helper";
import {
  downloadShareboxDocument,
  getApprovalCertificate,
  getApprovalCommentsHtml,
  getMessageHtml,
  getPortalMessageComments,
  getPortalMessageDocuments,
} from "../../redux/dev_chetan_api_helper";
import {
  setInboxMessageHtmlLoading,
  setOpenFromInbox,
  setPortalDocIdForSignDocModal,
  setPortalMessageDocumentsLoading,
  setshareboxDocForPreviewURL,
} from "../../redux/dev_chetan_slice";
import AttachmentStatusComponant from "../../user/componants/requestComponant/AttachmentStatusComponant";
import {
  clientBodyaccidData,
  GetDocTypeIcon2Requests,
  GetrequestTime,
} from "../../utils/RequestsFunction";
import ClientRequestDetails from "./ClientRequestDetails";
import {
  Dmessage_GetAllCommentsByToken_Json_Action,
  Dmessage_GetAllDetailsByMessageId_Json_Action,
  Dmessage_GetApprovalsAndCommentsHtmlByToken_Json_Action,
  Dmessage_GetMessageDocumentsByToken_Json_Action,
  Dmessage_MarkMessageAsViewedByToken_Json_Action,
} from "../../redux/dev_aditya_action";
import dayjs from "dayjs";
import { Ad_DirectMessageApiBody } from "../../redux/adityaReducer";
import HtmlEditorDX from "../../user/pages/create-request/HtmlEditor";
// import user from "../assets/images/user-2.svg";
import user from "../../assets/images/user-2.svg";
import CommentIcon from "@mui/icons-material/Comment";
import DocumentPreviewModal from "../../modals/DocumentPreviewModal";

export default function DirectMessageViewer() {
  const location = useLocation();
  const [token, setToken] = useState({});

  const portalObj = {
    accid: localStorage.getItem("agrno")
      ? localStorage.getItem("agrno")
      : "0003",
    email: localStorage.getItem("Email")
      ? localStorage.getItem("Email")
      : "patrick@docusoft.net",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "UGF0cmljazEyMy4=",
    portalUserEmail: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
  };

  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const { PortalUsersInbox, Message } = useSelector(
    ({ aditya }) => aditya?.directMessageAllData
  );
  const { openDocumentPreviewModal } = useSelector((state) => state.modals);
  const { ad_MessageAttachmentList, ad_Dm_Api_Body, requestcommentList } =
    useSelector(({ aditya }) => aditya);
  // console.log("✌️ad_MessageAttachmentList --->", ad_MessageAttachmentList);
  // console.log("✌️PortalUsersInbox --->", PortalUsersInbox);
  // const { PortalUsersInbox, Message } = directMessageAllData;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const Code = searchParams.get("Code");
  const portalDocID = searchParams.get("PortalDocID");
  const ReferenceName = searchParams.get("ReferenceName");
  const ClientName = searchParams.get("ClientName");
  const customerId = searchParams.get("customerId");
  const EmailID = searchParams.get("EmailID");
  const MessageID = searchParams.get("MessageID");
  const messageStatus = searchParams.get("messageStatus");

  const clientLoginStatus = localStorage.getItem("LoginClient");

  const [dmCommentHtml, setDmCommentHtml] = useState(null);
  const [dmCommentJson, setDmCommentJson] = useState(null);

  useEffect(() => {
    if (Code) {
      const param = atob(Code).split("#");
      if (param && param.length > 0) {
        let newb = {
          accid: param[0],
          token: param[1],
          messageId: "212ac0c089be48d7a5c088d179681109",
          // messageId: param[2],
        };
        dispatch(Ad_DirectMessageApiBody(newb));
        setToken(newb);
        dispatch(setInboxMessageHtmlLoading(true));
        dispatch(setPortalMessageDocumentsLoading(true));
        pageLoadData(newb);
      }
    }
  }, [Code]);

  const pageLoadData = async (newb) => {
    const param = atob(Code).split("#");
    // if (token) {
    // get Message Dat
    !PortalUsersInbox &&
      (await dispatch(
        Dmessage_GetAllDetailsByMessageId_Json_Action(newb, (msgDetail) => {
          // console.log("✌️msgDetail--->", msgDetail);
        })
      ));

    // mark view Message
    // await dispatch(
    //   Dmessage_MarkMessageAsViewedByToken_Json_Action(newb, (markView) => {
    //     // console.log("markView --->", markView);
    //   })
    // );

    // get comment html
    await dispatch(
      Dmessage_GetApprovalsAndCommentsHtmlByToken_Json_Action(
        newb,
        (commentHtml) => {
          setDmCommentHtml(JSON.parse(commentHtml));
          // console.log("✌️commentHtml --->", commentHtml);
        }
      )
    );

    // get comment json
    await dispatch(
      Dmessage_GetAllCommentsByToken_Json_Action(newb, (commentJson) => {
        // console.log("✌️commentJson --->", commentJson);
        setDmCommentJson(commentJson);
      })
    );

    // message Document List
    Object.keys(ad_MessageAttachmentList).length === 0 &&
      (await dispatch(
        Dmessage_GetMessageDocumentsByToken_Json_Action(
          newb,
          (AttachmentJson) => {
            // console.log("AttachmentJson --->", AttachmentJson);
          }
        )
      ));
    // }
    dispatch(setInboxMessageHtmlLoading(false));
    dispatch(setPortalMessageDocumentsLoading(false));
  };

  // Attachment Dropudoun
  const [EditanchorEl, EditsetAnchorEl] = React.useState({});
  // const Editopen = Boolean(EditanchorEl);

  const EdithandleClick = (event, index) => {
    EditsetAnchorEl({ ...EditanchorEl, [index]: event.currentTarget });
  };

  const EdithandleClose = (index) => {
    let temp = { ...EditanchorEl };
    delete temp[index];
    EditsetAnchorEl(temp);
  };

  const PreviewAttachment = (data) => {
    EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const PreviewNewTab = (data) => {
    EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setshareboxDocForPreviewURL(url));
    window.open(url, "_blank");
  };

  // Button DropDoun
  const [EditanchorE2, EditsetAnchorE2] = React.useState(null);
  const {
    inboxMessageHtml,
    portalMessageDocuments,
    portalMessageComments,
    approvalCertificateBase64,
    portalApprovalCommentsHtml,
    inboxMessageHtmlLoading,
    portalMessageDocumentsLoading,
  } = useSelector((state) => state.dev_chetan);
  // console.log("✌️inboxMessageHtmlLoading --->", inboxMessageHtmlLoading);

  const { GetMessageAttachments } = useSelector((state) => state.docu);

  let certificateUrl = `data:application/pdf;base64,${approvalCertificateBase64}`;

  function groupByPortalDocId(documents) {
    return documents.reduce((acc, doc) => {
      if (!acc[doc.PortalDocId]) {
        acc[doc.PortalDocId] = [];
      }
      acc[doc.PortalDocId].push(doc);
      return acc;
    }, {});
  }

  const [value, setValue] = React.useState("1");

  const Item = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.5),

    color: theme.palette.text.secondary,
  }));

 

  const downloadFile = (e, doc) => {
    e.stopPropagation();
    dispatch(
      downloadShareboxDocument(
        portalDocID,
        doc.Attachid,
        doc.DocExtension,
        doc.PortalName
      )
    );
  };

  const downloadAllDocs = (e) => {
    ad_MessageAttachmentList.forEach((doc) => {
      downloadFile(e, doc);
    });
  };

  
  const handlePreview = (data) => {
    console.log("✌️data --->", data);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${ad_Dm_Api_Body.accid}&attachment=${data.Attachid}&Id=${ad_Dm_Api_Body?.messageId}&extension=${data.DocExtension}`;
    console.log("✌️url --->", url);
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const handlePrint = () => {
    window.print();
  };

  // tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const signBody = {
    accid: localStorage.getItem("accIdClient"),
    sPassword: localStorage.getItem("passwordClient"),
    username: localStorage.getItem("EmailClient"),
  };

  const formattedDate = (timestamp) => dayjs(timestamp).format("DD/MM/YYYY");

  const componentRef = useRef();

  const status = (() => {
    if (PortalUsersInbox?.Expired === "Yes") {
      return "Expired";
    }
    if (PortalUsersInbox?.ForApproval === "No") {
      return "For Info";
    }
    if (
      PortalUsersInbox?.FileApproved === null &&
      PortalUsersInbox?.ForApproval === "Yes"
    ) {
      return "Pending";
    }
    if (
      PortalUsersInbox?.FileApproved === "Yes" &&
      PortalUsersInbox?.ForApproval === "Yes"
    ) {
      return "Approved";
    }
    if (
      PortalUsersInbox?.FileApproved === "Yes" &&
      PortalUsersInbox?.ForApproval === "No"
    ) {
      return "Disapproved";
    }
    return "Pending";
  })();

  const renderCommnetMessage = useMemo(() => {
    return (
      <Box className="p-2">
        {requestcommentList.length > 0 &&
          requestcommentList.map((item, key) => (
            <Box
              className="d-flex align-items-center justify-content-center"
              key={key}
            >
              <Box
                className="d-flex align-items-center justify-content-center border-bottom p-3 mb-2 flex-wrap flex-sm-nowrap rounded "
                sx={{ background: "#ffffff", width: "100%" }}
              >
                <Box className="d-flex align-items-start my-2 justify-content-center">
                  <>
                    <img
                      src={user}
                      height={30}
                      width={30}
                      // sx={{ width: 20, height: 20 }}
                      className="me-2"
                    />{" "}
                  </>
                </Box>
                <Box className="w-100 ms-2 ">
                  <Box
                    className="d-flex align-items-center justify-content-between "
                    sx={{ height: "40px" }}
                  >
                    <p className=" font-12 text-wrap poppins-semibold">
                      The following response was{" "}
                      {item?.Commenter ? "received" : "sent"}:
                    </p>
                    <p>{GetrequestTime(item?.DateOfRemark)}</p>
                  </Box>

                  <Box className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-12" sx={{ color: "#808080" }}>
                      {item?.Remark}
                    </span>
                    {/* <Button className="btn-blue">Learn More</Button> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    );
  }, [requestcommentList]);

  // console.log('✌️status --->', status);

  // comment functions

  const [comment, setComment] = useState("");
  const [editorText, setEditorText] = useState("");

  const handleEditorChange = (e, editor) => {
    let text = editor.getContent({ format: "text" });
    setComment(text);
    setEditorText(e);
  };
  const saveMessageComment = () => {

  };
  return (
    <>
      <Box className='container' ref={componentRef}>
        {inboxMessageHtmlLoading ? (
          <CustomLoader />
        ) : (
          <>
            {status == "Expired" && (
              <Box className="mb-3">
                <Alert variant="filled" severity="error">
                  This message has expired
                </Alert>
              </Box>
            )}

            <Box sx={{ width: "100%", typography: "body1", marginTop: "12px" }}>
              <TabContext value={value}>
                <Box className="clearfix">
                  {status !== "Expired" && (
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="custom-tabs mb-0 flex-wrap-tabs min-h-auto"
                    >
                      <Tab label="Message" value="1" />

                      <Tab label="Request" value="2" />
                    </TabList>
                  )}
                </Box>

                <TabPanel value="1" className="p-0">
                  <Box className="clearfix">
                    <Box className="white-box">
                      <Box className="d-flex align-items-center justify-content-between">
                        <h2 className="title">
                          {PortalUsersInbox?.Subject
                            ? PortalUsersInbox?.Subject
                            : "Not Available"}
                        </h2>

                        {!ReferenceName && status === "Pending" && (
                          <Box className="d-flex justify-content-end">
                            <Button
                              variant="contained"
                              // startIcon={checkCircleIcon}
                              size="small"
                              className="btn-blue me-2 mb-3"
                              onClick={() => {
                                dispatch(
                                  GetSignature_Json_Redux({
                                    ...signBody,
                                  })
                                );
                                dispatch(setOpenSignDocument(true));
                                dispatch(
                                  setPortalDocIdForSignDocModal(portalDocID)
                                );
                                dispatch(setOpenFromInbox(true));
                              }}
                            >
                              Action
                            </Button>
                          </Box>
                        )}
                      </Box>

                      <Box className="mt-1">
                        <Box className="d-flex justify-content-between align-items-center flex-wrap">
                          <Box className="d-flex align-items-center mb-3 mb-sm-0 flex-wrap">
                            <Box className="me-3">
                              {userImg && (
                                <img
                                  src={userImg}
                                  height={35}
                                  width={35}
                                  style={{
                                    borderRadius: "50%",
                                    background: "#eea706",
                                  }}
                                />
                              )}
                            </Box>

                            <Box>
                              <Typography
                                variant="h3"
                                className="font-14 poppins-semibold"
                              >
                                {PortalUsersInbox?.CName
                                  ? PortalUsersInbox?.CName
                                  : "Not Available"}
                              </Typography>

                              <Typography variant="p">
                                {PortalUsersInbox?.EmailID
                                  ? PortalUsersInbox?.EmailID
                                  : "Not Available"}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="d-flex ">
                            <Box className="d-flex flex-wrap">
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="poppins-semibold">Section</p>
                                <p className="text-gray font-12 poppins-medium">
                                  {PortalUsersInbox?.ItemName
                                    ? PortalUsersInbox?.ItemName
                                    : "No Date Available"}
                                </p>
                              </Box>
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="poppins-semibold">Recived Date</p>
                                <p className="text-gray font-12 poppins-medium">
                                  {PortalUsersInbox?.ViewDate
                                    ? formattedDate(PortalUsersInbox?.ViewDate)
                                    : "No Date Available"}
                                </p>
                              </Box>
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="poppins-semibold">Action By</p>
                                <p className="text-gray font-12 poppins-medium">
                                  {PortalUsersInbox?.ADate
                                    ? formattedDate(PortalUsersInbox?.ADate)
                                    : "No Date Available"}
                                </p>
                              </Box>
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="poppins-semibold">Expiry Date</p>
                                <p className="text-gray font-12 poppins-medium">
                                  {PortalUsersInbox?.ExpiryDate
                                    ? formattedDate(
                                        PortalUsersInbox?.ExpiryDate
                                      )
                                    : "No Date Available"}
                                </p>
                              </Box>
                            </Box>

                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  className="min-width-auto ms-1"
                                  size="small"
                                  onClick={handlePrint}
                                >
                                  <PrintIcon className="text-black" />
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                            {!clientLoginStatus && (
                              <TimelineModal location={location} />
                            )}
                          </Box>
                        </Box>

                        <hr className="m-2" />
                      </Box>

                      <Box>
                        <Box className="font-12">
                          <div className="mb-2 font-13">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: Message?.Message,
                              }}
                            ></div>
                          </div>
                          <Typography className="mb-2 font-13"></Typography>
                        </Box>
                      </Box>
                      {/* </Grid> */}

                      <Box sx={{ width: "100%" }}>
                        <Grid container rowSpacing={1}>
                          {/* Show Document list */}
                          {ad_MessageAttachmentList.length > 0 && (
                            <Grid item xs={12} lg={12} className="pt-0">
                              <Item>
                                <Box
                                  className="d-flex justify-content-between mb-0 align-items-center"
                                  sx
                                >
                                  <Typography
                                    variant="h3"
                                    className="font-18 text-black poppins-semibold mb-0"
                                  >
                                    Attachments
                                  </Typography>
                                  {ad_MessageAttachmentList.length > 1 && (
                                    <Button
                                      onClick={downloadAllDocs}
                                      variant="contained"
                                      className="btn-blue mb-2"
                                      size="small"
                                      startIcon={<DownloadIcon />}
                                    >
                                      Download All
                                    </Button>
                                  )}
                                </Box>

                                {/* message Document */}

                                <Box className="d-flex flex-wrap main-email-attachment w-100 mt-1">
                                  {ad_MessageAttachmentList.length > 0 &&
                                    ad_MessageAttachmentList.map((doc) => {
                                      return (
                                        <>
                                          {/* <Tooltip arrow> */}
                                          <Box
                                            className="email-attachment mb-2 "
                                            title={
                                              doc && doc.PortalName
                                                ? doc.PortalName
                                                : "Not Available"
                                            }
                                            onDoubleClick={() =>
                                              handlePreview(doc)
                                            }
                                          >
                                            <Box>
                                              <img
                                                src={GetDocTypeIcon2Requests(
                                                  doc.DocExtension
                                                )}
                                                style={{ width: 28 }}
                                                alt="documents"
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                  fontWeight: "550",
                                                }}
                                                className="d-flex align-items-center"
                                              >
                                                <span className="d-inline-block ms-2">
                                                  {doc && doc.PortalName
                                                    ? doc.PortalName
                                                    : "Not Available"}
                                                </span>
                                              </Typography>
                                            </Box>
                                            <Box className="download">
                                              <Tooltip title="Download" arrow>
                                                <Button
                                                  className="min-width-auto"
                                                  size="small"
                                                  onClick={(e) =>
                                                    downloadFile(e, doc)
                                                  }
                                                >
                                                  <DownloadIcon className="text-blue font-20" />
                                                </Button>
                                              </Tooltip>
                                            </Box>
                                          </Box>
                                          {/* </Tooltip> */}
                                        </>
                                      );
                                    })}
                                </Box>
                              </Item>
                            </Grid>
                          )}
                          {/* Show comments */}
                          <Grid item xs={12} className="pt-0">
                            <Item sx={{ marginRight: "20px" }}>
                              {(status === "For Info" ||
                                status === "Pending") && (
                                  <Comments
                                  portalDocID={portalDocID}
                                  portalMessageComments={dmCommentJson}
                                  ClientName={ClientName}
                                  customerId={customerId}
                                  />
                                // <Box
                                //   className=""
                                //   sx={{
                                //     background: "#f5f5f5",
                                //     padding: "15px",
                                //   }}
                                // >
                                //   <Box
                                //     sx={{ height: "225px" }}
                                //     className="mb-2"
                                //   >
                                //     <Typography
                                //       variant="h3"
                                //       className="font-18 text-black poppins-semibold mb-2"
                                //     >
                                //       Comment
                                //     </Typography>

                                //     <Box className="editor-height-200 mt-2">
                                //       <HtmlEditorDX
                                //         templateDataMarkup={editorText} // Correct binding to state
                                //         handleEditorChange={handleEditorChange}
                                //       />
                                //     </Box>

                                //     <Box className="d-flex align-items-center justify-content-end comment-button">
                                //       <Button
                                //         variant="contained"
                                //         className="btn-blue mt-2 "
                                //         startIcon={<CommentIcon />}
                                //         onClick={saveMessageComment}
                                //       >
                                //         comment
                                //       </Button>
                                //     </Box>
                                //   </Box>

                                //   <Box>
                                //     <Box
                                //       className="my-2"
                                //       sx={{
                                //         maxHeight: "calc(100vh - 350px)",
                                //         overflow: "auto",
                                //       }}
                                //     >
                                //       {renderCommnetMessage}
                                //     </Box>
                                //   </Box>
                                // </Box>
                              )}
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    {status === "Approved" && (
                      <Box className="white-box 02">
                        <Box className="d-flex justify-content-between align-items-center">
                          <Typography
                            className="font-18 text-black poppins-semibold"
                            variant="h3"
                          >
                            Certificate of approval
                          </Typography>
                        </Box>

                        <hr />

                        <Box>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              sm={6}
                              sx={{
                                height: "400px",
                                overflow: "auto",
                              }}
                              className="pe-2"
                            >
                              <Card sx={{ border: "1px solid black" }}>
                                {certificateUrl.split(",")[1] ===
                                "undefined" ? (
                                  <>
                                    <CustomLoader />
                                  </>
                                ) : (
                                  <iframe
                                    src={certificateUrl}
                                    width="100%"
                                    height="700px"
                                    frameBorder="0"
                                    allowFullScreen
                                    title="Embedded Content"
                                  ></iframe>
                                )}
                              </Card>
                            </Grid>
                            <Grid item sm={6}>
                              <Box>
                                <Box className="mb-2">
                                  <Typography
                                    variant="h2"
                                    className="font-18 poppins-semibold"
                                  >
                                    Comments
                                  </Typography>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: dmCommentHtml,
                                    }}
                                    className="single-user-comments"
                                  ></div>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </TabPanel>

                <TabPanel value="2" className="p-0">
                  <ClientRequestDetails />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
      </Box>

      {/* Modal */}
      <DocumentPreviewModal open={openDocumentPreviewModal} />
    </>
  );
}
