import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutoComplete from "../components/AutoComplete";
import {
  GetCalendlyCredentialsForAllUsers,
  ShowCalendly,
} from "../redux/ak_api_helper";
import {
  setCalendryMeetingModal,
  setOpenBookMeetingModal,
} from "../redux/modalSlice";
import { clientLoginStatus } from "../utils/RequestsFunction";
import GroupsIcon from "@mui/icons-material/Groups";
function BookMeeting({ open }) {
  const getAllUserFromBookingData = useSelector(
    (state) => state.ak.getAllUserFromBookingData.getAllUserFromBookingData
  );
  const [bookUserData, setBookUserData] = useState([])
  const dispatch = useDispatch();

  const [meetingLink, setMeetingLink] = useState(null);

  useEffect(() => {
    if (Array.isArray(getAllUserFromBookingData) && getAllUserFromBookingData.length > 0) {
      setBookUserData(getAllUserFromBookingData.filter(
        (user) => user.CalendlyUrl != null
      ))
    }
  }, [getAllUserFromBookingData]);


  useEffect(() => {
    if (clientLoginStatus) {
      let ObjDataForCredentialsForAllUsers = {
        accId: localStorage.getItem("accIdClient")
          ? localStorage.getItem("accIdClient")
          : "",
        username: localStorage.getItem("EmailClient")
          ? localStorage.getItem("EmailClient")
          : "",
        sPassword: localStorage.getItem("passwordClient")
          ? localStorage.getItem("passwordClient")
          : "",
      };

      dispatch(
        GetCalendlyCredentialsForAllUsers(ObjDataForCredentialsForAllUsers)
      );
    }
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setOpenBookMeetingModal(false));
  };

  const BookMeetingBuuton = () => {
    if (meetingLink != null) {
      setMeetingLink(null);
      dispatch(setCalendryMeetingModal(true));
      dispatch(setOpenBookMeetingModal(false));

      dispatch(ShowCalendly(meetingLink));
      dispatch(setCalendryMeetingModal(true));
      dispatch(setOpenBookMeetingModal(false));
    } else {
      toast.error("Select User Before Schedule Meeting");
    }
  };

  const getForwardUsernObject = (event, value) => {
    // console.log('velue for Atocomplete Data',value.CalendlyUrl || '');

    // setMeetingLink(value && value.CalendlyUrl)

    // console.log(event, value);
    // settxtFromData(value);

    // handleObjectDispatch("FromUser", value);

    if (value && value.CalendlyUrl) {
      setMeetingLink(value.CalendlyUrl);
    } else {
      setMeetingLink(null); // Handle case where value is null or doesn't have CalendlyUrl
      toast.error("This user haven't Calendy URL");
    }
    setMeetingLink(value && value.CalendlyUrl);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal-md"
      sx={{
        "& .MuiDialog-paper": {
          // width: "40%",
          maxWidth: "400px",
          height: "200px",
          overflow: "hidden",
        },
      }}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head p-0">
        <Box className="clearfix">
          <DialogTitle>{"Schedule Meeting"}</DialogTitle>
        </Box>

        <Button onClick={handleClose} autoFocus>
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent>
        <div>
          <Box className="custom-autocomplete">
            <AutoComplete
              lable="Select User"
              className="w-100"
              ComData={bookUserData}
              target="UserName"
              comFun={getForwardUsernObject}
            // defaultValue={
            //   Object.keys(mainObject).length !== 0 && mainObject.FromUser
            //     ? mainObject?.FromUser
            //     : ""
            // }
            ></AutoComplete>
          </Box>

          <div className=" d-flex justify-content-end mt-2">
            <Button
              id="basic-button"
              aria-haspopup="true"
              className="btn-blue "
              onClick={() => {
                BookMeetingBuuton();
              }}
              disabled={!meetingLink}
              startIcon={<GroupsIcon />}
            >
              Book Meetting
            </Button>
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions> 
        <Button onClick={handleClose}>Close</Button> 
        <Button onClick={handleClose} autoFocus> 
          Agree 
        </Button> 
      </DialogActions> */}
    </Dialog>
  );
}

export default BookMeeting;
