import { Box, Divider, IconButton, InputBase, Paper, TextField } from "@mui/material";

import React from "react";
import SearchIcon from "@mui/icons-material/Search";
function CustomeSerchBar({ sx, fullWidth, onClick, onChange, value }) {
  return (
    <Box>

      <Box className='search-input'
        sx={{
          width: 260,
          maxWidth: '100%',
        }}>
        <SearchIcon />
        <TextField fullWidth label="Search" size='small' value={value} onChange={onChange}/>
      </Box>

      {/* <Paper component="form" sx={sx} fullWidth>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder=""
          inputProps={{ "aria-label": "search google maps" }}
          fullWidth
          onChange={onChange}
        />

        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={onClick}
        >
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      </Paper> */}
    </Box>
  );
}

export default CustomeSerchBar;
