import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DraftsIcon from "@mui/icons-material/Drafts";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomeSerchBar from "../../components/CustomeSerchBar";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";
import { Json_Portal_GetClientListByEmail_Redux } from "../../redux/ak_api_helper";
import {
  getPortalUsersInboxNew_Json,
  markMessageAsViewed,
} from "../../redux/dev_chetan_api_helper";
import {
  setApprovalCertificateBase64,
  setPortalUserInboxMessage,
} from "../../redux/dev_chetan_slice";
import { convertToDateFormat } from "../../utils/RequestsFunction";
import CamposeMessage from "./CamposeMessage";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";

function createData(
  Subject,
  Section,
  ReceivedDate,
  ActionBy,
  Status,
  PortalDocID,
  EmailID,
  ForApproval,
  FileApproved,
  Expired,
  ViewedDate,
  SenderName,
  ExpiryDate
) {
  return {
    Subject,
    Section,
    ReceivedDate,
    ActionBy,
    Status,
    PortalDocID,
    EmailID,
    ForApproval,
    FileApproved,
    Expired,
    ViewedDate,
    SenderName,
    ExpiryDate,
    history: [
      {
        date: "Section",
        customerId: `: ${
          Section ? (Section.includes(".") ? Section.slice(4) : Section) : ``
        }`,
      },
      {
        date: "Received Data",
        customerId: `: ${ReceivedDate}`,
        // amount: 1,
      },
      {
        date: "Action By",
        customerId: `: ${ActionBy}`,
      },
    ],
  };
}

function Row(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const status = (() => {
    if (row?.Expired === "Yes") {
      return "Expired";
    }
    if (row?.ForApproval === "No") {
      return "For Info";
    }
    if (row?.FileApproved === null && row?.ForApproval === "Yes") {
      return "Pending";
    }
    if (row?.FileApproved === "Yes" && row?.ForApproval === "Yes") {
      return "Approved";
    }
    if (row?.FileApproved === "No" && row?.ForApproval === "Yes") {
      return "Disapproved";
    }
    return "Pending";
  })();

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onDoubleClick={() => {
          //console.log("dbl click",row);
          dispatch(markMessageAsViewed(row.PortalDocID));
          dispatch(setApprovalCertificateBase64("undefined"));

          const stateData = {
            PortalDocID: row.PortalDocID,
            Subject: row.Subject,
            Section: row.Section,
            ActionBy: row.ActionBy,
            Status: row.Status,
            ReceivedDate: row.ReceivedDate,
            ExpiryDate: row?.ExpiryDate,
            EmailID: row.EmailID,
            messageStatus: status,
            isClient: true, // Assuming 'isClient' is always true
            senderName: row.SenderName,
          };

          // Use navigate with the state object
          navigate("/client/portalmessage/inboxes/incoming-message-viewer", {
            state: stateData,
          });

          // navigate(
          //   `/client/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${
          //     row.PortalDocID
          //   }&Subject=${row.Subject}&Section=${row.Section}&ActionBy=${
          //     row.ActionBy
          //   }&Status=${row.Status}&ReceivedDate=${row.ReceivedDate}&ExpiryDate=${row?.ExpiryDate}&EmailID=${
          //     row.EmailID
          //   }&messageStatus=${status}&isClient=${"true"}&senderName=${
          //     row.SenderName
          //   }`
          // );
        }}
        className="pointer"
        hover
        aria-label="a dense table"
      >
        <TableCell style={{ width: "70px" }}>
          <Tooltip title={`${convertToDateFormat(row?.ViewedDate)}`}>
            {/* {convertToDateFormat(row?.ViewedDate)} */}
            <DraftsIcon className="font-16 text-blue me-1" />
            {/* <EmailOutlinedIcon className="font-16 text-blue me-1" /> */}
          </Tooltip>

          <Tooltip title="Requested">
            {row?.ForApproval === "Yes" ? (
              <MarkEmailReadIcon className="font-16 text-blue me-1" />
            ) : (
              <DoDisturbOutlinedIcon className="font-16 text-blue me-1" />
            )}
            {/* <DoDisturbOutlinedIcon className="font-16 text-blue me-1" /> */}
          </Tooltip>

          {/* <IconButton
            aria-label="expand row"
            size="small"
            align="right"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
        <TableCell
          data-th="Subject"
          align="left"
          scope="row"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row.Subject ? row.Subject : "Not Available"} 
        </TableCell>
        <TableCell
          align="center"
          data-th="Section"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row?.Section && row?.Section?.includes(".")
            ? row.Section.slice(3).trim()
            : row?.Section || "No Section"}
        </TableCell>
        <TableCell
          align="center"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
          data-th="ReceivedDate"
        >
          {row.ReceivedDate}
        </TableCell>
        <TableCell
          align="center"
          data-th="ActionBy"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row.ActionBy === "Invalid date" ? "Not Assigned" : row.ActionBy}
        </TableCell>
        <TableCell
          align="center"
          data-th="Status"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          <Box className="d-flex align-items-center justify-content-end ">
            {/* <Button className="btn-blue">View Detail</Button> */}
            <Chip
              label={status}
              size="small"
              style={{ borderRadius: "2px", fontSize: "12px", height: "20px", width: '100px' }}
              className={
                row.Expired === "Yes"
                  ? "chips bg-red"
                  : row.ForApproval === "No"
                  ? "chips bg-primary"
                  : (row.FileApproved === null || row.FileApproved === "No") &&
                    row.ForApproval === "Yes"
                  ? "chips bg-warning"
                  : row.FileApproved === "Yes" && row.ForApproval === "Yes"
                  ? "chips bg-accepted"
                  : row.FileApproved === "Yes" && row.ForApproval === "No"
                  ? "chips bg-red"
                  : ""
              }
            />
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PortalInbox() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(Json_Portal_GetClientListByEmail_Redux());
  }, []);
  const [searchInput, setSearchInput] = React.useState("");
  const [filterCriteria, setFilterCriteria] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const {
    portalUserInboxMessage,
    allPortalUserInboxMessage,
    isInboxMessageLoading,
  } = useSelector((state) => state.dev_chetan);
  // console.log('✌️portalUserInboxMessage --->', portalUserInboxMessage);

  let sectionList = [];
  allPortalUserInboxMessage.map((itm) => {
    if (!sectionList.includes(itm?.ItemName)) {
      sectionList.push(itm?.ItemName);
    }
  });
  const unreadMessages = allPortalUserInboxMessage.filter(
    (itm) => itm.ViewedDate === null
  );
  const [openComposeMessageBox, setOpenComposeMessageBox] =
    React.useState(false);

  React.useEffect(() => {
    LoadData();
  }, [dispatch]);

  const LoadData = async () => {
    setLoader(true);
    if (
      portalUserInboxMessage.length === 0 &&
      Boolean(localStorage.getItem("LoginClient")) === false
    ) {
      // dispatch(getPortalUserInboxMessages());
    } else {
      await dispatch(getPortalUsersInboxNew_Json());
    }
    setLoader(false);
  };

  React.useEffect(() => {
    const obj1 = {
      accId: localStorage.getItem("accIdClient")
        ? localStorage.getItem("accIdClient")
        : "",
      username: localStorage.getItem("EmailClient")
        ? localStorage.getItem("EmailClient")
        : "",
      sPassword: localStorage.getItem("passwordClient")
        ? localStorage.getItem("passwordClient")
        : "",
    };

    // dispatch(GetPortalContactDetailsByEmail(obj1));
  }, []);

  let allInboxMessages = [];
  if (portalUserInboxMessage.length > 0) {
    allInboxMessages = portalUserInboxMessage.map((itm) => {
      return createData(
        itm.Subject,
        itm.ItemName,
        moment(itm.ADate).format("DD/MM/YYYY"),
        moment(itm.ActionDate).format("DD/MM/YYYY"),
        itm.ForApproval,
        itm.PortalDocID,
        itm.EmailID,
        itm.ForApproval,
        itm.FileApproved,
        itm.Expired,
        itm.ViewedDate,
        itm.CName,
        moment(itm.ExpiryDate).format("DD/MM/YYYY")
      );
    });
  }

  const [currentPage, setCurrentPage] = React.useState(1);
  const handleChange = (e, value) => {
    setCurrentPage(value);
  };
  let itemsPerPage = 20;
  // Calculate the total number of pages
  const totalPages = Math.ceil(allInboxMessages.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allInboxMessages.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleInboxMessageSearch = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Only apply the filter if the input length is at least 3 characters
    setTimeout(() => {
      if (inputValue.length >= 3) {
        setFilterCriteria((prev) => ({
          ...prev,
          Subject: [inputValue],
        }));
      } else {
        // Reset filter if input length is less than 3
        setFilterCriteria((prev) => ({
          ...prev,
          Subject: [],
        }));
      }
    }, 1000);
  };

  const handleDateRangePicker = (date) => {
    if (
      date.startDate === "clear" &&
      Object.keys(filterCriteria).includes("ADate")
    ) {
      let temp = { ...filterCriteria };
      delete temp["ADate"];
      setFilterCriteria(temp);
      return;
    }
    setFilterCriteria({
      ...filterCriteria,
      ADate: [date.startDate, date.endDate],
    });
  };
  // console.log('test format date object', moment(portalUserInboxMessage[0].ADate)._d);

  React.useMemo(() => {
    if (Object.keys(filterCriteria).length > 0) {
      let fltData = allPortalUserInboxMessage.filter(function (obj) {
        return Object.keys(filterCriteria).every(function (key) {
          if (key === "ADate") {
            if (
              moment(obj.ADate)._d >= filterCriteria["ADate"][0] &&
              moment(obj.ADate)._d <= filterCriteria["ADate"][1]
            ) {
              return true;
            }
          } else {
            return (
              (Array.isArray(filterCriteria[key]) &&
                filterCriteria[key].some(function (criteria) {
                  return String(obj[key])
                    .toLowerCase()
                    .includes(String(criteria.toLowerCase()));
                })) ||
              filterCriteria[key].length === 0
            );
          }
        });
      });
      dispatch(setPortalUserInboxMessage(fltData));
    } else {
      if (allPortalUserInboxMessage.length > 0)
        dispatch(setPortalUserInboxMessage(allPortalUserInboxMessage));
    }
  }, [filterCriteria]);

  // console.log('allPortalUserInboxMessage', allPortalUserInboxMessage);
  const [section, setSection] = React.useState("Section");

  const handleSectionChange = (event) => {
    let value = event.target.value;
    if (value === "Clear Filters") {
      setSection("Section");
      let tempFilters = { ...filterCriteria };
      delete tempFilters.ItemName;
      setFilterCriteria({ ...tempFilters });
      return;
    }
    setFilterCriteria({ ...filterCriteria, ItemName: [value] });
    setSection(value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDescending = (target) => {
    switch (target) {
      case "Subject":
        const sortedData = [...portalUserInboxMessage].sort((a, b) =>
          b.Subject.localeCompare(a.Subject)
        );
        dispatch(setPortalUserInboxMessage(sortedData));
        return;
      case "Received Date":
        const sortByRecDate = [...portalUserInboxMessage].sort(
          (a, b) => moment(a.ADate).toDate() - moment(b.ADate).toDate()
        );
        dispatch(setPortalUserInboxMessage(sortByRecDate));
        return;
      default:
        return [];
    }
  };
  const handleAscending = (target) => {
    switch (target) {
      case "Subject":
        const sortedData = [...portalUserInboxMessage].sort((a, b) =>
          a.Subject.localeCompare(b.Subject)
        );
        dispatch(setPortalUserInboxMessage(sortedData));
        return;
      case "Received Date":
        const sortByRecDate = [...portalUserInboxMessage].sort(
          (a, b) => moment(b.ADate).toDate() - moment(a.ADate).toDate()
        );
        dispatch(setPortalUserInboxMessage(sortByRecDate));
        return;
      default:
        return [];
    }
  };
  const handleSorting = (e, target) => {
    let boolean = e.target.checked;
    if (boolean) {
      handleDescending(target);
    } else {
      handleAscending(target);
    }
  };

  return loader ? (
    <CustomLoader />
  ) : (
    <Box className="mt-2">
      <Box className="d-flex align-items-center justify-content-between"></Box>

      <Box className="d-flex white-box   justify-content-end flex-wrap">
        <Box className="d-flex align-items-center">
          <CustomeSerchBar
            className="mb-2 "
            value={searchInput}
            onChange={handleInboxMessageSearch}
          />
        </Box>
        <Box className="d-flex align-items-center flex-wrap ms-3 justify-content-between">
          <Box className="me-2 ">
            <FormatListBulletedIcon
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="pointer"
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="custom-dropdown"
            >
              <Box className="px-3">
                <Typography component={"h3"} className="m-1">
                  Filter by:
                </Typography>
              </Box>
              <Stack direction="row" spacing={2} className="mx-3">
                <Box className="font-12">
                  <FormControl
                    sx={{ minWidth: 220 }}
                    size="small"
                    className="font-12"
                  >
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={section}
                      onChange={handleSectionChange}
                      size="small"
                    >
                      <MenuItem value="Section" className="d-none">
                        Section
                      </MenuItem>
                      <MenuItem
                        value="Clear Filters"
                        className="font-12 text-danger"
                      >
                        <ClearOutlinedIcon /> Clear Filters
                      </MenuItem>
                      {sectionList.length > 0 &&
                        sectionList.map((itm, i) => {
                          return (
                            <MenuItem key={i} value={itm} className="font-12">
                              {itm.slice(3)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
                {/* <Box className="d-flex align-items-center jus">
                  <FormControl sx={{ width: 220 }} size="small">
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={section}
                      onChange={handleSectionChange}
                      size="small"
                    >
                      <MenuItem value="Section" className-="d-none font-12">
                        Section
                      </MenuItem>
                      <MenuItem value="Clear Filters" className="font-12 text-danger"><ClearOutlinedIcon /> Clear Filters</MenuItem>
                      {sectionList.length > 0 &&
                        sectionList.map((itm, i) => {
                          return (
                            <MenuItem key={i} value={itm} className="font-12">
                              {itm.slice(3)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box> */}
              </Stack>
            </Menu>
          </Box>
          <Box className=" d-flex">
            <CustomDateRangePicker
              strText={"Filter By Date"}
              tooltipTlt={"Recieved Date"}
              onChange={() => {}}
              setDateObj={handleDateRangePicker}
            />
            {/* <IconButton>
              <RefreshIcon />
            </IconButton> */}
          </Box>
        </Box>
      </Box>

      {/* <hr /> */}

      {isInboxMessageLoading ? (
        <CustomLoader />
      ) : allInboxMessages.length > 0 ? (
        <>
          <TableContainer
            className="mt-2 table-bg"
            component={Paper}
            sx={{ boxShadow: "none" }}
          >
            <Table
              // aria-label="collapsible table"
              size="small"
              className="custom-table table table-mobile"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className="poppins-semibold py-0">
                    Subject
                    <Checkbox
                      {...label}
                      icon={<ArrowUpwardIcon />}
                      checkedIcon={<ArrowDownwardIcon />}
                      onChange={(e) => handleSorting(e, "Subject")}
                    />
                  </TableCell>
                  <TableCell
                    className="poppins-semibold py-0" 
                    onClick={() => handleDescending("Section")}
                  >
                    Section
                  </TableCell>
                  <TableCell className="poppins-semibold py-0" align="center">
                    Received Date
                    <Checkbox
                      {...label}
                      icon={<ArrowUpwardIcon />}
                      checkedIcon={<ArrowDownwardIcon />}
                      onChange={(e) => handleSorting(e, "Received Date")}
                    />
                  </TableCell>
                  <TableCell className="poppins-semibold py-0" align="center">
                    {" "}
                    Action By
                  </TableCell>

                  <TableCell className="poppins-semibold py-0" align="right">
                    <Box className="pe-4">Status</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.length > 0 ? (
                  currentItems.map((row, i) => <Row row={row} key={i} />)
                ) : (
                  <NoData message={"No Match Found"} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <NoData message="No Message here" />
      )}

      <Box className="mt-2">
        {/* <PaginationComponent /> */}
        {Number(totalPages) !== (1 || 0) && (
          <Pagination
            count={totalPages}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        )}
      </Box>

      <Box className="compose-messages">
        {openComposeMessageBox && (
          <CamposeMessage setOpenComposeMessageBox={setOpenComposeMessageBox} />
        )}
      </Box>
    </Box>
  );
}
