import * as React from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Grid, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadShareboxDocument,
  getShareBoxDocuments,
  getShareBoxMessageId,
  removeShareboxDocument,
  saveShareBoxDocument,
} from "../../redux/dev_chetan_api_helper";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import DataNotFound from "../../components/DataNotFound";
import CustomLoader from "../../components/CustomLoader";
import DocumentCard from "../../cmp-utils/DocumentCard";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ShareBox() {
  // console.log('location', window.location.pathname.split("/")[1]);
  const portalObj = useLocalStorage("accid");
  const [selectButtonText, setSelectButtonText] = React.useState("Select All");
  const dispatch = useDispatch();
  const { shareboxDocuments, isShareDocLoading, shareBoxMessageId } =
    useSelector((state) => state.dev_chetan);
  // console.log('sharebox docs', shareboxDocuments);

  React.useEffect(() => {
    if (shareboxDocuments.length === 0) dispatch(getShareBoxDocuments());
    if (shareBoxMessageId === "") dispatch(getShareBoxMessageId());
  }, [dispatch]);

  const uploadShareBoxDocument = (e) => {
    const allFiles = e.target.files;
    dispatch(saveShareBoxDocument(allFiles, shareBoxMessageId));
  };

  const [checkboxData, setCheckboxData] = React.useState({});

  const removeCheckedDoc = (e) => {       // Don't remove this code - dev-chetan 
    e.stopPropagation();
    for (const key in checkboxData) {
      dispatch(removeShareboxDocument(checkboxData[key]));
    }
    dispatch(getShareBoxDocuments());
    setCheckboxData({});
    setSelectButtonText("Select All");
  };

  const downloadCheckedDoc = (e) => {     // Don't remove this code - dev-chetan 
    e.stopPropagation();
    for (const key in checkboxData) {
      dispatch(downloadShareboxDocument(checkboxData[key].PortalDocID, checkboxData[key].AttachID, checkboxData[key].DocExtension, checkboxData[key]["Document Name"]));
    }
    setCheckboxData({});
    setSelectButtonText("Select All");
  };

  const handleSelectAll = (e) => {       // Don't remove this code - dev-chetan 
    e.stopPropagation();
    if (selectButtonText === "Select All") {
      let newCheckList = {};
      shareboxDocuments.forEach((doc, i) => {
        newCheckList[i] = doc;
      });
      setCheckboxData(newCheckList);
      setSelectButtonText("Unselect All");
    } else if (selectButtonText === "Unselect All") {
      setCheckboxData({});
      setSelectButtonText("Select All");
    }
  };
  const { pathname } = window.location;
  const getPageTitle = () => {
    switch (true) {
      case pathname.includes("templates"):
        return "Template List";
      case pathname.includes("portalmessage/sent"):
        return "Portal Sent Messages ";
      case pathname.includes("share-box"):
        return "Share Box";
      case pathname.includes("contacts/contact-details"):
        return "Contact Detail";
      case pathname.includes("contact/contacts"):
        return "Contact List";
      case pathname.includes("contact/companies"):
        return "Company List";
      case pathname.includes("user/client-details"):
        return "Company Details";
      case pathname.includes("incoming-message-viewer"):
        return "Incoming Message";
      case pathname.includes("all-requests"):
        return "All Request";
      default:
        return "Portal Messages";
    }
  };

  return (
    <>
      <Box className="clearfix">
        <Box className="d-flex align-items-center justify-content-between justify-content-sm-end py-2">
          <h2 className="title mb-0 nowrap d-sm-none d-block font-16">{getPageTitle()}</h2>
          <Box>
            {/* {Object.keys(checkboxData).length > 0 && (
              <>
                <Button
                  className="mb-3 me-3 btn-blue"
                  variant="contained"
                  onClick={(e) => handleSelectAll(e)}
                >
                  {selectButtonText}
                </Button>
                <Button
                  className="mb-3 me-3 btn-blue"
                  variant="contained"
                  onClick={removeCheckedDoc}
                >
                  {Object.keys(checkboxData).length + " "}Remove
                </Button>
                <Button
                  className="mb-3 me-3 btn-blue"
                  variant="contained"
                  onClick={downloadCheckedDoc}
                >
                  {Object.keys(checkboxData).length + " "}Download
                </Button>
              </>
            )} */}    {/* Don't remove this code - dev-chetan */}
            {window.location.pathname.split("/")[1]!=="client" && <Button
              component="label"
              role={undefined}
              variant="contained"
              disabled={ Object.keys(checkboxData).length>0 ? true : false }
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className="mb-0 btn-blue"
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                onChange={uploadShareBoxDocument}
                multiple
              />
            </Button>}
          </Box>
        </Box>

        <Box className="">
          <Box>
            {isShareDocLoading ? (
              <CustomLoader />
            ) : shareboxDocuments.length > 0 ? (
              <Grid container spacing={1}>
                {shareboxDocuments.map((itm, i) => {
                  return (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <DocumentCard
                          itm={itm}
                          i={i}
                          checkboxData={checkboxData}
                          setCheckboxData={setCheckboxData}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            ) : (
              <DataNotFound />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ShareBox;
