import axios from "axios";
import { toast } from "react-toastify";
import {
  clientBodyaccidData,
  clientBodyaccIdData,
  userBodyDAtaa,
} from "../utils/RequestsFunction";
import {
  Ad_DirectMessageAttachmentList_Reducer,
  DirectMessageAllReducer,
  GetAllClientList_reducer,
  GetAllContacts_reducer,
  GetAllRequestComments,
  SignDocSaveReducer,
} from "./adityaReducer";
import { setConfigs_Redux, setJsonConfig_Redux, UDFClintData } from "./akSlice";
import {
  docuSMSApi,
  OutlookMydocusoft,
  portalClient,
  portalUser,
  PracticeServices,
} from "./AxiosInstance";

const clientBody = {
  accId: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient"),
};

const userBody = {
  accid: localStorage.getItem("accid"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

const userBody_2 = {
  accId: localStorage.getItem("accid"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

const ArgnoclientBody = {
  agrno: localStorage.getItem("accid"),
  Email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

const ArgnoPracticeBody = {
  agrno: localStorage.getItem("accIdClient"),
  Email: localStorage.getItem("EmailClient"),
  password: localStorage.getItem("passwordClient"),
};

const LoginClient = localStorage.getItem("LoginClient");

export const ApproveMessageNew_Json = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("ApproveMessageNew_Json", obj);
    if (res) {
      let js = JSON.parse(JSON.stringify(res.data.d));
      callBack(JSON.parse(js));
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, ApproveMessageNew_Json",
      error
    );
  }
};

export const ad_client_DisapproveMessageNew_Json =
  (obj, callBack) => async (dispatch) => {
    const ipResponse = await axios.get("https://api.ipify.org/?format=json");
    try {
      let res = await portalUser.post("DisapproveMessageNew_Json", {
        ...clientBodyaccIdData,
        sIPAddress: ipResponse.data?.ip ? ipResponse.data?.ip : "",
        ...obj,
      });
      if (res) {
        let js = JSON.parse(JSON.stringify(res.data.d));
        toast.success("Message disapproved");
        callBack(JSON.parse(js));
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DisapproveMessageNew_Json",
        error
      );
    }
  };

export const ad_client_ApproveMessageNew_Json =
  (obj, callBack) => async (dispatch) => {
    const ipResponse = await axios.get("https://api.ipify.org/?format=json");
    try {
      let res = await portalUser.post("ApproveMessageNew_Json", {
        ...clientBodyaccIdData,
        sIPAddress: ipResponse.data?.ip ? ipResponse.data?.ip : "",
        ...obj,
      });
      if (res) {
        let js = JSON.parse(JSON.stringify(res?.data?.d));
        if (js === "") {
          callBack(true);
        } else {
          callBack(false);
        }
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, DisapproveMessageNew_Json",
        error
      );
    }
  };

export const UpdateRequestedDocStatusAction_Json_ReduxClient =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post(
        "UpdateRequestedDocStatusAction_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        console.log("UpdateRequestedDocStatusAction_Json", str.d);
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const RenderRequestAll_Json_Action =
  (obj, callBack) => async (dispatch) => {
    let body = {
      accId: "0003",
      email: "patrick@docusoft.net",
      password: "UGF0cmljazEyMy4=",
      username: "patrick.docusoft@outlook.com",
      sPassword: "0",
      filterBy: "All",
    };
    try {
      let res = await portalUser.post("PortalUsersRequestedDocs2_Json", body);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const GetRequestDocumentAction = (obj, callBack) => async (dispatch) => {
  if (LoginClient) {
    let body = {
      ...clientBody,
      ...obj,
    };
    try {
      let res = await portalUser.post(
        "GetPortalRequestDocumentsNew2_Json",
        body
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  } else {
    let body = {
      ...userBody,
      ...obj,
    };

    try {
      let res = await portalClient.post(
        "GetPortalRequestDocuments2_Json",
        body
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  }
};

export const SendReminderUserAction = (obj, callBack) => async (dispatch) => {
  let body = {
    ...userBody,
    ...obj,
  };

  try {
    let res = await portalClient.post("SendReminder", body);
    if (res) {
      let str = JSON.parse(JSON.stringify(res.data));
      callBack(str.d);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const GetCRMContactUDFValueAction =
  (obj, callBack) => async (dispatch) => {
    let body = {
      ...userBody,
      ...obj,
    };

    try {
      let res = await portalUser.post("GetCRMContactUDFValues2_Json", obj);

      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        const parseData = JSON.parse(str?.d)?.Table;
        dispatch(UDFClintData(parseData));
        callBack(parseData);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const UpdateUdfValuesAction = (obj, callBack) => async (dispatch) => {
  const { value, data, getOrigantryNumber } = obj;

  let body = {
    ...clientBodyaccIdData,
    projectid: data?.ProjectId,
    ClientId: getOrigantryNumber,
    ContactNo: 0,
    fieldName: data?.Name,
    fieldValue: value,
  };

  try {
    let res = await portalUser.post("UpdateContactField_Json", body);
    if (res) {
      callBack(res.data);
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const ad_Json_GetAllContacts_Action = (callBack) => async (dispatch) => {
  try {
    let res = await portalClient.post("GetAllContacts_Json", userBody);
    if (res) {
      const newRes = JSON.parse(res.data.d);

      if (newRes) {
        dispatch(GetAllContacts_reducer(newRes));
        callBack(newRes);
      }
    }
  } catch (error) {
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const ad_Json_GetAllClientList_Action =
  (callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetAllClientList_Json", userBody);
      const newRes = JSON.parse(res.data.d).Table;
      if (newRes) {
        dispatch(GetAllClientList_reducer(newRes));
        callBack(newRes);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_Json_UpdateContact_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("UpdateContact_Json", {
        ...userBody,
        ...obj,
      });

      if (res.data.d) {
        callBack(res.data.d);
        return res.data.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_UpdateContactField_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("UpdateContactField_Json", {
        ...userBody,
        ...obj,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
        return res?.data?.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_MessageRetracted_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("MessageRetracted", {
        ...userBody,
        ...obj,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
        return res?.data?.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_MessageUnRetracted_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("MessageUnRetracted", {
        ...userBody,
        ...obj,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
        return res?.data?.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_MessageDeleted_Action = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalClient.post("MessageDeleted", {
      ...userBody,
      ...obj,
    });
    if (res?.data?.d) {
      callBack(res?.data?.d);
      toast.success("Message deleted");
      return res?.data?.d;
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    toast.error("Something went wrong");
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const ad_Json_GetConfiguration_Action =
  (callBack) => async (dispatch) => {
    try {
      let res = await docuSMSApi.post("Json_GetConfiguration", {
        ...ArgnoclientBody,
      });

      if (res?.data?.d) {
        let allConfigs = JSON.parse(res?.data?.d);
        callBack(allConfigs);
        dispatch(
          setJsonConfig_Redux({
            bussiness: allConfigs.Table1,
            sources: allConfigs.Table2,
            mangers: allConfigs.Table3,
            companyStatus: allConfigs.Table,
          })
        );
        dispatch(
          setConfigs_Redux({
            roles: allConfigs?.Table4,
            managers: allConfigs?.Table3,
          })
        );
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const getMessagerequest = (obj, callBack) => async (dispatch) => {
  if (LoginClient) {
    try {
      let res = await portalUser.post("GetAllComments_Json", {
        ...clientBodyaccidData,
        ...obj,
      });
      if (res?.data?.d) {
        if (res?.data?.d === "False") return;
        callBack(res?.data?.d);
        dispatch(GetAllRequestComments(JSON.parse(res?.data?.d)));
        return res?.data?.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  } else {
    try {
      let res = await portalClient.post("GetAllComments_Json", {
        ...userBody,
        ...obj,
      });
      if (res?.data) {
        callBack(res?.data?.d);
        dispatch(GetAllRequestComments(JSON.parse(res?.data?.d)));
        return res?.data?.d;
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  }
};

export const addCommentClientrequest = (obj, callBack) => async (dispatch) => {
  try {
    let res = await portalUser.post("CommentAddedNew_Json", {
      ...clientBodyaccidData,
      ...obj,
    });
    if (res?.data) {
      callBack(res?.data);
      toast.success("Comment added successfully!!");
      // return res?.data?.d;
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    toast.error("Something went wrong");
    console.log(
      "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
      error
    );
  }
};

export const requestDocumentStatusAction =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("GetDocumentStatus_Json", {
        ...userBody,
        ...obj,
        messageEmailAddress: "",
      });
      if (res?.data?.d) {
        callBack(JSON.parse(res?.data?.d));
        return JSON.parse(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const ad_Json_GetClientCardDetails_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_GetClientCardDetails", {
        ...ArgnoclientBody,
        ...body,
      });
      if (res?.data?.d) {
        callBack(JSON.parse(res?.data?.d));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_GetClientAssignedUnassignedFolderList_Action =
  (obj, callBack) => async (dispatch) => {
    let body = {
      agrno: ArgnoclientBody.agrno,
      UserEmail: ArgnoclientBody.Email,
      password: ArgnoclientBody.password,
      ...obj,
      intuserId: localStorage.getItem("UserId"),
    };
    try {
      let res = await PracticeServices.post(
        "Json_GetClientAssignedUnassignedFolderList",
        body
      );
      if (res?.data?.d) {
        callBack(JSON.parse(res?.data?.d));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_UpdateClient_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_UpdateClient", {
        ...ArgnoclientBody,
        ...body,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_AddFolders_Toclient_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_AssignProjectsToClient", {
        ...ArgnoclientBody,
        ...body,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_ClientAddress_Update_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_SetClientAddress", {
        ...ArgnoclientBody,
        ...body,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_CRMSaveUDFValues_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_CRMSaveUDFValues", {
        ...ArgnoclientBody,
        ...body,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const GetSignatureDocumentAction =
  (obj, callBack) => async (dispatch) => {
    if (LoginClient) {
      let body = {
        ...clientBody,
        ...obj,
      };
      try {
        let res = await portalUser.post(
          "GetPortalRequestDocumentsNew2_Json",
          body
        );
        if (res) {
          let str = JSON.parse(JSON.stringify(res.data.d));
          dispatch(SignDocSaveReducer(str));
          callBack(str.d);
        }
      } catch (error) {
        console.log(
          "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
          error
        );
      }
    } else {
      let body = {
        ...userBody,
        ...obj,
      };

      try {
        let res = await portalClient.post(
          "GetPortalRequestDocuments2_Json",
          body
        );
        if (res) {
          let str = JSON.parse(JSON.stringify(res.data));
          callBack(str.d);
        }
      } catch (error) {
        console.log(
          "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
          error
        );
      }
    }
  };

export const ad_SetPubUserSign_Json_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("SetPubUserSign_Json", {
        ...clientBodyaccidData,
        ...body,
      });
      if (res?.data) {
        callBack(res?.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_deleteSupplierContact_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("DeleteSupplierContact_Json", {
        ...userBodyDAtaa,
        ...body,
      });
      if (res?.data) {
        callBack(res?.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_DeleteClient_Json_DeleteSuppliers_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("DeleteSuppliers_Json", {
        ...userBodyDAtaa,
        ...body,
      });
      if (res?.data) {
        callBack(res?.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_GetCRMContactUDFValues_Action =
  (body, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_GetCRMContactUDFValues", {
        ...ArgnoclientBody,
        ...body,
      });
      if (res?.data?.d) {
        callBack(JSON.parse(res?.data?.d));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_GetWebTemplates_Action =
  (callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_GetWebTemplates", {
        ...ArgnoclientBody,
      });
      if (res?.data?.d) {
        callBack(JSON.parse(res?.data?.d));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_GetStandardLetterData_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await OutlookMydocusoft.post("Json_GetStandardLetterData", {
        ...ArgnoclientBody,
        ...obj,
        UserEmail: ArgnoclientBody?.Email,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response.data?.Message)
        toast.error(error?.response.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_GetHtmlFromRtf_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await OutlookMydocusoft.post("Json_GetHtmlFromRtf", {
        ...ArgnoclientBody,
        ...obj,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response?.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_UpdatePortalDocTemplateName_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("UpdatePortalDocTemplateName_Json", {
        ...userBody_2,
        ...obj,
      });
      if (res?.data?.d) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response?.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_GetOnboardingDetailsByMultipleClientIdPubUser_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post(
        "Json_GetOnboardingDetailsByMultipleClientIdPubUser",
        {
          ...ArgnoPracticeBody,
          ...obj,
        }
      );
      if (res?.data) {
// console.log('✌️res?.data --->', res?.data);
        callBack(JSON.parse(res?.data?.d)[0]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response?.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const GetOnboardingDetailsByMultipleClientIdPubUser =
    (obj, callBack) => async (dispatch) => {
      try {
        let res = await PracticeServices.post("Json_GetOnboardingDetailsByMultipleClientIdPubUser", obj);

        res = res.data.d
        
        console.log('GetOnboardingDetailsByMultipleClientIdPubUser' , JSON.parse(res))

        callBack(JSON.parse(res))

      } catch (error) {
        console.log(
          "Network error: No response received from server, Json_GetOnboardingDetailsByMultipleClientIdPubUser",
          error
        );
      }
    };

export const ad_Json_GetPortalMessageDetailsByTaskIdPubUser_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post(
        "Json_GetPortalMessageDetailsByTaskIdPubUser",
        {
          ...ArgnoPracticeBody,
          ...obj,
        }
      );
      if (res?.data) {
        // console.log("✌️res?.data?.d --->", res?.data);
        callBack(JSON.parse(res?.data?.d)?.Table[0]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response?.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_Json_ToGetEngagementTaskID_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await PracticeServices.post("Json_ToGetEngagementTaskID", {
        ...ArgnoPracticeBody,
        ...obj,
      });
      if (res?.data) {
        callBack(res?.data?.d);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response?.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

export const ad_SendReminderByMsgId_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalClient.post("SendReminderByMsgId_Json", {
        ...userBody,
        ...obj,
      });
      if (res?.data) {
        callBack(res?.data);
        toast.success("Sent message reminder");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.data?.Message)
        toast.error(error?.response?.data?.Message);
      else toast.error("Something went wrong");

      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error?.response?.data
      );
    }
  };

// d-message-viewer API

export const Dmessage_GetAllDetailsByMessageId_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("GetAllDetailsByMessageId_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        const parseData = JSON.parse(res.data?.d);
        const manageData = {
          Message: { ...parseData.Message[0] },
          PortalUsersInbox: { ...parseData.PortalUsersInbox[0] },
        };
        dispatch(DirectMessageAllReducer(manageData));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const Dmessage_MarkMessageAsViewedByToken_Json_Action =
  (obj, callBack) => async (dispatch) => {
    const ipResponse = await axios.get("https://api.ipify.org/?format=json");
    try {
      let res = await portalUser.post("MarkMessageAsViewedByToken_Json", {
        ...obj,
        ipAddress: ipResponse.data?.ip ? ipResponse.data?.ip : "",
      });
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const Dmessage_GetApprovalsAndCommentsHtmlByToken_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post(
        "GetApprovalsAndCommentsHtmlByToken_Json",
        obj
      );
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        callBack(str.d);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const Dmessage_GetAllCommentsByToken_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("GetAllCommentsByToken_Json", obj);
      if (res) {
        // console.log("✌️res.data --->", res.data);

        let str = JSON.parse(JSON.stringify(res.data));
        dispatch(GetAllRequestComments(JSON.parse(str?.d).Table));
        callBack(JSON.parse(str?.d).Table);
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

export const Dmessage_GetMessageDocumentsByToken_Json_Action =
  (obj, callBack) => async (dispatch) => {
    try {
      let res = await portalUser.post("GetMessageDocumentsByToken_Json", obj);
      if (res) {
        let str = JSON.parse(JSON.stringify(res.data));
        let str2 = JSON.parse(str?.d)?.MessageDocuments
        callBack(str2);
        dispatch(Ad_DirectMessageAttachmentList_Reducer(str2))
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

// d-message-viewer API
