import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Pagination,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import { OpenPendingMessageModalReducer } from "../redux/adityaReducer";
import { GetAllSentMessages_Json_Redux } from "../redux/api_helper";
import PendingMessageComponanat from "../user/pages/portal-messages/PendingMessageComponanat";
import CustomLoader from "../components/CustomLoader";

const TableHeder = [
  "",
  "Subject",
  "Recepient",
  "Section",
  "Issued On",
  "Action",
];

const PendingMesssageModal = () => {
  const dispatch = useDispatch();
  const { OpenPendingMessageModal } = useSelector(({ aditya }) => aditya);
  const closeModal = () => dispatch(OpenPendingMessageModalReducer(false));

  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const [originalData, setOriginalData] = useState([]); // Holds unfiltered data
  const [filteredData, setFilteredData] = useState([]); // Holds filtered data
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [selectedItem, setSelectedItem] = useState([]);
  const itemsPerPage = 10; // Number of items per page
  const objReq = useLocalStorage("accid");
  const sentObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    ccode: "",
    emailAddress: "",
  };

  useEffect(() => {
    getAllSentMessages.length < 1 &&
      dispatch(GetAllSentMessages_Json_Redux(sentObj));
  }, [dispatch]);

  const jsonObjectArray = useMemo(() => {
    return getAllSentMessages.map((item) => {
      const approvedDate = item["Date Approved"]
        ? moment(item["Date Approved"]).format("DD/MM/yyyy")
        : "Not available";
      const viewedDate = item.Viewed
        ? moment(item.Viewed).format("DD/MM/yyyy")
        : "Not available";

      let viewStatus = "Pending";
      if (!item.Viewed) viewStatus = "Unread";
      else if (item.Viewed && item.Approved === "Yes") viewStatus = "Approved";
      else if (item.Viewed && item.Approved === "No")
        viewStatus = "Disapproved";

      return {
        Subject: item.Subject || "Not available",
        Recipient: item["Client Name"] || "Not available",
        ApprovalRequested: item["To be Approved"] || "Not available",
        IssuedOn: item.Issued
          ? moment(item.Issued).format("DD/MM/yyyy")
          : "Not available",
        DueDate: item["Due Date"]
          ? moment(item["Due Date"]).format("DD/MM/yyyy")
          : "Not available",
        Status: viewStatus,
        MessageNo: item["Message No"] || "Not available",
        MessageID: item["Message ID"] || "Not available",
        ReferenceName: item["Client Name"] || "Not available",
        Section: item.Section,
        History: [
          { date: "Section", customerId: item.Section || "Not available" },
          { date: "Date Approved", customerId: approvedDate },
          { date: "Viewed On", customerId: viewedDate },
          {
            date: "Email ID",
            customerId: item.EMailId || "Not available",
          },
          {
            date: "Sent By",
            customerId: item["Sent/Received By"] || "Not available",
          },
        ],
      };
    });
  }, [getAllSentMessages]);

  useEffect(() => {
    const pendingMessages = jsonObjectArray.filter(
      (item) => item?.Status === "Pending"
    );
    setOriginalData(pendingMessages);
    setFilteredData(pendingMessages);
  }, [jsonObjectArray]);

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filteredData]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Dialog
      open={OpenPendingMessageModal}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal w-100"
      maxWidth={"xl"}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="clearfix">
          <DialogTitle>{"Pending Messages"}</DialogTitle>
        </Box>
        <Button onClick={closeModal} autoFocus>
          <CloseIcon />
        </Button>
      </Box>
      <DialogContent className=" white-box ">
        {paginatedData.length > 0 ? (
          <Box>
            <PendingMessageComponanat
              TableHeder={TableHeder}
              TableBodyData={paginatedData}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              originalData={originalData}
            />

            <Box className="mt-2">
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                className="custom-pagination"
              />
            </Box>
          </Box>
        ) : (
          <CustomLoader />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PendingMesssageModal;
