import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CreateIcon from "@mui/icons-material/Create";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import PersonIcon from "@mui/icons-material/Person";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import LinkDropDown from "../../../components/LinkDropDown";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import {
  EditCompanyOpenMdalReducer,
  EditContactOpenMdalReducer,
} from "../../../redux/adityaReducer";
import {
  Json_GetAllContactsByClientID_Redux,
  Json_GetClientAddresses_Redux,
  Json_GetClientCardDetails_Redux,
  Json_GetConfiguration,
  Json_Portal_GetClientListByEmail_Redux,
} from "../../../redux/ak_api_helper";
import {
  getClientIdMethod_Redux,
  getClientIdName_Redux,
  getContactEmailID_Redux,
  getFolderObject_Redux,
} from "../../../redux/akSlice";
import {
  GetAllFolders_Json_Redux,
  GetAllReceivedMessages_Json_Redux,
  GetAllReceivedMessagesByFolder_Json_Redux,
  GetAllSentMessagesByFolder_Json_Redux,
  GetClientsByFolder_Json_Redux,
  Json_GetAccountUsersContactsByClient_Redux,
} from "../../../redux/api_helper";
import {
  getAllContactsByClientID,
  getContactsListByFolder,
} from "../../../redux/dev_chetan_api_helper";
import { useGetUserSentMessage } from "../../../utils/RequestsFunction";
import EditContactModal from "../../../modals/EditContactModal";
import { handleOpenContactModal } from "../../../redux/docuSlice";
import { setOpenCompanyModal } from "../../../redux/modalSlice";

function PortalMessagesFilters({ filterType }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Json_Portal_GetClientListByEmail_Redux());
    refreshData();
  }, []);

  const [getFolder, setGetFolders] = React.useState([]);
  const [getClientsByFolder, setClientsByFolder] = React.useState([]);
  const [getCustomersByClient, setCustomersByClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [folderSet, setfolderId] = React.useState(0);
  const [clientSet, setClientCode] = React.useState("");
  const [params, setsearchParams] = useSearchParams();
  const argno = params.get("argno");
  const folderId = params.get("folderId");
  //Set label
  const [folerLabelSet, setFolderLabel] = React.useState("All");
  const [clientLabelSet, setClientLabel] = React.useState("All");
  const [contactLabelSet, setContactLabel] = React.useState("All");
  useEffect(() => {
    if (folderId && getFolder.length > 0) {
      let obj = getFolder.find(
        (data) => Number(data?.FolderID) === Number(folderId)
      );
      getFolderObject(folderId, obj);
    }
  }, [folderId, getFolder]);

  useEffect(() => {
    if (argno && getClientsByFolder.length > 0) {
      let obj = getClientsByFolder.find((data) => data?.ClientID == argno);
      if (obj) {
        getClientIdMethod(argno, obj);
      }
    }
  }, [argno, getClientsByFolder]);

  let objReq = useLocalStorage("accid");
  const [FolderObject, setFolderObject] = useState();
  const [ClientIdMethod, setClientIdMethod] = useState();
  const fetchSentUserMessages = useGetUserSentMessage();
  const [editContactModal, setEditContactModal] = useState(false);
  // dropdown
  const [EditanchorEl, EditsetAnchorEl] = React.useState(null);
  const Editopen = Boolean(EditanchorEl);
  const EdithandleClick = (event) => {
    EditsetAnchorEl(event.currentTarget);
  };
  const EdithandleClose = () => {
    EditsetAnchorEl(null);
  };

  const hanleModalOpne = () => {
    // dispatch(setOpenCompanyModal(true));
    dispatch(EditCompanyOpenMdalReducer(true));
  };
  const hanleModalOpneContact = () => {
    // dispatch(handleOpenContactModal(true));
    dispatch(EditContactOpenMdalReducer());
    setEditContactModal(true);
  };
  // dropdown
  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const Addopen = Boolean(AddanchorEl);
  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };
  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };
  const createClient = () => {
    AddsetAnchorEl(null);
    dispatch(setOpenCompanyModal(true));
  };
  //Added all filters data by 1 jun 2023
  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
      })
    );
    dispatch(Json_GetConfiguration());
  }, [dispatch]);

  //Get all client by folders seleected
  let filterObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    emailAddress: objReq.EmailId,
  };
  const [isVisible, setIsVisible] = useState(false);
  const getFolderObject = (event, value) => {
    setIsVisible(true);
    setLoading(true);
    if (value && value.FolderID) {
      dispatch(getFolderObject_Redux(`${value.FolderID}`));
      setFolderObject(value.FolderID);
      setFolderLabel(value.Folder); //Set folder label
      setClientLabel("Select");
      setContactLabel("Select");
      const newObj = { ...objReq, folder: parseInt(value.FolderID) };
      dispatch(
        GetClientsByFolder_Json_Redux(newObj, function (res) {
          setClientsByFolder(res);
        })
      );

      //Filter data sections
      setfolderId(parseInt(value.FolderID));
      let filterFolderObj = {
        ...filterObj,
        folder: parseInt(value.FolderID),
        ccode: "",
      };
    } else {
      setClientsByFolder([]);
    }
  };

  //Get custome r details clients and folders
  const getClientIdMethod = async (event, value) => {
    EditsetAnchorEl(null);
    const updatedParams = new URLSearchParams(params);
    updatedParams.delete("contactNo"); // Remove the 'contactNo' parameter
    setsearchParams(updatedParams); // Update the URL
    // console.log("getClientIdMethod called with value: ", value);
    setIsVisible(true);

    setLoading(true);
    if (value) {
      dispatch(getClientIdMethod_Redux(`${value.ClientID}`));
      dispatch(getClientIdName_Redux(`${value.Client}`));
      setContactLabel("Select");
      setClientIdMethod(value.ClientID);
      setClientLabel(value.Client);
      const contactObj = {
        agrno: objReq.accid,
        Email: objReq.email,
        password: objReq.password,
        Clientid: value.ClientID,
      };

      dispatch(
        Json_GetAccountUsersContactsByClient_Redux(contactObj, function (res) {
          // console.log(JSON.parse(res),"===== JSON.parse(res)");
          // setCustomersByClient(JSON.parse(res));
        })
      );

      const contactList = await dispatch(
        getAllContactsByClientID(value.ClientID, FolderObject)
      );

      if (contactList && contactList.length > 0) {
        const filterContact = contactList.filter(
          (contact) => contact?.OriginatorNo === value.ClientID
        );
        setCustomersByClient(filterContact);
      }
    } else {
      setCustomersByClient([]);
    }
    const body = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
    };
    const contectData = {
      ...body,
      ProjectID: FolderObject,
      ClientID: value?.ClientID,
    };

    const clientCardData = {
      ...body,
      intProjectId: FolderObject,
      strOrignatorNumber: value?.ClientID,
    };

    const clientAddress = {
      ...body,
      clientId: value.ClientID,
    };

    // setTimeout(() => {
    //   dispatch(Json_GetAllContactsByClientID_Redux(contectData));
    // }, 1000);

    // setTimeout(() => {
    //   dispatch(getAllContactsByClientID(value.ClientID,FolderObject));
    // }, 1000);

    setTimeout(() => {
      dispatch(Json_GetClientCardDetails_Redux(clientCardData));
    }, 1000);

    setTimeout(() => {
      dispatch(Json_GetClientAddresses_Redux(clientAddress));
    }, 1000);
  };

  const getContactfilters = (event, value) => {
    setsearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("contactNo", value?.ContactNo);
      return newParams;
    });
    dispatch(getContactEmailID_Redux(`${value["E-Mail"]}`));
    // localStorage.setItem('getContactEmailID', `${value.EmailId}`);
    setIsVisible(true);
    setLoading(true);
    setContactLabel(
      value.Title + " " + value["First Name"] + " " + value["Last Name"]
    );
    let filterContactObj = {
      accid: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      emailAddress: value["E-Mail"] ? value["E-Mail"] : objReq.EmailId,
      folder: folderSet,
      ccode: clientSet,
    };
    // console.log(`Contact obj=============${filterType} : `, filterContactObj);
    if (filterType == 1) {
      dispatch(
        GetAllSentMessagesByFolder_Json_Redux(filterContactObj, function (res) {
          setLoading(false);
        })
      );
    } else if (filterType == 2) {
      //recieved messages
      dispatch(
        GetAllReceivedMessagesByFolder_Json_Redux(
          filterContactObj,
          function (res) {
            setLoading(false);
          }
        )
      );
    }
  };

  //Create contact and open modal by click contact
  const createContactModalOpen = () => {
    AddsetAnchorEl(null);
    dispatch(handleOpenContactModal(true));
    // dispatch(EditContactOpenMdalReducer())
    // setEditContactModal(true);
  };

  const refreshData = () => {
    // window.location.reload()

    let receivedObj = {
      accid: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      ccode: "",
      emailAddress: "",
    };

    fetchSentUserMessages(); // Call the function returned by the hook
    dispatch(GetAllReceivedMessages_Json_Redux(receivedObj, function (res) { }));

    dispatch(getFolderObject_Redux(`${"All"}`));
    // setFolderObject(value.FolderID);
    setFolderLabel("All"); //Set folder label
    // dispatch(getClientIdMethod_Redux(`${value.ClientID}`));
    dispatch(getClientIdName_Redux(`${"All"}`));
    setContactLabel("All");
    // setClientIdMethod(value.ClientID);
    setClientLabel("All");
    setContactLabel("All");

    const updatedParams = new URLSearchParams(params);
    updatedParams.delete("contactNo"); // Remove the 'contactNo' parameter
    setsearchParams(updatedParams); // Update the URL
  };

  const closeContactModal = () => {
    setEditContactModal(false);
  };

  return (
    <Box className="d-flex justify-content-between flex-wrap">
      {/* {loading ? 'Loading...' : ''} */}
      {getFolderObject && getFolderObject.length > 0 && (
        <Box className="d-flex flex-wrap">
          <Box className="d-flex align-items-center me-1">
            <Typography className="poppins-medium font-12" variant="Body1">
              Folder:
            </Typography>

            <LinkDropDown
              label={folerLabelSet}
              ddType={"1"}
              dataOpt={getFolder}
              changeMethod={getFolderObject}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          </Box>
          <Box className="d-flex align-items-center me-1">
            <Typography className="poppins-medium font-12" variant="Body1">
              Client:
            </Typography>

            <LinkDropDown
              label={clientLabelSet}
              ddType={"2"}
              dataOpt={getClientsByFolder}
              changeMethod={getClientIdMethod}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          </Box>
          <Box className="d-flex align-items-center me-1">
            <Typography className="poppins-medium font-12" variant="Body1">
              Contact:
            </Typography>
            <LinkDropDown
              label={contactLabelSet}
              ddType={"3"}
              dataOpt={getCustomersByClient}
              changeMethod={getContactfilters}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          </Box>
        </Box>
      )}

      <Box className="d-flex">
        {/* <Tooltip title="Contact Details" arrow><Button className='min-width-auto' onClick={() => dispatch(setOpenContactDetailModal(true))}><AssignmentIndIcon className='text-black' /></Button></Tooltip> */}

        <Tooltip title="Edit" arrow>
          <Button className="min-width-auto" onClick={EdithandleClick}>
            <CreateIcon className="text-black font-18" />
          </Button>
        </Tooltip>

        {clientLabelSet != "All" && clientLabelSet != "Select" && (
          <Menu
            id="basic-menu"
            anchorEl={EditanchorEl}
            open={Editopen}
            onClose={EdithandleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {ClientIdMethod && (
              <MenuItem onClick={hanleModalOpne}>
                <PersonIcon className="me-2" /> Client
              </MenuItem>
            )}
            {contactLabelSet != "All" && contactLabelSet != "Select" && (
              <MenuItem onClick={hanleModalOpneContact}>
                <ApartmentIcon className="me-2" /> Contact
              </MenuItem>
            )}
          </Menu>
        )}

        <Tooltip title="Add" arrow>
          <Button className="min-width-auto" onClick={AddhandleClick}>
            <AddIcon className="text-black font-18" />
          </Button>
        </Tooltip>
        <Menu
          className="custom-dropdown"
          id="basic-menu"
          anchorEl={AddanchorEl}
          open={Addopen}
          onClose={AddhandleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={createClient}>
            <PersonIcon className="me-2" /> Client
          </MenuItem>
          {contactLabelSet && (
            <MenuItem onClick={createContactModalOpen}>
              <ApartmentIcon className="me-2" /> Contact
            </MenuItem>
          )}
        </Menu>

        <Tooltip title="Refresh" arrow>
          <Button className="min-width-auto">
            <RefreshIcon className="text-black font-18" onClick={refreshData} />
          </Button>
        </Tooltip>

        

        {/* <Tooltip title="View Setting" arrow><Button className='min-width-auto'><TuneIcon className='text-black' /></Button></Tooltip> */}
      </Box>

      {
        <EditContactModal
          open={editContactModal}
          close={closeContactModal}
          refreshData={refreshData}
        />
      }
    </Box>
  );
}

export default PortalMessagesFilters;
