import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ad_GetAllContacts: [],
  ad_GetAllClientList: [],
  requestcommentList: [],
  getDocumentStatusList: [],
  editCompanyModal: false,
  editContactModal: false,
  OpenPendingMessageModal: false,
  signDocument: {},
  directMessageAllData: {},
  draftFormData: {},
  ad_ClientId: {},
  ad_MessageAttachmentList: [],
  ad_Dm_Api_Body:{}
};

const adityaReducer = createSlice({
  name: "ak_Slice",
  initialState,
  reducers: {
    GetAllContacts_reducer: (state, action) => {
      state.ad_GetAllContacts = action.payload;
    },
    GetAllClientList_reducer: (state, action) => {
      state.ad_GetAllClientList = action.payload;
    },
    GetAllRequestComments: (state, { payload }) => {
      state.requestcommentList = payload;
    },
    GetDocumentStatusReducer: (state, { payload }) => {
      state.getDocumentStatusList = payload;
    },
    EditCompanyOpenMdalReducer: (state, { payload }) => {
      state.editCompanyModal = !state.editCompanyModal;
    },
    EditContactOpenMdalReducer: (state, { payload }) => {
      state.editContactModal = payload;
    },
    SignDocSaveReducer: (state, { payload }) => {
      const parseData = JSON.parse(payload);
      state.signDocument = parseData[0];
    },
    OpenPendingMessageModalReducer: (state, { payload }) => {
      state.OpenPendingMessageModal = payload;
    },
    DraftFormDataReducer: (state, { payload }) => {
      state.draftFormData = payload;
    },
    Ad_SetClientIdReducer: (state, { payload }) => {
      state.ad_ClientId = payload;
    },
    // direct message viwer
    Ad_DirectMessageApiBody : (state,{payload}) => {
      state.ad_Dm_Api_Body= {...payload}
    },
    DirectMessageAllReducer: (state, { payload }) => {
      state.directMessageAllData = payload;
    },
    Ad_DirectMessageAttachmentList_Reducer: (state, { payload }) => {
      state.ad_MessageAttachmentList = [...payload];
    },
  },
});

export const {
  GetAllContacts_reducer,
  GetAllClientList_reducer,
  GetAllRequestComments,
  EditCompanyOpenMdalReducer,
  EditContactOpenMdalReducer,
  SignDocSaveReducer,
  DirectMessageAllReducer,
  OpenPendingMessageModalReducer,
  DraftFormDataReducer,
  Ad_DirectMessageApiBody,
  Ad_SetClientIdReducer,
  Ad_DirectMessageAttachmentList_Reducer,
} = adityaReducer.actions;
export default adityaReducer.reducer;
