import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
// import "sweetalert2/src/sweetalert2.scss";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";
import RenderRequest from "../../components/RenderRequest";
import { fetchdocuRequestData } from "../../redux/api_helper";
import { fetchdocuRequestDataClient } from "../../redux/docuSlice";
import { setInboxMessageHtmlLoading } from "../../redux/dev_chetan_slice";

function DocuRequest() {
  const dispatch = useDispatch();
  let accordionData = useSelector(
    (state) => state.dev_sonam.RequestDocumentData
  );
  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );
  console.log("✌️portalAllRequestAll --->", portalAllRequestAll);

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("EmailId");
  const EmailID = searchParams.get("EmailId");
  const id = searchParams.get("id");
  const request = searchParams.get("request");
  const EmailId = searchParams.get("EmailId");
  const PortalDocId = searchParams.get("PortalDocId");

  const [value, setValue] = React.useState(0);
  const [RecentRequests, setRecentRequests] = useState([]);
  const [allShowDoc, setAllShowDoc] = useState([]);
  const [filterByDoc, setFilterByDoc] = useState(null);

  let { docuRequestDataClient: portalAllRequest, docuRequestDataClientLoding } =
    useSelector((state) => state.docu);

  const { inboxMessageHtmlLoading } = useSelector((state) => state.dev_chetan);

  useEffect(() => {
    if (PortalDocId) {
      const newData = portalAllRequestAll.filter((portalReq) => {
        return portalReq.PortalDocId === PortalDocId;
      });
      setAllShowDoc(newData);
    } else {
      setAllShowDoc([]);
    }
    // setAllShowDoc(portalAllRequestAll);
  }, [portalAllRequestAll, PortalDocId]);

  const handleFilterDoc = (item) => {
    setFilterByDoc(item);
  };

  useEffect(() => {
    dispatch(setInboxMessageHtmlLoading(true));
    portalAllRequestAll.length === 0 &&
      dispatch(fetchdocuRequestData(customerId || EmailID));
    dispatch(setInboxMessageHtmlLoading(false));
  }, [dispatch]);

  // dropdown
  const [anchorElRequests, setAnchorElRequests] = React.useState(null);
  const openRequests = Boolean(anchorElRequests);
  const RequestshandleClick = (event) => {
    setAnchorElRequests(event.currentTarget);
  };
  const RequestshandleClose = (e) => {
    setAnchorElRequests(null);
  };

  return (
    <Box className="mt-2">
      <Box className="container-fluid">
        <Box className="row">
          {inboxMessageHtmlLoading ? (
            <CustomLoader />
          ) : (
            <Box className="col-md-12 m-auto">
              <Box className="white-box-">
                {/* <h2 className="title mb-0">
                  {allShowDoc.length > 0 && allShowDoc[0].TemplateName}
                </h2>
                <hr /> */}
                {docuRequestDataClientLoding ? (
                  <>
                    <CustomLoader />
                  </>
                ) : allShowDoc && allShowDoc.length > 0 ? (
                  <>
                    <Box value={value} className="">
                      <Grid container spacing={2} className="">
                        {allShowDoc &&
                          allShowDoc.length > 0 &&
                          allShowDoc.map((accordionItem, accordionIndex) => (
                            <RenderRequest
                              accordionItem={accordionItem}
                              accordionIndex={accordionIndex}
                            />
                          ))}
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <>
                    <NoData message={"No Data Here"} />
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default DocuRequest;
