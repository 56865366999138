import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useDispatch, useSelector } from "react-redux";
import {
  setComposePortalMgsObj_Redux,
  setPortalRequestDocuments_Redux,
  setRequestDocument_Redux,
} from "../redux/docuSlice";
import { useNavigate } from "react-router-dom";
import { DraftFormDataReducer } from "../redux/adityaReducer";

const DraftButtonModal = () => {
  const { draftFormData } = useSelector(({ aditya }) => aditya);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleDraftButton = () => {
    console.log("===== eueygwefb", draftFormData);
    dispatch(setRequestDocument_Redux(draftFormData?.equestDocumnets));
    dispatch(
      setPortalRequestDocuments_Redux(draftFormData?.PortalRequestDocuments)
    );
    dispatch(setComposePortalMgsObj_Redux(draftFormData?.ComposePortalMgsObj));
    navigate("/user/create-new-request")
    dispatch(DraftFormDataReducer({}))
  };

  return (
    <Box className='draft-btn'>
      <Fab color="primary" aria-label="add" size="small" onClick={handleDraftButton}>
        <DriveFileRenameOutlineOutlinedIcon />
      </Fab>
    </Box>
  );
};

export default DraftButtonModal;
