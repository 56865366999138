import PrintIcon from "@mui/icons-material/Print";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef } from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import csv from "../../assets/images/files-icon/csv.png";
import doc from "../../assets/images/files-icon/doc.png";
import jpg from "../../assets/images/files-icon/jpg.png";
import pdf from "../../assets/images/files-icon/pdf.png";
import png from "../../assets/images/files-icon/png.png";
import text from "../../assets/images/files-icon/text.png";
import xls from "../../assets/images/files-icon/xls.png";
import userImg from "../../assets/images/user-2.svg";
import ReactToPrint from "react-to-print";
import DownloadIcon from "@mui/icons-material/Download";
import Comments from '../../components/Comments';
import { useLocation } from 'react-router-dom';
import TimelineModal from "../../modals/TimelineModal";
import { portalUser } from "../../redux/AxiosInstance";
import {
  downloadShareboxDocument,
  getApprovalCommentsHtml,
  GetApprovalsAndCommentsHtmlNew_Json,
  GetCertificateNew_Json,
  GetMessageDocumentsNew_Json,
  getMessageHtml,
  PortalUsersInboxNewByMsgId_Json_Test,
} from "../../redux/dev_chetan_api_helper";
import { useDispatch, useSelector } from "react-redux";
import { DownloadBase64File } from "../../utils/RequestsFunction";

const Item = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5),

  color: theme.palette.text.secondary,
}));

function convertDate(dateString) {
  // Extract the timestamp from the input string
  const timestamp = parseInt(dateString.substring(6, dateString.length - 2));

  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Get day, month, and year components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  // Format the date as dd/mm/yyyy
  return `${day}/${month}/${year}`;
}

function Proposal() {
  const location = useLocation();
  const dispatch = useDispatch();
  const {messageData, messageDocs, approvalsAndComments, approvalCertificate} = useSelector(state=> state.dev_chetan.proposalCompData);
  const messageHTML = useSelector(state=> state.dev_chetan.inboxMessageHtml);
  
  let certificateUrl = `data:application/pdf;base64,${approvalCertificate}`;
  
  const [value, setValue] = React.useState("1");

  useEffect(() => {
    if(messageData.length > 0){}else{
      dispatch(PortalUsersInboxNewByMsgId_Json_Test(sessionStorage.getItem("PortalDocId")));
    }
    if(messageHTML){}else{
      dispatch(getMessageHtml(sessionStorage.getItem("PortalDocId")));
    }
    if(messageDocs.length > 0){}else{
      dispatch(GetMessageDocumentsNew_Json(sessionStorage.getItem("PortalDocId")));
    }
    if(approvalsAndComments){}else{
      dispatch(GetApprovalsAndCommentsHtmlNew_Json(sessionStorage.getItem("PortalDocId")));
    }
    // if(approvalsAndComments){}else{
    // }
    dispatch(GetCertificateNew_Json(sessionStorage.getItem("PortalDocId")));

    //8d54ab1f371743f6a6ae68947797fe19

  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePrint = () => {
    window.print();
  };
  const componentRef = useRef();
  const downloadAllDocs = (e) => {
    messageDocs.forEach((doc) => {
      downloadFile(e, doc);
    });
  }
  const handlePreview = (data) => {
    // let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${portalDocID}&extension=${data.DocExtension}`;
    // dispatch(setOpenDocumentPreviewModal(true));
    // dispatch(setshareboxDocForPreviewURL(url));
  };
  const docTypeIcon = (target) => {
    switch (target) {
      case ".pdf":
        return pdf;
      case ".txt":
        return text;
      case ".docx":
        return doc;
      case ".jpg":
        return jpg;
      case ".jpeg":
        return jpg;
      case ".png":
        return png;
      case ".xls":
        return xls;
      case ".csv":
        return csv;
      default:
        return text;
    }
  };
  const downloadFile = (e, doc) => {
    e.stopPropagation();
    dispatch(
      downloadShareboxDocument(
        "5228c7c3e10d42479ce8d048ebda92b3",
        doc.Attachid,
        doc.DocExtension,
        doc.PortalName
      )
    );
  };
  const DownLoadCertificate = (e) => {
    let doc = certificateUrl.split(",")[1];
    if (doc) {
      let base64Doc = {
        DocData: doc,
        DocExtension: ".pdf",
      };
      DownloadBase64File(doc, "Certificate.pdf");
    }
  };
  return (
    <div ref={componentRef}>
      {messageData.length > 0 && messageData[0].Expired==="Yes" && <Box className="mb-3">
        <Alert variant="filled" severity="error">
          {/* <AlertTitle>Warning</AlertTitle>  */}
          This message has expired
        </Alert>
      </Box>}

      <Box sx={{ width: "100%", typography: "body1", marginTop: "12px" }}>
        <TabContext value={value}>
          {/* <Box className="clearfix">
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="custom-tabs mb-0 flex-wrap-tabs min-h-auto"
            >
              <Tab label="Message" value="1" />

              <Tab label="Request" value="2" />
            </TabList>
          </Box> */}

          <TabPanel value="1" className="p-0">
            <Box className="clearfix">
              <Box className="white-box">
                <Box className="d-flex align-items-center justify-content-between">
                  <h2 className="title">
                    {messageData.length > 0 && messageData[0].Subject}
                  </h2>

                  {(messageData.length>0 && messageData[0].ForApproval==="Yes" && messageData[0].FileApproved==="No" ) && <Box className="d-flex justify-content-end">
                    <Button
                      variant="contained"
                      checkCircleIcon
                      size="small"
                      // startIcon={<CheckCircleIcon />}
                      className="btn-blue me-2 mb-3"
                      // onClick={() => {
                      //   dispatch(
                      //     GetSignature_Json_Redux(clientBodyaccidData)
                      //   );
                      //   dispatch(setOpenSignDocument(true));
                      // }}

                      onClick={() => {
                        // dispatch(
                        //   GetSignature_Json_Redux({
                        //     ...signBody,
                        //   })
                        // );
                        // dispatch(setOpenSignDocument(true));
                        // dispatch(
                        //   setPortalDocIdForSignDocModal(portalDocID)
                        // );
                        // dispatch(setOpenFromInbox(true));
                      }}
                    >
                      Action
                    </Button>
                  </Box>}
                </Box>

                {/* <hr className="m-0" /> */}

                <Box className="mt-1">
                  <Box className="d-flex justify-content-between align-items-center flex-wrap">
                    <Box className="d-flex align-items-center mb-2">
                      <Box className="me-3">
                        {userImg && (
                          <img
                            src={userImg}
                            height={35}
                            width={35}
                            style={{
                              borderRadius: "50%",
                              background: "#eea706",
                            }}
                          />
                        )}
                      </Box>

                      <Box>
                        <Typography
                          variant="h3"
                          className="font-14 poppins-semibold"
                        >
                          {messageData.length > 0 && messageData[0].CName ? messageData[0].CName : "Not Available"}
                          {/* Test Client Name */}
                        </Typography>

                        <Typography variant="p">
                          {/* {EmailID && EmailID}
                          {customerId && customerId} */}
                          {messageData.length > 0 && messageData[0].EmailID ? messageData[0].EmailID : "Not Available"}
                          {/* Email Address */}
                          Customer Id
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="d-block d-md-flex">
                      <Box className="d-flex flex-wrap">
                        <Box className="me-2">
                          <p className="poppins-semibold">Section</p>
                          <p className="text-gray font-12 poppins-medium">
                            {messageData.length > 0 && messageData[0].ItemName ? messageData[0].ItemName : "Not Available"}
                            {/* Section Here */}
                          </p>
                        </Box>
                        <Box className="me-2 d-none">
                          <p className="poppins-semibold">
                            Send Date
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {SendDate} */}
                            dd/mm/yyyy
                          </p>
                        </Box>
                        <Box className="me-2 d-none">
                          <p className="poppins-semibold">View On</p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {ViewDate} */}
                            dd/mm/yyyy
                          </p>
                        </Box>
                        <Box className="me-2 d-none">
                          <p className="poppins-semibold">
                            Reference
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {ReferenceName} */}
                            ReferenceName
                          </p>
                        </Box>

                        <Box className="me-2 d-none">
                          <p className="poppins-semibold">
                            Issued on
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {Issued} */}
                            dd/mm/yyyy
                          </p>
                        </Box>
                        <Box className="me-2 d-none">
                          <p className="poppins-semibold">
                            Viewed on
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {Viewed} */}
                            dd/mm/yyyy
                          </p>
                        </Box>

                        <Box className="me-2">
                          <p className="poppins-semibold">
                            Received Date
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {ReceivedDate} */}
                            {messageData.length > 0 && messageData[0].ViewDate ? convertDate(messageData[0].ViewDate) : ""}
                          </p>
                        </Box>
                        <Box className="me-2">
                          <p className="poppins-semibold">
                            Action By
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {ActionBy === "Invalid date"
                              ? "Not Assigned"
                              : ActionBy} */}
                            {messageData.length > 0 && messageData[0].ActionDate ? convertDate(messageData[0].ActionDate) : ""}
                          </p>
                        </Box>
                        <Box className="me-2">
                          <p className="poppins-semibold">
                            Expiry Date
                          </p>
                          <p className="text-gray font-12 poppins-medium">
                            {/* {ExpiryDate} */}
                            {messageData.length > 0 && messageData[0].ExpiryDate ? convertDate(messageData[0].ExpiryDate) : ""}
                          </p>
                        </Box>
                      </Box>

                      <ReactToPrint
                        trigger={() => (
                          <Button
                            className="min-width-auto ms-1"
                            size="small"
                            onClick={handlePrint}
                          >
                            <PrintIcon className="text-black" />
                          </Button>
                        )}
                        content={() => componentRef.current}
                      />
                      {/* {!clientLoginStatus && (
                        <TimelineModal location={location} />
                      )} */}
                      {/* <TimelineModal location={location} /> */}
                    </Box>
                  </Box>

                </Box>

                <Box>

                  <Grid container spacing={3} className="mt-4">
                    <Grid
                      item
                      md={6}
                      sx={{
                      }}
                      className="pe-2"
                    >
                      <Box className="font-12">
                        <div className="mb-2 font-13">
                          {/* {inboxMessageHtml} */}
                          <div
                            // dangerouslySetInnerHTML={{
                            //   __html: inboxMessageHtml,
                            // }}
                            dangerouslySetInnerHTML={{
                              __html: messageHTML,
                            }}
                          ></div>
                        </div>
                        <Typography className="mb-2 font-13"></Typography>

                        {/* <hr /> */}
                      </Box>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      sx={{
                      }}
                      className="pe-2"
                    >
                      PDF Here
                    </Grid>

                  </Grid>

                </Box>
                {/* </Grid> */}

                <Box sx={{ width: "100%" }}>
                  <Grid container rowSpacing={1}>
                    {messageDocs.length > 0 && <Grid
                      item
                      xs={12}
                      // lg={messageStatus === "Expired" ? 12 : 12}
                      lg={12}
                      className="pt-0"
                    >
                      <Item>
                        <Box
                          className="d-flex justify-content-between mb-0 align-items-center"
                          sx
                        >
                          <Typography
                            variant="h3"
                            className="font-18 text-black poppins-semibold mb-0"
                          >
                            Attachments
                          </Typography>
                          <Button
                            onClick={downloadAllDocs}
                            variant="contained"
                            className="btn-blue mb-2"
                            size="small"
                            startIcon={<DownloadIcon />}
                          >
                            Download All
                          </Button>
                          {/* {portalMessageDocuments.length > 1 && (
                              <Button
                                onClick={downloadAllDocs}
                                variant="contained"
                                className="btn-blue mb-2"
                                size="small"
                                startIcon={<DownloadIcon />}
                              >
                                Download All
                              </Button>
                            )} */}
                        </Box>

                        {/* message Document */}
                        <Box className="d-flex flex-wrap main-email-attachment w-100 mt-1">
                          {messageDocs.length > 0 &&
                              messageDocs.map((doc) => {
                                return (
                                  <>
                                    <Box
                                      className="email-attachment mb-2 "
                                      title={
                                        doc && doc.PortalName
                                          ? doc.PortalName
                                          : "Not Available"
                                      }
                                      onDoubleClick={() =>
                                        handlePreview(doc)
                                      }
                                    >
                                      <Box>
                                        <img
                                          src={docTypeIcon(
                                            doc.DocExtension
                                          )}
                                          style={{ width: 28 }}
                                          alt="documents"
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            fontWeight: "550",
                                          }}
                                          className="d-flex align-items-center"
                                        >
                                          <span className="d-inline-block ms-2">
                                            {doc && doc.PortalName
                                              ? doc.PortalName
                                              : "Not Available"}
                                          </span>
                                        </Typography>
                                      </Box>
                                      <Box className="download">
                                        <Tooltip title="Download" arrow>
                                          <Button
                                            className="min-width-auto"
                                            size="small"
                                            onClick={(e) =>
                                              downloadFile(e, doc)
                                            }
                                          >
                                            <DownloadIcon className="text-blue font-20" />
                                          </Button>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                        </Box>
                      </Item>
                    </Grid>}
                    <hr className="" />
                    {/* {!clientLoginStatus && (
                      <Grid item xs={12}>
                        <Box className="request-details-accordian d-flex flex-wrap ">
                          {!clientLoginStatus &&
                            // Array.isArray(
                            //   GetMessageAttachments[portalDocID]
                            // ) &&
                            // GetMessageAttachments[portalDocID].map(
                            GetMessageAttachments.map((itm, index) => (
                              <AttachmentStatusComponant
                                key={itm.id || index}
                                portalDocId={portalDocID}
                                itm={itm}
                                index={index}
                                EdithandleClick={EdithandleClick}
                                EditanchorEl={EditanchorEl}
                                EditsetAnchorEl={EditsetAnchorEl}
                                MessageID={MessageID}
                              />
                            ))}

                        </Box>
                      </Grid>
                    )} */}

                    {(messageData.length>0 && messageData[0].Expired!=="Yes") && <Grid item xs={12} className="pt-0">
                      <Item>
                        <Comments
                          portalDocID={''}
                          portalMessageComments={''}
                          ClientName={''}
                          customerId={''}
                        />
                        {/* {(messageStatus === "For Info" ||
                          messageStatus === "Pending" ||
                          ReferenceName ||
                          ClientName) && (
                            <Comments
                              portalDocID={portalDocID}
                              portalMessageComments={portalMessageComments}
                              ClientName={ClientName}
                              customerId={customerId}
                            />
                          )} */}
                      </Item>
                    </Grid>}
                  </Grid>
                </Box>
              </Box>

              {/* {(messageStatus === "For Info" ||
                      messageStatus === "Pending" ||
                      ReferenceName ||
                      ClientName) && (
                      <Comments
                        portalDocID={portalDocID}
                        portalMessageComments={portalMessageComments}
                        ClientName={ClientName}
                        customerId={customerId}
                      />
                    )} */}

              {(messageData.length > 0 && messageData[0].FileApproved === "Yes" && messageData[0].ForApproval === "Yes") && <Box className="white-box 02">
                <Box className="d-flex align-items-center gap-3">
                  <Typography
                    className="font-18 text-black poppins-semibold"
                    variant="h3"
                  >
                    Certificate of approval
                  </Typography>
                  <Button
                    onClick={DownLoadCertificate}
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                    className="btn-blue"
                  >
                    Download Certificate
                  </Button>
                </Box>

                <hr />

                <Box>
                  <Grid container spacing={3} className="mt-4">
                    <Grid
                      item
                      sm={6}
                      sx={{
                        height: "400px",
                        overflow: "auto",
                      }}
                      className="pe-2"
                    >
                      <Card sx={{ border: "1px solid black" }}>
                        <iframe
                          src={certificateUrl}
                          width="100%" // Set the width
                          height="700px" // Set the height
                          frameBorder="0" // Set frameborder to 0
                          allowFullScreen // Allow fullscreen mode
                          title="Embedded Content" // Set the title for accessibility
                        ></iframe>
                        {/* {certificateUrl.split(",")[1] ===
                          "undefined" ? (
                          <>
                            <CustomLoader />
                          </>
                        ) : (
                          <iframe
                            src={certificateUrl}
                            width="100%" // Set the width
                            height="700px" // Set the height
                            frameBorder="0" // Set frameborder to 0
                            allowFullScreen // Allow fullscreen mode
                            title="Embedded Content" // Set the title for accessibility
                          ></iframe>
                        )} */}
                      </Card>
                    </Grid>
                    <Grid item sm={6}>
                      <Box>
                        <Box className="mb-2">
                          <Typography
                            variant="h2"
                            className="font-18 poppins-semibold"
                          >
                            Comments
                          </Typography>
                          <Box
                            // dangerouslySetInnerHTML={{
                            //   __html: portalApprovalCommentsHtml,
                            // }}
                            
                            dangerouslySetInnerHTML={{
                              __html: approvalsAndComments,
                            }}
                            className="single-user-comments"
                          ></Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>}

              {/* {messageStatus === "Approved" && (
                <Box className="white-box 02">
                  <Box className="d-flex align-items-center gap-3">
                    <Typography
                      className="font-18 text-black poppins-semibold"
                      variant="h3"
                    >
                      Certificate of approval
                    </Typography>
                    <Button
                      onClick={DownLoadCertificate}
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue"
                    >
                      Download Certificate
                    </Button>
                  </Box>

                  <hr />

                  <Box>
                    <Grid container spacing={3} className="mt-4">
                      <Grid
                        item
                        sm={6}
                        sx={{
                          height: "400px",
                          overflow: "auto",
                        }}
                        className="pe-2"
                      >
                        <Card sx={{ border: "1px solid black" }}>
                          {certificateUrl.split(",")[1] ===
                            "undefined" ? (
                            <>
                              <CustomLoader />
                            </>
                          ) : (
                            <iframe
                              src={certificateUrl}
                              width="100%" // Set the width
                              height="700px" // Set the height
                              frameBorder="0" // Set frameborder to 0
                              allowFullScreen // Allow fullscreen mode
                              title="Embedded Content" // Set the title for accessibility
                            ></iframe>
                          )}
                        </Card>
                      </Grid>
                      <Grid item sm={6}>
                        <Box>
                          <Box className="mb-2">
                            <Typography
                              variant="h2"
                              className="font-18 poppins-semibold"
                            >
                              Comments
                            </Typography>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: portalApprovalCommentsHtml,
                              }}
                              className="single-user-comments"
                            ></div>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )} */}
            </Box>
          </TabPanel>

          <TabPanel value="2" className="p-0">
            {/* <ClientRequestDetails /> */}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}

export default Proposal