import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";
import RenderRequest from "../../components/RenderRequest";
import { fetchdocuRequestData } from "../../redux/api_helper";
import { useCustomerId } from "../../redux/get_email_form_url";
import UserRenderRequest from "../../user/componants/requestComponant/UserRenderRequest";

const ClientRequestDetails = () => {
  const [load, setLoad] = useState(true);
  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const location = useLocation();
  const {
    PortalDocID: portalDocID,
    Subject,
    Section,
    ReceivedDate,
    SendDate,
    ReferenceName,
    ClientName,
    customerId,
    Issued,
    Viewed,
    ViewDate,
    ExpiryDate,
    ActionBy,
    Status,
    EmailID,
    SendMessage,
    MessageID,
    messageStatus,
    isClient,
    senderName,
    TabIndex,
    data,
  } = location?.state || {};

  const [searchParams] = useSearchParams();
  // const portalDocID = searchParams.get("PortalDocID");
  // const MessageID = searchParams.get("PortalDocID");

  // const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  // const customerId = searchParam.get("customerId");
  // const EmailID = searchParam.get("EmailID");

  const [allShowDoc, setAllShowDoc] = useState(portalAllRequestAll);

  const dispatch = useDispatch();
  const customerEmail = useCustomerId();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoad(true);
    const data = await dispatch(fetchdocuRequestData(customerId || EmailID));
    if (data && data.length > 0) setLoad(false);
  };

  useEffect(() => {
    if (portalDocID) {
      const newData = portalAllRequestAll.filter(
        (portalReq) => portalReq.PortalDocId === portalDocID
      );
      setAllShowDoc(newData);
    }
  }, [portalAllRequestAll, portalDocID, MessageID]);

  const LoginClient = localStorage.getItem("LoginClient");
  return (
    <>
      {load ? (
        <CustomLoader />
      ) : (
        <Box className="">
          {/* <h2 className="title mb-0">Requests</h2> */}
          <Grid container spacing={2} className="">
            {allShowDoc && allShowDoc.length > 0 ? (
              LoginClient ? (
                allShowDoc.map((accordionItem, accordionIndex) => (
                  <RenderRequest
                    accordionItem={accordionItem}
                    accordionIndex={accordionIndex}
                  />
                ))
              ) : (
                <UserRenderRequest accordionItem={allShowDoc} id={0} />
              )
            ) : (
              <Grid item xs={12}>
                <NoData className="w-100" message={"No Data Here"} />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ClientRequestDetails;
