import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import DocuTextField from "../cmp-utils/DocuTextField";
import { getPortalContactVerification_Json, UpdatedPortalContactVerification_Json } from "../redux/ak_api_helper";

const AMLCheckViewEdit = () => {
  const [editModal, setEditModal] = useState(true);
  const [isAmlCheck, setIsAmlCheck] = useState(true);
  const [editButtonShow, setEditButtonShow] = useState(true);

  const [amlData, setAmlData] = useState({
    "Bank Account Number": "",
    "Passport Number": "",
    "Bank SR Code": "",
    "Personal UTR Number": "",
    "Driving License Number": "",
    "Photo ID Verified": "Verified",
    "NI Number": "",
    "Address Verified": "Not Verified",
  });
  const [amlDataPreviouse, setAmlDataPreviouse] = useState({
    "Bank Account Number": "",
    "Passport Number": "",
    "Bank SR Code": "",
    "Personal UTR Number": "",
    "Driving License Number": "",
    "Photo ID Verified": "Verified",
    "NI Number": "",
    "Address Verified": "Not Verified",
  });

  const obj1 = {
    accId: localStorage.getItem("accIdClient")
      ? localStorage.getItem("accIdClient")
      : "",
    username: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
    sPassword: localStorage.getItem("passwordClient")
      ? localStorage.getItem("passwordClient")
      : "",
  };

  const AMLValidation = Yup.object({
    "Bank Account Number": Yup.string().required(
      "Bank Account Number is required"
    ),
    "Passport Number": Yup.string().required("Passport Number is required"),
    "Bank SR Code": Yup.string().required("Bank SR Code is required"),
    "Driving License Number": Yup.string().required(
      "Driving License Number is required"
    ),
    "NI Number": Yup.string().required("NI Number is required"),
  });

  useEffect(() => {
    getAMLDetails()
  },[])

  async function getAMLDetails() {
    const bodyData = { ...obj1 };
    bodyData.Accid = obj1.accId;
    bodyData.Contactemail = "aakashr.diploma2022@ssism.org";
    delete bodyData.accId;
    const data = await getPortalContactVerification_Json(bodyData);
    const result = data?.Table[0];
    setAmlData({
      ...amlData,
      ["Bank Account Number"]: result?.BnkAccNumber || "8974759874",
      ["Bank SR Code"]: result?.BnkSrCode || "021616506414",
      ["Passport Number"]: result?.PassportNumber || "passport",
      ["Personal UTR Number"]: result?.PassportNumber || "UTR no.",
      ["Driving License Number"]: result?.DrvLicNumber || "driving license",
      ["NI Number"]: result?.NatInsNumber || "NI number",
    });
    setAmlDataPreviouse({
      ...amlData,
      ["Bank Account Number"]: result?.BnkAccNumber || "8974759874",
      ["Bank SR Code"]: result?.BnkSrCode || "021616506414",
      ["Passport Number"]: result?.PassportNumber || "passport",
      ["Personal UTR Number"]: result?.PassportNumber || "UTR no.",
      ["Driving License Number"]: result?.DrvLicNumber || "driving license",
      ["NI Number"]: result?.NatInsNumber || "NI number",
    });
  }

  async function updateAmlCheck() {
    const bodyData = { ...obj1 };
    bodyData.Accid = obj1.accId;
    bodyData.Contactemail = "aakashr.diploma2022@ssism.org";
    bodyData.bankAccount = amlData?.["Bank Account Number"];
    bodyData.bankSrCode = amlData?.["Bank SR Code"];
    bodyData.driveLic = amlData?.["Driving License Number"];
    bodyData.niNumber = amlData?.["NI Number"];
    bodyData.passport = amlData?.["Passport Number"];
    delete bodyData.accId;
    const res = await UpdatedPortalContactVerification_Json(bodyData);
    if (res?.data?.d) {
      setIsAmlCheck(true);
      toast.success("AML details updated");
      setAmlDataPreviouse({ ...amlData });
    } else {
      toast.error("Something went wrong !");
    }
  }

  const handleChangeAml = (e) => {
    const { name, value } = e.target;
    setAmlData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box className="white-box">
      {isAmlCheck ? (
        <Box>
          <div className="d-flex justify-content-between">
            <h2 className="title font-18">AML Check</h2>
            <IconButton
              onClick={() => {
                setIsAmlCheck(false);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    Bank Account Number
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {amlData?.["Bank Account Number"]}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    Bank SR Code
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {amlData?.["Bank SR Code"]}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    Driving License Number
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {amlData?.["Driving License Number"]}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    NI Number
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {amlData?.["NI Number"]}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    Passport Number
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {amlData?.["Passport Number"]}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    Personal UTR Number
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {amlData?.["Personal UTR Number"]}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="poppins-medium font-12">
                    Photo ID Verified
                  </Typography>
                  <Typography className="font-12 text-success poppins-medium">
                    <span>
                      <HowToRegIcon className="font-22" />{" "}
                      {amlData?.["Photo ID Verified"]}
                    </span>
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Box>
                    <Typography className="poppins-medium font-12">
                      Address Verified
                    </Typography>
                    <Typography className="font-12 text-danger poppins-medium">
                      <span>
                        <CloseIcon className="font-20" />{" "}
                        {amlData?.["Address Verified"]}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Box className="white-box">
            <Formik
              initialValues={amlData}
              enableReinitialize
              validationSchema={AMLValidation}
              onSubmit={updateAmlCheck}
            >
              <Form>
                <div className="d-flex justify-content-between">
                  <h2 className="title font-18">AML Details</h2>
                  <div>
                    <IconButton
                      onClick={() => {
                        setIsAmlCheck(true);
                        setAmlData({ ...amlDataPreviouse });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <Button
                      id="basic-button"
                      aria-haspopup="true"
                      className="btn-blue "
                      type="submit"
                      startIcon={<DoneAllIcon />}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <hr />

                <Grid container rowSpacing={0} columnSpacing={2}>
                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      Bank Account Number
                    </label>
                    <Field
                      size={"small"}
                      label={"Bank Account Number"}
                      fieldValue={amlData?.["Bank Account Number"]}
                      id={"bank"}
                      fieldName={"Bank Account Number"}
                      onChange={handleChangeAml}
                      component={DocuTextField}
                    />
                    <ErrorMessage
                      name="Bank Account Number"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      Passport Number
                    </label>
                    <Field
                      size={"small"}
                      label={"Passport Number"}
                      fieldValue={amlData?.["Passport Number"]}
                      id={"Passport"}
                      fieldName={"Passport Number"}
                      onChange={handleChangeAml}
                      component={DocuTextField}
                    />
                    <ErrorMessage
                      name="Passport Number"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      Bank SR Code
                    </label>
                    <Field
                      size={"small"}
                      label={"Bank SR Code"}
                      fieldValue={amlData?.["Bank SR Code"]}
                      id={"SR"}
                      fieldName={"Bank SR Code"}
                      onChange={handleChangeAml}
                      component={DocuTextField}
                    />
                    <ErrorMessage
                      name="Bank SR Code"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      Driving License Number
                    </label>
                    <Field
                      size={"small"}
                      label={"Driving License Number"}
                      fieldValue={amlData?.["Driving License Number"]}
                      id={"Driving"}
                      fieldName={"Driving License Number"}
                      onChange={handleChangeAml}
                      component={DocuTextField}
                    />
                    <ErrorMessage
                      name="Driving License Number"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      NI Number
                    </label>
                    <Field
                      size={"small"}
                      label={"NI Number"}
                      fieldValue={amlData?.["NI Number"]}
                      id={"NI"}
                      fieldName={"NI Number"}
                      onChange={handleChangeAml}
                      component={DocuTextField}
                    />
                    <ErrorMessage
                      name="NI Number"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AMLCheckViewEdit;
