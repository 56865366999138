import DataGrid, {
  FilterRow,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
const pageSizes = [20, 50, 100];

export default function CompanyGrid({
  dataSourceOptions,
  selectedFolder,
  filterEnable,
}) {
  console.log("✌️selectedFolder --->", selectedFolder);
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);
  const onContentReady = useCallback(
    (e) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const handleRowDoubleClick = (target) => {
    navigate(
      `/user/client-details?OrgNo=${target.data.OriginatorNo}&folder=${selectedFolder?.FolderID}`
    );
  };

  return (
    <Box>
      <DataGrid
        dataSource={
          dataSourceOptions && dataSourceOptions.length > 0
            ? dataSourceOptions
            : []
        }
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        className="table-grid table-height  table-width"
        columnAutoWidth={true}
        // width="75vw"
        onRowDblClick={handleRowDoubleClick}
        remoteOperations={true}
        // onContentReady={onContentReady}
      >
        <FilterRow visible={filterEnable} />
        <Scrolling columnRenderingMode="virtual" />
        {/* <GroupPanel visible={true} /> */}
        {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
        {/* <Grouping autoExpandAll={false} /> */}

        {/* <Column
        dataField="Company Name"
        caption="Company Name"
        dataType="string"
      />
      <Column
        dataField="First Name"
        caption="First Name"
        dataType="string"
      />
      <Column
        dataField="Last Name"
        caption="Last Name"
        dataType="string"
      />
      <Column
        dataField="E-Mail"
        caption="E-Mail"
        dataType="string"
      /> */}

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
      <Paging defaultPageSize={20} />
    </DataGrid>
    </Box>
  )
}
