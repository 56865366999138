import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  GetDocTypeIcon2Requests,
  RequestDownloadFile,
} from "../../utils/RequestsFunction";

const ShowRequestDocument = ({ DocumentData }) => {
  // Function to convert Base64 string to a Uint8Array
  function base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  // Function to handle preview
  const handlePreview = (doc) => {
    if (doc && (doc.DocData || doc.DocData2)) {
      const byteArray = base64ToUint8Array(doc.DocData || doc.DocData2);
      const mimeType =
        doc.DocExtension === "pdf"
          ? "application/pdf"
          : doc.DocExtension === "docx"
          ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          : doc.DocExtension === "xlsx"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : doc.DocExtension === "xls"
          ? "application/vnd.ms-excel"
          : doc.DocExtension === ".pdf"
          ? "application/pdf"
          : "application/octet-stream";

      const blob = new Blob([byteArray], { type: mimeType });
      const url = URL.createObjectURL(blob);

      if (mimeType === "application/pdf") {
        // Open PDF in a new tab
        window.open(url);
      }
      // else if (mimeType.includes("sheet")) {
      //   Break;
      //   // Preview .xlsx or .xls file using SheetJS
      //   const reader = new FileReader();
      //   reader.onload = (e) => {
      //     const arrayBuffer = e.target.result;
      //     const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      //     const sheetName = workbook.SheetNames[0];
      //     const sheet = workbook.Sheets[sheetName];
      //     const html = XLSX?.utils?.sheet_to_html(sheet); // Convert sheet to HTML
      //     const previewWindow = window.open("", "_blank");
      //     previewWindow.document.write(html);
      //   };
      //   reader.readAsArrayBuffer(blob);
      // }
      //  else {
      //   // For .docx or unsupported files, use Office Viewer or handle separately
      //   window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`, "_blank");
      // }
    } else {
      console.error("Document data is not available for preview");
    }
  };

  // Function to handle download with dynamic file type
  const downloadFile = (e, doc) => {
    e.preventDefault();

    if (doc && (doc.DocData || doc.DocData2)) {
      let extension = doc.DocExtension.startsWith(".")
        ? doc.DocExtension.toLowerCase()
        : `.${doc.DocExtension.toLowerCase()}`;

      let mimeType = "application/octet-stream";
      switch (extension) {
        case ".pdf":
          mimeType = "application/pdf";
          break;
        case ".docx":
          mimeType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case ".xlsx":
          mimeType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        case ".xls":
          mimeType = "application/vnd.ms-excel";
          break;
      }

      const byteArray = doc.DocData2
        ? base64ToUint8Array(doc.DocData2)
        : base64ToUint8Array(doc.DocData);
      const blob = new Blob([byteArray], { type: mimeType });
      const url = URL.createObjectURL(blob);

      const filename = `${doc.DocName.replace(
        /[^a-zA-Z0-9_\- ]+/g,
        ""
      )}${extension}`;

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      console.error("Document data is not available for download");
    }
  };

  return (
    <Box className="d-flex flex-wrap main-email-attachment w-100">
      <Box
        className="email-attachment mb-0 d-flex p-0 w-100"
        title={
          DocumentData && DocumentData.PortalName
            ? DocumentData.PortalName
            : "Not Available"
        }
        onDoubleClick={() => handlePreview(DocumentData)} // Preview on double click
      >
        <Box className="download">
          <Tooltip title="Download" arrow>
            <Button
              className="min-width-auto"
              size="small"
              onClick={(e) => RequestDownloadFile(e, DocumentData)}
            >
              <DownloadIcon className="text-blue font-20" />
            </Button>
          </Tooltip>
        </Box>

        <img
          src={GetDocTypeIcon2Requests(DocumentData.DocExtension)}
          style={{ width: 28 }}
          alt="documents"
        />
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "550",
          }}
          className="d-flex align-items-center"
        >
          <span className="d-inline-block ms-2">
            {DocumentData && DocumentData?.DocName
              ? DocumentData?.DocName +
                (typeof DocumentData?.DocExtension === "string" &&
                DocumentData?.DocExtension.includes(".")
                  ? DocumentData?.DocExtension
                  : "." + (DocumentData?.DocExtension || ""))
              : "Not Available"}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(ShowRequestDocument);
