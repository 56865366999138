import "./documentCard.scss";
import { Box, Checkbox, Typography } from "@mui/material";
import moment from "moment";
import pdf from "../assets/images/files-icon/pdf.png";
import png from "../assets/images/files-icon/png.png";
import jpg from "../assets/images/files-icon/jpg.png";
import doc from "../assets/images/files-icon/doc.png";
import csv from "../assets/images/files-icon/csv.png";
import xls from "../assets/images/files-icon/xls.png";
import text from "../assets/images/files-icon/text.png";
import DocumentTripleDot from "./DocumentTripleDoc";
import { useDispatch } from "react-redux";
import { setOpenDocumentPreviewModal } from "../redux/modalSlice";
import { setshareboxDocForPreviewURL } from "../redux/dev_chetan_slice";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
let acc_id = localStorage.getItem("agrno");
function DocumentCard({ itm, i, checkboxData, setCheckboxData }) {
    const { agrno } = useLocalStorage();
    const dispatch = useDispatch();
    const handlePreview = (data) => {
        let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${acc_id?acc_id:"0003"}&attachment=${data.AttachID}&Id=${data.PortalDocID}&extension=${data.DocExtension}`;
        dispatch(setOpenDocumentPreviewModal(true));
        dispatch(setshareboxDocForPreviewURL(url));
    };
    const docTypeIcon = (target) => {
        switch(target){
            case 'pdf':
                return pdf;
                case 'txt':
                return text;
                case 'docx':
                return doc;
                case 'jpg':
                return jpg;
                case 'jpeg':
                return jpg;
                case 'png':
                return png;
                case 'xls':
                return xls;
                case 'csv':
                return csv;
            default:
                return text;
        }
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };   // Don't remove this code - dev-chetan 

  const handleCheckbox = (e, i, itm) => {     // Don't remove this code - dev-chetan 
    e.stopPropagation();
    if (e.target.checked) {
      let newCheckList = { ...checkboxData };
      newCheckList[i] = itm;
      setCheckboxData(newCheckList);
    } else {
      let newCheckList = { ...checkboxData };
      delete newCheckList[i];
      setCheckboxData(newCheckList);
    }
  };
  return (
    <>
      <Box
        key={`shareDoc_${i}`}
        onDoubleClick={() => handlePreview(itm)}
        className="document-box d-flex align-items-center mb-0 pointer"
      >
        {/* <Checkbox
        className={Object.keys(checkboxData).length>0?"":"test-check123"}
          {...label}
          checked={
          Object.keys(checkboxData).includes(String(i)) ? true : false
          }
          onChange={(e) => handleCheckbox(e, i, itm)}
        /> */}    {/* Don't remove this code - dev-chetan  */}
        <img
          src={docTypeIcon(itm.DocExtension.slice(1))}
          style={{ width: 26 }}
          alt=""
        />
        <Box className="ms-2">
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
            }}
          >
            {itm["Document Name"]
              && itm["Document Name"].length>25 ? itm["Document Name"].substr(0,25)+"..." 
              : itm["Document Name"] ? itm["Document Name"] : "Document Name Not Available"}
          </Typography>
          <p className="text-gray font-10">
            {itm.Added
              ? moment(itm.Added).format("DD/MM/yyyy hh:mm A")
              : "Not Available"}
          </p>
        </Box>

        <Box className='ms-auto'>
          <DocumentTripleDot itm={itm} i={i} />
        </Box>
      </Box>
    </>
  );
}

export default DocumentCard;
