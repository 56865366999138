import DeleteIcon from "@mui/icons-material/Delete";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import { DataGrid } from "devextreme-react";
import {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid"; // Correct import
import React from "react";

import { Menu, MenuItem, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetAllReceivedMessages_Json_Redux,
  GetAllSentMessages_Json_Redux,
  GetRetractedMessages_Json_Redux,
} from "../redux/api_helper";
import {
  ad_MessageDeleted_Action,
  ad_MessageRetracted_Action,
  ad_MessageUnRetracted_Action,
  ad_SendReminderByMsgId_Json_Action,
} from "../redux/dev_aditya_action";
import { markMessageAsViewed } from "../redux/dev_chetan_api_helper";
import { userBodyDAtaa } from "../utils/RequestsFunction";

const DataGridComposeMessage = ({
  TableData,
  TableHeader,
  value,
  filterEnable,
  gridRef,
}) => {
  const pageSizes = [20, 50, 100];

  const [MessageanchorEl, MessagesetAnchorEl] = React.useState(null);
  const [actionData, setActionData] = React.useState({});
  const Messageopen = Boolean(MessageanchorEl);
  const handleClick = (event, data) => {
    MessagesetAnchorEl(event.currentTarget);
    setActionData(data);
  };
  const MessagehandleClose = () => {
    // dispatch(ad_MessageRetracted_Action())
    MessagesetAnchorEl(null);
  };

  const getSentMassage = async () => {
    let body = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };

    let receivedObj = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };

    await dispatch(GetAllSentMessages_Json_Redux(body, function (res) {}));
    await dispatch(GetAllReceivedMessages_Json_Redux(receivedObj, function (res) {}));
    await dispatch(GetRetractedMessages_Json_Redux(receivedObj, function (res) {}));
  };

  const HandleUntractMessage = (data) => {
    dispatch(
      ad_MessageRetracted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );

    MessagesetAnchorEl(null);
  };

  const HandleDeleteMessaage = (data) => {
    MessagesetAnchorEl(null);
    dispatch(
      ad_MessageDeleted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (date) => moment(date).format("DD-MM-YYYY");

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Pending":
        return "badge text-bg-warning";
      case "Unread":
        return "badge text-bg-primary";
      case "Disapproved":
        return "badge text-bg-danger";
      default:
        return "badge text-bg-success";
    }
  };

  const isValidDate = (date) => dayjs(date, "DD/MM/YYYY", true).isValid();

  const SendReminderMessaage = (row) => {
    console.log("✌️row --->", row);
    let body = {
      messageID: row?.MessageNo,
      forApproval: row?.ApprovalRequested,
      isAppcodeReq: false,
      forApprovalId: "",
      contactEmail: row.History[3]?.customerId,
      copyEmail: true,
    };
    console.log("✌️body --->", body);
    dispatch(
      ad_SendReminderByMsgId_Json_Action(body, (res) => {
        console.log("✌️res --->", res);
      })
    );
  };

  const HandleViewMessage = (row) => {
    // for Recieved Message
    if (value === "2") {
      if (row?.MessageNo && !row?.Viewed) {
        dispatch(markMessageAsViewed(row?.MessageNo));
      }
      let customerId = row.History[2].customerId;

      const data = {
        PortalDocID: row.MessageNo,
        Section: row.DueDate,
        Subject: row.Subject,
        Issued: formatDate(row.Issued),
        Viewed: formatDate(row.Viewed),
        ClientName: row.ReferenceName,
        Status: "Yes", // Assuming Status is "Yes" for this case
        customerId: customerId,
        TabIndex: value, // Assuming 'value' is defined somewhere
      };

      // Use navigate with state
      navigate("/user/portalmessage/inboxes/incoming-message-viewer", {
        state: data,
      });
    }
    // for retract and sent message Message
    else {
      let customerId = row.History[3].customerId;

      const data = {
        PortalDocID: row.MessageNo,
        Section: row.DueDate,
        Subject: row.Subject,
        Issued: formatDate(row.Issued),
        Viewed: formatDate(row.Viewed),
        ClientName: row.ReferenceName,
        Status: "Yes", // Assuming the status is "Yes" in this case
        MessageID: row.MessageID,
        customerId: customerId,
        TabIndex: value,
        messageStatus: row.Status,
      };

      // Navigate with the state
      navigate("/user/portalmessage/sent/outgoing-message-viewer", {
        state: data,
      });
    }
  };

  const handleRowDoubleClick = ({ data }) => {
    console.log("✌️e --->", data);
    if (data?.MessageNo && !data?.Viewed) {
      dispatch(markMessageAsViewed(data?.MessageNo));
    }
    let customerId = data?.History[3]?.customerId;

    const dataMsg = {
      PortalDocID: data?.MessageNo,
      Section: data?.DueDate,
      Subject: data?.Subject,
      Issued: formatDate(data?.Issued),
      Viewed: formatDate(data?.Viewed),
      ClientName: data?.ReferenceName,
      Status: "Yes", // Assuming Status is "Yes" for this case
      customerId: customerId,
      TabIndex: value, // Assuming 'value' is defined somewhere
    };
    navigate("/user/portalmessage/inboxes/incoming-message-viewer", {
      state: dataMsg,
    });
  };

  return (
    TableData && (
      <div>
        {Number(value) === 2 ? (
          <DataGrid
            ref={gridRef}
            dataSource={TableData}
            showBorders={true}
            width="100%"
            // height={450}
            allowColumnResizing={true}
            className="table-grid table-height  table-width"
            remoteOperations={true}
            onRowDblClick={handleRowDoubleClick}
          >
            <Column
              dataField={"Subject"}
              caption={"Subject"}
              allowHeaderFiltering={false}
              minWidth={350}
              maxWidth={700}
            />

            <Column
              dataField={"ReferenceName"}
              caption={"Sender"}
              allowSorting={false}
              // allowHeaderFiltering={false}
              minWidth={200}
              maxWidth={300}
              cellRender={({ row }) => {
                const recipientData = row?.data;
                return (
                  <Box>
                    <p>{recipientData?.Recipient}</p>
                    <span className="badge text-bg-primary">
                      {recipientData?.ReferenceName}
                    </span>
                  </Box>
                );
              }}
            />

            <Column
              dataField={"IssuedOn"}
              caption={"Issued On"}
              allowSorting={false}
              allowHeaderFiltering={false}
              minWidth={150}
              maxWidth={300}
            />

            <Column
              dataField={"Viewed"}
              caption={"Viewed On"}
              allowSorting={false}
              allowHeaderFiltering={false}
              minWidth={150}
              maxWidth={300}
            />

            {/* Section Column start  */}
            <Column
              dataField={"DueDate"}
              caption={"Section"}
              allowHeaderFiltering={false}
              minWidth={150}
              allowSorting={false}
              maxWidth={200}
            />

            {/* Reference ID start  */}
            <Column
              dataField={"Section"}
              caption={"Reference ID"}
              minWidth={150}
              allowSorting={false}
              maxWidth={200}
              cellRender={(data) => {
                const row = data?.row?.data;
                return (
                  <Box className="d-flex align-items-center justify-content-cente">
                    <Box className="hover-status-heading">
                      <span className={"text-blue"}>Reference ID</span>

                      <Box className="hover-status-box shadow">
                        <Box className="d-flex justify-content-center">
                          <Box className="poppins-bold pe-2 text-black">
                            <p>Email ID</p>
                          </Box>
                          <Box>
                            <p>{row.History[2]?.customerId}</p>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            />

            {/* Action Column Start*/}
            <Column
              caption={"Action"}
              minWidth={80}
              maxWidth={150}
              cellRender={({ row }) => {
                //   console.log("✌️akD --->", row);
                //   console.log("✌️row?.data?.Status --->", row?.data?.Status);
                return (
                  <Box className="ms-2">
                    <IconButton
                      id="basic-button"
                      aria-controls={Messageopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={Messageopen ? "true" : undefined}
                      onClick={(e) => handleClick(e, row?.data)}
                      className="p-1"
                    >
                      <MoreVertIcon className="font-18" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={MessageanchorEl}
                      open={Messageopen}
                      onClose={MessagehandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="custom-dropdown"
                    >
                      <MenuItem onClick={() => HandleViewMessage(actionData)}>
                        <DraftsOutlinedIcon className="me-2" /> View Message
                      </MenuItem>

                      <MenuItem
                        onClick={() => HandleDeleteMessaage(actionData)}
                      >
                        <DeleteIcon className="me-2" /> Delete Message
                      </MenuItem>
                      {/* <MenuItem onClick={HandleLaunchNewTab}>
                          <LaunchIcon className="me-2" /> Launch in new tab
                        </MenuItem> */}
                    </Menu>
                  </Box>
                );
              }}
            />
            {/* Action Column End*/}

            <FilterRow visible={filterEnable} />
            <HeaderFilter visible={filterEnable} />
            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
            <Paging defaultPageSize={20} />
          </DataGrid>
        ) : (
          <DataGrid
            ref={gridRef}
            dataSource={TableData}
            showBorders={true}
            width="100%"
            // height={450}
            allowColumnResizing={true}
            className="table-grid table-height  table-width"
            remoteOperations={true}
            onRowDblClick={handleRowDoubleClick}
          >
            {/* Type column */}
            <Column
              dataField={"ApprovalRequested"}
              allowSorting={false}
              caption={"Type"}
              cellRender={(typeData) => {
                return typeData?.data?.ApprovalRequested === "Yes" ? (
                  <Tooltip title="For Approval">
                    <strong className="badge text-bg-success">A</strong>
                  </Tooltip>
                ) : (
                  <Tooltip title="For Information">
                    <strong className="badge text-bg-primary px-1">
                      <InfoOutlinedIcon className="font-13" />
                    </strong>
                  </Tooltip>
                );
              }}
              minWidth={50}
              maxWidth={100}
              headerFilter={{
                visible: true, // Makes the header filter dropdown visible
                dataSource: (options) => {
                  // Customizes the header filter dataSource to remove search functionality
                  options.dataSource = [
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                  ]; // Provide static options
                  options.searchEnabled = false; // Disable the search box
                },
              }}
            />

            {/* Type column End */}

            <Column
              dataField={"Subject"}
              caption={"Subject"}
              allowHeaderFiltering={false}
              minWidth={350}
              maxWidth={700}
            />

            <Column
              dataField={"Recipient"}
              caption={"Recipient"}
              allowSorting={false}
              allowHeaderFiltering={false}
              minWidth={200}
              maxWidth={300}
              cellRender={({ row }) => {
                const recipientData = row?.data;
                return (
                  <Box>
                    <p>{recipientData?.Recipient}</p>
                    <span className="badge text-bg-primary">
                      {recipientData?.ReferenceName}
                    </span>
                  </Box>
                );
              }}
            />
            <Column
              dataField={"IssuedOn"}
              caption={"Issued On"}
              allowSorting={false}
              allowHeaderFiltering={false}
              minWidth={150}
              maxWidth={300}
            />
            <Column
              dataField={"DueDate"}
              caption={"Due Date"}
              allowSorting={false}
              allowHeaderFiltering={false}
              minWidth={150}
              maxWidth={300}
            />

            {/* Status Column start  */}
            <Column
              dataField={"Status"}
              caption={"Status"}
              minWidth={150}
              allowSorting={false}
              maxWidth={200}
              cellRender={(data) => {
                const row = data?.row?.data;
                return (
                  <Box className="d-flex align-items-center justify-content-cente">
                    <Box className="hover-status-heading">
                      <span className={getStatusBadgeClass(row.Status)}>
                        {row.Status}{" "}
                      </span>

                      <Box className="hover-status-box shadow">
                        {row?.History?.map((historyRow) => (
                          <Box key={historyRow.date} className="d-flex">
                            <Box className="poppins-bold pe-2 text-black">
                              <p>{historyRow.date}</p>
                            </Box>
                            <Box>
                              <p>{historyRow.customerId}</p>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            />
            {/* Status Column End  */}

            {/* Action Column Start*/}
            <Column
              caption={"Action"}
              minWidth={80}
              maxWidth={150}
              cellRender={({ row }) => {
                //   console.log("✌️akD --->", row);
                //   console.log("✌️row?.data?.Status --->", row?.data?.Status);
                return (
                  <Box className="ms-2">
                    <IconButton
                      id="basic-button"
                      aria-controls={Messageopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={Messageopen ? "true" : undefined}
                      onClick={(e) => handleClick(e, row?.data)}
                      className="p-1"
                    >
                      <MoreVertIcon className="font-18" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={MessageanchorEl}
                      open={Messageopen}
                      onClose={MessagehandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="custom-dropdown"
                    >
                      <MenuItem onClick={() => HandleViewMessage(actionData)}>
                        <DraftsOutlinedIcon className="me-2" /> View Message
                      </MenuItem>
                      {/* {row?.data?.Status === "Pending" && ( */}
                      <MenuItem
                        onClick={() => SendReminderMessaage(actionData)}
                      >
                        <NotificationsActiveOutlinedIcon className="me-2" />{" "}
                        Send Reminder
                      </MenuItem>
                      {/* )} */}
                      <MenuItem
                        onClick={() => HandleUntractMessage(actionData)}
                      >
                        <UnsubscribeIcon className="me-2" />
                        Retract Message
                      </MenuItem>
                      <MenuItem
                        onClick={() => HandleDeleteMessaage(actionData)}
                      >
                        <DeleteIcon className="me-2" /> Delete Message
                      </MenuItem>
                      {/* <MenuItem onClick={HandleLaunchNewTab}>
                          <LaunchIcon className="me-2" /> Launch in new tab
                        </MenuItem> */}
                    </Menu>
                  </Box>
                );
              }}
            />
            {/* Action Column End*/}

            <FilterRow visible={filterEnable} />
            <HeaderFilter visible={filterEnable} />
            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
            <Paging defaultPageSize={20} />
          </DataGrid>
        )}
      </div>
    )
  );
};

export default DataGridComposeMessage;
