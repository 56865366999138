import React, { useState } from 'react';
import { MenuItem, ListItemIcon, IconButton, Menu, Typography, Box } from '@mui/material';
import { Sort as SortIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';


const ITEM_HEIGHT = 48;
const options = [{ label: 'Edit', icon: <EditIcon /> },
// { label: 'Add', icon: <AddIcon /> },
{ label: 'Copy', icon: <CopyIcon /> },
{ label: 'Delete', icon: <DeleteIcon /> },

];

const TemplateMenuItem = ({ item, setSelectedTemplate, handleClickOpenModal, deleteTemplate }) => {
  // console.log("hello print",item,);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleMenuItemClick = (action, item) => {

    console.log('Action for template:', action, "Item :", item);

    setSelectedTemplate({ action: action?.label, data: item });


    handleClose();
    switch (action?.label) {
      case 'Edit':
        // Implement edit functionality here
        handleClickOpenModal()
        break;
      // case 'Add':
      //   // Implement edit functionality here
      //   handleClickOpenModal()
      //   break;
      case 'Copy':
        console.log('Copy action for template');
        // Implement copy functionality here            
        break;
      case 'Delete':
        deleteTemplate(item);
        console.log('Delete action for template');
        // Implement delete functionality here
        break;
      default:
        break;
    }
  };

  return (
    <MenuItem className='p-0'>
      <Box className='custom-tabs-box-icon'>
        <DescriptionOutlinedIcon className='font-26 text-blue mb-2' />
      </Box>
      <Box>
        <Typography variant="body1" className='font-12 text-gray text-wrap'>{item.TemplateName}</Typography>
      </Box>
      <Box className='file-details-dropdown'>
        <IconButton
          aria-label="more"
          aria-controls={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size='small'
          className='p-0'
        >
          <MoreVertIcon className='font-18' />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
          className="custom-dropdown"
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option, item)}>
              <span className='me-1'>{option.icon}</span>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </MenuItem>
  );
};

export default TemplateMenuItem;
