import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, ButtonBase, Tooltip, Typography } from "@mui/material";
import React from "react";
import GetFileType from "./FileType";

import { v4 as uuidv4 } from "uuid";
import DounloadFile from "../user/pages/create-request/DownloadFile";
import { useState } from "react";

function FileUpload({ selectedFiles = [], setSelectedFiles,type}) {
  const getFileExtension = (fileName) => {
    // Split the file name by the dot (.)
    const parts = fileName.split(".");
    // Return the last part, which is the extension
    return parts[parts.length - 1];
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const selectedFilesArray = Array.from(files);
    const filesData = [];
    selectedFilesArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = () => {
        let fileByte = reader.result.split(";")[1].replace("base64,", "");
        let strGuid = uuidv4().replace(/-/g, "");
        const fileData = {
          FileName: file.name,
          Base64: fileByte ? fileByte : "", // Base64 data of the file
          FileSize: file.size,
          Preview: reader.result, // Data URL for preview
          DocId: "",
          guid: strGuid,
          FileType: getFileExtension(file.name).toLowerCase(),
        };
        filesData.push(fileData);
        // Check if this is the last file
        if (index === selectedFilesArray.length - 1) {
          // Add new files to the uploadedFiles array
          setSelectedFiles(filesData);
        }
      };
      reader.readAsDataURL(file); // Read file as data URL (base64)
    });
  };

  const RemoveFile = (id) => {
    try {
      let res = selectedFiles.filter((el) => el.guid !== id);
      setSelectedFiles(res);
    } catch (error) {
      console.log("remove file", error);
    }
  };


  const [openView, setOpenView] = React.useState(false);
  const [base64Data, setBase64Data] = useState('');
  const [fileType, setFileType] = useState('');




  const handleClickDownload = (base64, fileType) => {
    try {
      if (base64) {
        // console.log('base 64 and type', fileType, base64);
        setBase64Data(base64);
        if (fileType)
          // Check if the file type is an Excel or Word document
          if (fileType === 'xlsx' ||
            fileType === 'doc' ||
            fileType === 'docx') {
            // Trigger direct download
            let xlx = 'vnd.ms-excel';
            let msword = 'msword';
            let fix = fileType === 'xlsx' ? xlx : msword;
            const link = document.createElement('a');
            link.href = `data:application/${fix};base64,${base64}`;
            link.download = `downloaded_file.${fileType}`; // Set the desired filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            // For other file types, open the dialog
            setOpenView(true);
            setBase64Data(base64);
            setFileType(fileType);
          }

      }
    } catch (error) {
      console.log('Download failed:', error);
    }
  };

  const handleCloseView = () => {
    setOpenView(false);
  };


  return (
    <Box className="">

      <DounloadFile
        open={openView}
        onClose={handleCloseView}
        base64Data={base64Data}
        fileType={fileType}
      />

      <Box className="file-upload text-center mb-3">
      <label htmlFor={`fileUpload-${type}`}>
      <UploadFileIcon className="font-32 text-gray mb-2" />
          <p>
            Drag and drop a file or click or{" "}
            <span style={{ color: "#007bff" }}> click to upload</span>
          </p>
        </label>
        <input
          type="file"
          id={`fileUpload-${type}`}
          onChange={handleFileSelect}
        />   
           </Box>
      {selectedFiles.length > 0 ? (
        <Box
          sx={{
            border: "1px solid #f1f1f1",
            boxShadow: "none",
            // marginTop: "10px",
            // padding: "10px",
            // borderRadius: "4px",
          }}
          className="white-box d-flex align-items-center justify-content-between p-3 mb-2"
        >

          <>
            <Box className="d-flex align-items-center">
              <GetFileType
                Type={selectedFiles.length > 0 ? selectedFiles[0].FileType : ""}
              ></GetFileType>
              <Typography className="ms-1 font-12 poppins-medium">
                {selectedFiles.length > 0 ? selectedFiles[0].FileName : ""}
              </Typography>
            </Box>

            <Box>
              <Tooltip title="Download">
                <ButtonBase className="ms-2 min-width-auto" size="small">
                  <DownloadIcon className="text-blue" onClick={
                    () => handleClickDownload(selectedFiles[0].Base64, selectedFiles[0].FileType)
                  } />
                </ButtonBase>
              </Tooltip>

              <Tooltip
                title="Remove"
                onClick={() => RemoveFile(selectedFiles[0].guid)}
              >
                <ButtonBase className="ms-3  min-width-auto" size="small">
                  <CloseIcon className="text-red" />
                </ButtonBase>
              </Tooltip>
            </Box>
          </>

        </Box>
      ) : null}
    </Box>
  );
}

export default FileUpload;
