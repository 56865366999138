import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import AddIcon from '@mui/icons-material/Add';
import { GetAllFolders_Json_Redux } from "../redux/api_helper";
import { Json_GetSupplierListByProject } from "../redux/dev_chetan_api_helper";
import { setOpenCompanyModal } from "../redux/modalSlice";
import CompanyGrid from "./CompanyGrid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';


export default function Companies({ chgObj, setChgObj }) {
  let objReq = useLocalStorage("accid");
  let dispatch = useDispatch();
  const { clientListByProjectId: getCustomersByClient } = useSelector(
    (state) => state.dev_chetan
  );
  const [getFolder, setGetFolders] = React.useState([]);
  const [filterEnable, setFilterEnable] = React.useState(false);

  const [selectedFolder, setSelectedFolder] = React.useState({
    FolderID: "15",
    Folder: "Client",
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        console.log("✌️res --->", res);
        setGetFolders(res);
        setSelectedFolder(res[0]);
        dispatch(Json_GetSupplierListByProject(res[0]?.FolderID));
      })
    );
  }, [dispatch]);

  const getFolderObject = (event, value) => {
    if (value && value.FolderID) {
      setSelectedFolder(value);
      dispatch(Json_GetSupplierListByProject(value.FolderID));
    }
  };
  const getClientIdMethod = (event, value) => {
    console.log("getClientIdMethod ===", value);
    if (value) {
      const contactObj = {
        agrno: objReq.accid,
        Email: objReq.email,
        password: objReq.password,
        Clientid: value.ClientID,
      };
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredContacts = getCustomersByClient?.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleOpenCompanymodal = () => {
    dispatch(setOpenCompanyModal(true));
  };

  return (
    <>
      <Box className="relative">
        <Box className="search-area">
          <Box className="d-flex align-items-center justify-content-end">
            <Box className="bg-white me-2 custom-autocomplete" >
              <Autocomplete
                value={selectedFolder}
                disablePortal
                id="combo-box-demo"
                options={getFolder || []}
                getOptionLabel={(option) => option.Folder}
                onChange={getFolderObject}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Folder" />
                )}
                style={{ width: '240px' }}
              />
            </Box>

            {/* <Box className="search-input w-100 me-2">
              <SearchIcon />
              <TextField
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                label="Search"
                size="small"
              />
            </Box> */}
            
            <Tooltip title="Create Company" arrow>
              <Button className="min-width-auto" onClick={handleOpenCompanymodal}>
                <AddIcon className="text-black font-18" />
              </Button>
            </Tooltip>
            
            <Tooltip title="Filter" arrow>
              <Button className="min-width-auto" onClick={() => setFilterEnable(!filterEnable)}>
                <FilterAltIcon className="text-black font-18" />
              </Button>
            </Tooltip>

          </Box>
        </Box>
      </Box>
      {/* <hr /> */}
      <Box className="mt-0">
        <CompanyGrid
          dataSourceOptions={filteredContacts}
          selectedFolder={selectedFolder}
          filterEnable={filterEnable}
        />
      </Box>
    </>
  );
}
