import React, { useEffect } from 'react'
import CreateUdf from './CreateUDF'
import CreateCompany from './CreateCompany'
import { useDispatch, useSelector } from 'react-redux';
import { ContectDetails_Redux, Json_Portal_GetClientListByEmail_Redux } from '../redux/ak_api_helper';

function Home() {
  const { UdfNameId } = useSelector((state) => state.ak);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(Json_Portal_GetClientListByEmail_Redux());
  },[]);

  return (
    <div>
      <h1>Home Page</h1>
    </div>
  )
}

export default Home
