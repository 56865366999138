import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Checkbox, FormControlLabel, Grid, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CustomDatePicker from '../components/CustomDatePicker';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import pdf from "../assets/images/files-icon/pdf.png";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenUploadDocument } from '../redux/modalSlice';
import { RequestedDocumentCreated_Json_Redux } from '../redux/api_helper';
import { toast } from 'react-toastify';
import { useLocalStorage } from '../custom-hook/useLocalStorage';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function UploadFileModal({ open }) {
    const [file, setFile] = React.useState(null);
    const dispatch = useDispatch();
    const doc = useSelector((state)=>state.modals.uploadDocumentData);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
  
    //Get data by objects 
    let docDetails = useSelector(
    (state) => state.modals
    );

  
    const handleClose = () => {
        // setOpen(false);
        dispatch(setOpenUploadDocument({ data: [], isOpen: false }))
    };

 const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
   // Send the request
  let objReq = useLocalStorage("accid");
 
     let obj = {
      accId: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      emailID: docDetails?.uploadDocumentData?.EmailId,
      docTempId: docDetails?.uploadDocumentData?.DocTempId,
      docID: docDetails?.uploadDocumentData?.DocId,
    
    };
     
   
    //Upload file documents 
    const handleFileUpload = async () => {
        if (file) {
            // Create a FormData object
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            formData.append('fileExtension', file.name.split('.').pop());

            // Convert file to Base64
            const base64File = await fileToBase64(file);
            formData.append('fileBase64', base64File);

            let newObject = { ...obj, DocExtension: file.name.split('.').pop(), attachment: base64File  }
        //   dispatch(RequestedDocumentCreated_Json_Redux(newObject, function (res)
        //     {
        //       if (res) {
        //             console.log("res4444444444444444444444444444444",res)
        //             // Show success message
        //             toast.success("successfully.");
        //         } else {
        //             // Show error message
        //             toast.error("something wrong.");
        //         }

        //     }))
            // axios.post('YOUR_API_ENDPOINT', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // }).then(response => {
            //     console.log('File uploaded successfully', response.data);
            // }).catch(error => {
            //     console.error('Error uploading file', error);
            // });
        }
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


  const filename = ()=>{

      
    
  }



    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='custom-modal'
            >
                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="clearfix">
                        <DialogTitle>{"File Upload"}</DialogTitle>
                    </Box>

                    {/*  */}
                    <Button onClick={handleClose} autoFocus>
                        <CloseIcon />
                    </Button>
                </Box>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Grid container spacing={3} className="align-items-center">
                            <Grid item lg={6} xs={6} md={6}>
                                <Box
                                    sx={{
                                        border: "1px solid #f1f1f1",
                                        boxShadow: 'none',
                                        // marginTop: "10px",
                                        // padding: "10px",
                                        // borderRadius: "4px",
                                    }}
                                    className='white-box d-flex align-items-center justify-content-between'
                                >

                                    <img src={pdf} style={{ width: 36 }} />
                                    <Typography
                                        sx={{
                                            // color: "#616161",
                                            fontSize: "14px",
                                            fontWeight: "550",
                                            // marginTop: "8px",
                                        }}
                                        className='ms-1'
                                    >
                                        documentsClient Information sheet.pdf
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={6} xs={6} md={6}>


                                <ArrowForwardIosIcon className='font-26' />


                                <Box
                                    sx={{
                                        border: "1px solid #f1f1f1",
                                        boxShadow: 'none',
                                    }}
                                    className='white-box d-flex align-items-center justify-content-between'
                                >
                                    New file
                                    <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                </Box>
                            </Grid>
                        </Grid>

                    </DialogContentText>

                    <DialogActions className='pt-4 px-0'>
                        <Button onClick={handleFileUpload} className='btn-blue' variant="outlined">Save</Button>
                        <Button onClick={handleClose} className='btn-red' autoFocus variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    )
}

export default UploadFileModal

