import { Box, Fab } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import { Json_GetForwardUserList_Redux } from "../redux/api_helper";

export default function Assignee({ ForwardUserList }) {
  let obj = useLocalStorage("required");
  const dispatch = useDispatch();
  // const GetForwardUserList = useSelector((state)=>state.GetForwardUserList);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [GetForwardUserList, setGetForwardUserList] = React.useState();
  const [AddAssignee, setAddAssignee] = React.useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    let o = { ProjectId: 15, SectionId: "-1" };
    let params = { ...obj, ...o };
    dispatch(
      Json_GetForwardUserList_Redux(params, function (res) {
        if (res) {
          // console.log("Json_GetForwardUserList22",res.Table);
          let dt = res.Table;
          if (dt.length > 0) {
            let result = dt.filter((el) => {
              return el.CGroup !== "Yes";
            });

            if (result.length > 0) {
              result.forEach((el) => {
                if (el.ID === parseInt(localStorage.getItem("UserId"))) {
                  setAddAssignee(getTwoLetter(el.ForwardTo));
                }
              });
            }
            setGetForwardUserList(result);
          }
        }
      })
    );
  }, [dispatch]);

  const handleAddAssignee = (newAssignees) => {
    setAnchorEl(null);
    // if (!Array.isArray(newAssignees)) {
    //     console.log('newAssignees should be an array');
    //     return;
    // }

    setAddAssignee(getTwoLetter(newAssignees.ForwardTo));
  };

  function getTwoLetter(str) {
    const words = str.split(" ");
    // Extract the first letter of each word and concatenate them
    let result = "";
    for (let i = 0; i < words.length && i < 2; i++) {
      result += words[i].charAt(0);
    }
    return result;
  }

  return (
    <div>
      <Box className="d-flex align-items-center">
        <Fab
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="primary"
          aria-label="add"
          size="small"
          className="me-3"
        >
          {AddAssignee ? AddAssignee : ""}
        </Fab>
      </Box>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {GetForwardUserList
          ? GetForwardUserList.map((item, index) => {
              return (
                <MenuItem key={index} onClick={() => handleAddAssignee(item)}>
                  {item.ForwardTo}
                </MenuItem>
              );
            })
          : ""}
      </Menu>
    </div>
  );
}
