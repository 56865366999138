import { Box, Button, Pagination, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomePortalMessageTable from "../../../components/CustomePortalMessageTable";
import CustomLoader from "../../../components/CustomLoader";
import NoData from "../../../components/NoData";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import { GetAllReceivedMessages_Json_Redux } from "../../../redux/api_helper";
import AdvanceFilter from "./AdvanceFilter";
import PortalMessagesFilters from "./PortalMessagesFilters";
import DataGridComposeMessage from "../../../components/DataGridComposeMessage";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

function ReceivedMessages({ value }) {
  let dispatch = useDispatch();
  const { getAllReceivedMessages, getAllReceivedMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const [originalData, setOriginalData] = useState([]); // Holds unfiltered data
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filterEnable, setFilterEnable] = useState(false);

  const gridRef = useRef(null);

  const itemsPerPage = 10; // Number of items per page

  let objReq = useLocalStorage("accid");

  let receivedObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    ccode: "",
    emailAddress: "",
  };

  React.useEffect(() => {
    getAllReceivedMessages.length === 0 &&
      dispatch(
        GetAllReceivedMessages_Json_Redux(receivedObj, function (res) {})
      );
  }, [dispatch]);

  // const TableHeder = [
  //   "Subject",
  //   "Sender",
  //   "Issued On ",
  //   "Viewed On",
  //   "Section",
  //   "Reference ID",
  //   "Status",
  // ];

  const TableHeader = [
    { index: 1, tabHeader: "Subject" },
    { index: 2, tabHeader: "Sender" },
    { index: 3, tabHeader: "Issued On" },
    { index: 4, tabHeader: "Viewed On" },
    { index: 5, tabHeader: "Section" },
    { index: 6, tabHeader: "Reference ID" },
    { index: 7, tabHeader: "Status" },
  ];

  // getAllReceivedMessages
  // Create a JSON object from the array using the map method
  const jsonObjectArray = useMemo(() => {
    return getAllReceivedMessages.map((item) => {
      let documentNo = "Not available";
      if (item["Document No"]) {
        documentNo = item["Document No"];
      }

      let objectAll = {
        Subject: item?.Subject ? item.Subject : "Not available",
        Recipient: item["Contact Name"]
          ? item["Contact Name"]
          : "Not available",
        ApprovalRequested: item["To be Approved"]
          ? item["To be Approved"]
          : "Not available",
        IssuedOn: item.Issued
          ? moment(item.Issued).format("DD/MM/yyyy")
          : "Not available",
        DueDate: item.Section ? item.Section : "Not available",
        Viewed: item.Viewed
          ? moment(item.Viewed).format("DD/MM/yyyy")
          : "Not available",
        ReferenceName: item["Client Name"]
          ? item["Client Name"]
          : "Not available",
        MessageNo: item["Message No"] ? item["Message No"] : "Not available",
        History: [
          {
            date: "Reference ID",
            customerId: ": " + item.CCode ? item.CCode : "Not available",
          },
          {
            date: "Date Approved",
            customerId:
              ": " + item["Date Approved"]
                ? moment(item["Date Approved"]).format("DD/MM/yyyy")
                : "Not available",
          },

          {
            date: "Email ID",
            customerId: ": " + item.EMailId ? item.EMailId : "Not available",
          },
          {
            date: "Docusoft ID",
            customerId: ": " + documentNo,
          },
        ],
      };
      return objectAll;
    });
  }, [getAllReceivedMessages]);

  //Add paginations
  useEffect(() => {
    setOriginalData(jsonObjectArray);
    setFilteredData(jsonObjectArray);
  }, [jsonObjectArray]);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Determine the items to display on the current page
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = jsonObjectArray.slice(indexOfFirstItem, indexOfLastItem);
  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filteredData]);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //End Pagination

  const handleChange = (e, value) => {
    setCurrentPage(value);
  };

  const handleClearFilters = () => {
    if (gridRef.current) {
      gridRef.current.instance.clearFilter(); // Clears all filters
    }
  };

  return (
    <>
      <Box className="d-flex align-items-center flex-wrap justify-content-between white-box py-0 pe-0 mb-0 relative mb-2">
        {/* <AdvanceFilter
          setFilteredData={setFilteredData}
          originalData={originalData}
          setCurrentPage={setCurrentPage}
          tabIndex={value}
        /> */}

        <Box className="d-flex flex-wrap search-area" style={{ top: "-42px" }}>
          <PortalMessagesFilters filterType={value} />

          <Tooltip title="Filter" arrow>
            <Button
              className="min-width-auto"
              onClick={() => {
                setFilterEnable(!filterEnable);
                handleClearFilters();
              }}
            >
              <FilterAltIcon className="text-black font-18" />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        {getAllReceivedMessagesLoding ? (
          <>
            <Box>
              <CustomLoader />
            </Box>
          </>
        ) : paginatedData && paginatedData.length > 0 ? (
          <>
            {/* <CustomePortalMessageTable
              TableHeder={TableHeder}
              TableData={paginatedData}
              value={value}
            /> */}
            <DataGridComposeMessage
              TableHeader={TableHeader}
              TableData={originalData}
              value={value}
              filterEnable={filterEnable}
              gridRef={gridRef}
            />
          </>
        ) : paginatedData.length === 0 ? (
          <NoData messsage="No Message here" />
        ) : (
          ""
        )}
      </Box>
      {/* <Box className="mt-2">
        <Pagination
          count={totalPages}
          page={currentPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="custom-pagination"
        />
      </Box> */}
    </>
  );
}

export default ReceivedMessages;
