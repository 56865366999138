import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../../redux/dev_sonam_api_helper";
import { toast } from "react-toastify";
import { clientBodyaccidData } from "../../utils/RequestsFunction";

const FormFillRequest = ({ accordionItem }) => {
  const dispatch = useDispatch();

  const openFileData = (e, accordionItem) => {
    let uploadobj = {
      docTempId: accordionItem?.DocTempId,
      docID: accordionItem?.DocId,
      statusId: "3",
    };
    
    // dispatch(
    //   UpdateRequestedDocStatusAction_Json_ReduxClient(
    //     uploadobj,
    //     function (res) {
    //       console.log(res, "UpdateRequestedDocStatusAction_Json");
    //     }
    //   )
    // );

    if (accordionItem && accordionItem.DocExtension == "docx") {
      window.open(
        `https://formeditor.docuengager.com/SharedDocEditor.aspx?accid=${clientBodyaccidData.accid}&EmailId=${clientBodyaccidData.username}&check=${clientBodyaccidData.sPassword}&User=${accordionItem.Individual}&DocTempId=${accordionItem.DocTempId}&DocId=${accordionItem.DocId}`,
        "_blank"
      );
    }else
    if (accordionItem && accordionItem.DocExtension == "xlsx") {
      window.open(
        `https://formeditor.docuengager.com/SharedExcelEditor.aspx?accid=${clientBodyaccidData.accid}&EmailId=${clientBodyaccidData.username}&check=${clientBodyaccidData.sPassword}&User=${accordionItem.Individual}&DocTempId=${accordionItem.DocTempId}&DocId=${accordionItem.DocId}`,
        "_blank"
      );
    }else{
      toast.error("eligebal for Only docx and xlsx file")
    }
  };

  return (
    <Button
      component="label"
      variant="text"
      startIcon={<MarkEmailReadIcon />}
      className="btn-blue"
      sx={{
        padding: "5px",

        fontSize: "12px",

        marginRight: "10px",
      }}
      onClick={(e) => openFileData(e, accordionItem)}
    >
      Form Filling
    </Button>
  );
};

export default FormFillRequest;
