import ContactPageIcon from "@mui/icons-material/ContactPage";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NoData";
import { GetAllFolders_Json_Redux } from "../../redux/api_helper";
import { getContactsListByFolder } from "../../redux/dev_chetan_api_helper";
import {
  setContactRequestDocCount,
  setMessageReadUnreadCountDetails,
} from "../../redux/dev_chetan_slice";
import { handleOpenContactForClientModal } from "../../redux/docuSlice";
import { setOpenCompanyModal } from "../../redux/modalSlice";

const pageSizes = [10, 25, 50, 100];
const dummyContacts = [
  {
    OriginatorNo: "12345",
    "Company Name": "ABC Corp",
    "First Name": "John",
    "Last Name": "Doe",
    "E-Mail": "john.doe@example.com",
  },

  {
    OriginatorNo: "12345",
    "Company Name": "ABC Corp",
    "First Name": "John",
    "Last Name": "Doe",
    "E-Mail": "john.doe@example.com",
  }

];
export default function ClientContactGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allContacts, allContactsLoding } = useSelector(
    (state) => state.dev_chetan
  );
  const [isLoding, setIsLoding] = useState(false);
  const [view, setView] = React.useState("list");
  const [activeBtn, setActiveBtn] = useState(2);
  const [searchQuery, setSearchQuery] = useState("");
  const [getFolder, setGetFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState();

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
        setSelectedFolder(res[0]);
      })
    );
  }, [dispatch]);

  const getFolderObject = (event, value) => {
    setSelectedFolder(value);
  };

  // Filter contacts based on the search query
  const filteredContacts = allContacts?.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const highlightText = (text) => {
    if (!searchQuery.trim()) return text;

    const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleClick = (clickbutton) => () => {
    setActiveBtn(clickbutton);
  };
  useEffect(() => {
    if (allContacts) {
      setIsLoding(true);
    }
  });
  const [collapsed, setCollapsed] = useState(true);
  const onContentReady = useCallback(
    (e) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const handleRowDoubleClick = (e) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${e.data.OriginatorNo}&contactNo=${e.data.ContactNo}&emailAddress=${e.data["E-Mail"]}`
    );
    dispatch(setMessageReadUnreadCountDetails([]));
    dispatch(setContactRequestDocCount([]));
  };
  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleCardClick = (contact) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${contact.OriginatorNo}&contactNo=${contact.ContactNo}&emailAddress=${contact["E-Mail"]}&folderId=${selectedFolder?.FolderID}`
    );
  };

  React.useEffect(() => {
    if (selectedFolder) {
      dispatch(getContactsListByFolder(selectedFolder));
    }
  }, [selectedFolder]);

  const handleClickOpen = () => {
    dispatch(handleOpenContactForClientModal(true));
  };
  const handleOpenCompanymodal = () => {
    dispatch(setOpenCompanyModal(true));
  };

  return (
    <Box mt={3} className="relative">
      <Box className="search-area">
        <Box className="d-flex align-items-center justify-content-end flex-wrap">

          <Box className="search-input me-2">
            <SearchIcon />
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              label="Search"
              size="small"
            />
          </Box>

          <Button
            variant="outlined"
            className="btn-blue mb-0"
            onClick={handleClickOpen}
            startIcon={<ContactPageIcon />}
          >
            Create Contact
          </Button>

        </Box>
      </Box>
      {activeBtn === 2 && (
        <DataGrid
          dataSource={dummyContacts}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          className="table-grid table-height"
          onRowDblClick={handleRowDoubleClick}
          width="100%"
        >
          {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
          <Column
            dataField="OriginatorNo"
            caption="Originator No"
            dataType="string"
          />
          <Column
            dataField="Company Name"
            caption="Company Name"
            dataType="string"
          />
          <Column
            dataField="First Name"
            caption="First Name"
            dataType="string"
          />
          <Column
            dataField="Last Name"
            caption="Last Name"
            dataType="string"
          />
          <Column dataField="E-Mail" caption="E Mail" dataType="string" />
          <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
          <Paging defaultPageSize={10} />
        </DataGrid>
      )}
    </Box>
  );
}
