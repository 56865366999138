import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Height } from '@mui/icons-material';
import { Json_ToGetSurveyDetails } from '../../redux/dev_chetan_api_helper';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoader from '../../components/CustomLoader';
import DataNotFound from '../../components/DataNotFound';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
    createData('15/01/2025', 'Accounts preparation checklist', 'Yes', '',),
];

function AdditionalInformation() {

    const dispatch = useDispatch();

    const {surveyDetailsList, isSurveyDetailsListLoading} = useSelector(state => state.dev_chetan.additionalInformation);

    const [currentSurvey, setCurrentSurvey] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(()=>{
        if(isSurveyDetailsListLoading){
            dispatch(Json_ToGetSurveyDetails(sessionStorage.getItem("OnboardingGUIDForSurveys")));
        }
    },[]);

    return (
        <Box className='pt-2'>
            {isSurveyDetailsListLoading? <CustomLoader/> : (!isSurveyDetailsListLoading && surveyDetailsList.length===0) ? <DataNotFound/> : <TableContainer component={Paper} className="mt-2 table-bg">
                <Table size="small"
                    className="table custom-table table-mobile"
                    stickyHeader
                    aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell >Survey</TableCell>
                            {/* <TableCell>Status</TableCell> */}
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {surveyDetailsList.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row" data-th='Date'>
                                    {row?.sendDate ? row?.sendDate : "Not Available"}
                                </TableCell>
                                <TableCell data-th='Survey'>{row.surveyName}</TableCell>
                                {/* <TableCell  data-th='Status'>
                                    <span className='font-12 badge text-bg-success '>{row.fat}</span>
                                </TableCell> */}
                                <TableCell  data-th='Action'>
                                    <Button
                                        className="btn-blue me-2"
                                        startIcon={<LibraryBooksOutlinedIcon />}
                                        size='small'
                                        onClick={()=>{
                                            setCurrentSurvey(row.surveyLink);
                                            handleClickOpen();
                                        }}
                                    >
                                        <span className="d-md-block d-none">
                                            Fill
                                        </span>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}


            {/*  */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='custom-modal'
                maxWidth={'lg'}
            >

                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="clearfix">
                        <DialogTitle>{"Additional Settings"}</DialogTitle>
                    </Box>

                    {/*  */}
                    <Button onClick={handleClose} autoFocus>
                        <CloseIcon />
                    </Button>
                </Box>

                <DialogContent>
                    <DialogContentText className='modal-dialog-scrollable'>
                        <iframe src={currentSurvey} title="W3Schools Free Online Web Tutorials" className='w-100' style={{height: 'calc(100vh - 200px)'}}></iframe>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>

        </Box>
    )
}

export default AdditionalInformation