import React from "react";
import { Route, Routes } from "react-router-dom";
import CamposeMessage from "../client/pages/CamposeMessage";
import ClientDashboard from "../client/pages/ClientDashboard";
import ClientHome from "../client/pages/ClientHome";
import ClientLogin from "../client/pages/ClientLogin";
import ClientLogout from "../client/pages/ClientLogout";
import ClientProfile from "../client/pages/ClientProfile";
import ClientSetting from "../client/pages/ClientSetting";
import ClientSharebox from "../client/pages/ClientSharebox";
import DirectMessageViewer from "../client/pages/DirectMessageViewer.jsx";
import DocuRequest from "../client/pages/DocuRequest";
import IncomngMessageViewer from "../client/pages/IncomngMessageViewer";
import NewRequest from "../client/pages/NewRequest.jsx";
import PortalInbox from "../client/pages/PortalInbox";
import PortalSendinbox from "../client/pages/PortalSendinbox";
import PageNotFound from "../components/PageNotFound";
import PreviewNewTAb from "../components/PreviewNewTab";
import SideBar from "../components/SidebarNav";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Logout from "../pages/Logout";
import Settings from "../pages/Settings";
import UploadForm from "../pages/UploadForm";
import ClientDetails from "../pages/clientDetails/ClientDetails.jsx";
import Login from "../pages/login";
import AllRequests from "../user/pages/AllRequests";
import ContactDetails from "../user/pages/ContactDetails";
import CreateInvite from "../user/pages/CreateInvite";
import CreateRequest from "../user/pages/CreateRequest";
import Setting from "../user/pages/Setting/Setting";
import ShareBox from "../user/pages/ShareBox";
import CreateTemplate from "../user/pages/Templates/CreateTemplate";
import Templates from "../user/pages/Templates/Templates";
import CreateNewRequest from "../user/pages/create-request/create-new-request";
import AllInvites from "../user/pages/invites/AllInvites";
import PortalMessages from "../user/pages/portal-messages/PortalMessages";
import UserNewPortalMesage from "../user/pages/portal-messages/UserNewPortalMesage";
import InitialRoute from "./InitialRoute";
import ProtectedRoute from "./ProtectedRoute";
import NewProfileSetup from "../client/pages/NewProfileSetup.jsx";
import Proposal from "../client/pages/Proposal.jsx";
import AdditionalInformation from "../client/pages/AdditionalInformation.jsx";
import Onboarding from "../client/pages/Onboarding.jsx";


function CustomRoutes() {
  
  return (
    <Routes>
      <Route path="/" element={<InitialRoute />} />
      <Route path="/user" element={<ProtectedRoute Cmp={<SideBar />} />}>
        <Route index element={<Home />} />

        {/* calandly redirectn start */}
           
        {/* <Route path="redirect-calandly" element={<RedirectCalanly />} /> */}

        {/* calandly redirectn end */}
        <Route path="dashboard" element={<ClientDashboard />} />
        <Route path="portal-messages" element={<PortalMessages />} />
        <Route path="new-portal-messages" element={<UserNewPortalMesage />} />
        <Route path="user-setting" element={<Setting />} />
        <Route path="all-invites" element={<AllInvites />} />
        <Route path="all-requests" element={<AllRequests />} />
        <Route path="templates" element={<Templates />} />
        <Route path="templates/create-templates" element={<CreateTemplate />} />
        <Route path="about" element={<About />} />
        <Route path="priview" element={<PreviewNewTAb></PreviewNewTAb>} />
        <Route path="contact">
          <Route
            path="contacts"
            element={<Contact pathurl="Contacts" tab="1" />}
          />
          <Route path="contacts/contact-details" element={<ContactDetails />} />
          <Route
            path="companies"
            element={<Contact pathurl="Companies" tab="2" />}
          />
        </Route>
        <Route path="client-details" element={<ClientDetails />} />
        <Route path="Invite" element={<CreateInvite />} />
        <Route path="invite" element={<CreateInvite />} />
        <Route path="create-request" element={<CreateRequest />} />
        <Route path="create-new-request" element={<CreateNewRequest />} />
        <Route path="portalmessage/inboxes/incoming-message-viewer" element={<IncomngMessageViewer />} />
        <Route path="share-box" element={<ShareBox />} />
        <Route path="logout" element={<Logout />} />
        <Route path="settings" element={<Settings />}></Route>
        <Route path="portalmessage">
          <Route path="sent/outgoing-message-viewer" element={<IncomngMessageViewer />} />          
        </Route>
      </Route>
      <Route path="client/d-message-viewer" element={<DirectMessageViewer />} />

      <Route path="/client" element={<ProtectedRoute Cmp={<SideBar />} />}>
        <Route index element={<ClientHome />} />
        {/* <Route  element={<DocuRequest />} /> */}
        <Route path="clientdashboard" element={<ClientDashboard />} />
        <Route path="client-Profile" element={<ClientProfile />} />
        <Route path="Client_Setting" element={<ClientSetting/>} />
        <Route path="portalmessage">
          {/* <Route path="inboxes" element={<PortalInbox />} /> */}
          <Route path="inboxes/campose-message" element={<CamposeMessage />} />
          <Route path="inboxes/incoming-message-viewer" element={<IncomngMessageViewer />} />
          <Route path="inboxes" element={<PortalInbox />} />
          <Route path="sendinboxes" element={<PortalSendinbox />} />
        </Route>
        <Route path="request-detail" element={<DocuRequest />}/>
        <Route path="requests" element={<NewRequest />}/>
        <Route path="clientsharebox" element={<ClientSharebox />} />
        <Route path="CamposeMessage" element={<CamposeMessage />} />
        <Route path="profileSetup" element={<NewProfileSetup />} />
        <Route path="proposal" element={<Proposal />} />
        <Route path="additional-information" element={<AdditionalInformation />} />
        <Route path="onboarding" element={<Onboarding />} />
        
        <Route path="logout" element={<ClientLogout />} />
      </Route>

      <Route path="/user/Login" element={<Login />} />
      <Route path="/client/ClientLogin" element={<ClientLogin />} />
      <Route path="/*" element={<PageNotFound />} />
      <Route path="/uploadForm" element={<UploadForm />} />
    </Routes>
  );
}

export default CustomRoutes;
