import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EmailIcon from "@mui/icons-material/Email";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Box, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../components/AutoComplete";
import CustomLoader from "../components/CustomLoader";
import NoData from "../components/NoData";
import { GetAllFolders_Json_Redux } from "../redux/api_helper";
import { getContactsListByFolder } from "../redux/dev_chetan_api_helper";
import {
  setContactRequestDocCount,
  setMessageReadUnreadCountDetails,
} from "../redux/dev_chetan_slice";
import { handleOpenContactModal } from "../redux/docuSlice";
import { setOpenCompanyModal } from "../redux/modalSlice";

const pageSizes = [20, 50, 100];

export default function ContactGrid({ dataSourceOptions, pathurl }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allContacts, allContactsLoding } = useSelector(
    (state) => state.dev_chetan
  );
  const [isLoding, setIsLoding] = useState(false);
  const [view, setView] = React.useState("list");
  const [activeBtn, setActiveBtn] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [getFolder, setGetFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState();

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
        setSelectedFolder(res[0]);
      })
    );
  }, [dispatch]);

  const getFolderObject = (event, value) => {
    setSelectedFolder(value);
  };

  // Filter contacts based on the search query
  const filteredContacts = allContacts?.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const highlightText = (text) => {
    if (!searchQuery.trim()) return text;

    const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleClick = (clickbutton) => () => {
    setActiveBtn(clickbutton);
  };
  useEffect(() => {
    if (allContacts) {
      setIsLoding(true);
    }
  });
  const [collapsed, setCollapsed] = useState(true);
  const onContentReady = useCallback(
    (e) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const handleRowDoubleClick = (e) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${e.data.OriginatorNo}&contactNo=${e.data.ContactNo}&emailAddress=${e.data["E-Mail"]}`
    );
    dispatch(setMessageReadUnreadCountDetails([]));
    dispatch(setContactRequestDocCount([]));
  };
  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleCardClick = (contact) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${contact.OriginatorNo}&contactNo=${contact.ContactNo}&emailAddress=${contact["E-Mail"]}&folderId=${selectedFolder?.FolderID}`
    );
  };

  React.useEffect(() => {
    if (selectedFolder) {
      dispatch(getContactsListByFolder(selectedFolder));
    }
  }, [selectedFolder]);

  const handleClickOpen = () => {
    dispatch(handleOpenContactModal(true));
  };
  const handleOpenCompanymodal = () => {
    dispatch(setOpenCompanyModal(true));
  };

  return (
    <Box className="relative">
      <Box className="search-area">
        <Box className="d-flex align-items-center justify-content-end flex-wrap">
          <Box className="bg-white me-2 custom-autocomplete">
            <AutoComplete
              defaultValue={selectedFolder}
              disablePortal
              lebel="Folder"
              target="Folder"
              id="combo-box-demo"
              ComData={getFolder}
              getOptionLabel={(option) => option.Folder}
              comFun={getFolderObject}
              // sx="small"
              sx={{ minWidth: '170px' }}
              renderInput={(params) => <TextField {...params} label="Folder" />}
              className="form-control mb-3"
            />
          </Box>

          <Box className="search-input me-2">
            <SearchIcon />
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              label="Search"
              size="small"
            />
          </Box>

          <Button
            variant="outlined"
            className="btn-blue mb-0"
            onClick={handleClickOpen}
            startIcon={<ContactPageIcon />}
          >
            Create Contact
          </Button>
          {allContacts && allContacts.length > 0 && (
            <Box className="text-end relative">
              <ToggleButtonGroup
                // orientation="vertical"
                value={view}
                exclusive
                onChange={handleChange}
                className="table-toggle-btn d-flex ms-2"
              >
                <ToggleButton
                  value="list"
                  aria-label="list"
                  onClick={handleClick(1)}
                >
                   <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton
                  value="module"
                  aria-label="module"
                  onClick={handleClick(2)}
                >
                  <ViewListIcon />                 
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
          {/* {pathurl === "Contacts" ? (
                ""
              ) : (
                <Button
                  variant="outlined"
                  className="btn-blue"
                  onClick={handleOpenCompanymodal}
                  startIcon={<CreateIcon />}
                >
                  Create Company 
                </Button>
              )} */}
        </Box>
      </Box>

      {allContactsLoding ? (
        <>
          <CustomLoader />
        </>
      ) : allContacts && allContacts.length > 0 ? (
        <>
          {activeBtn === 1 && (
            <>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #7a7a7a",
                  // height: "50px",
                  background: "#fff",
                  borderRadius: "5px",
                  width: "30%",
                  marginBottom: "10px",
                }}
              >
                <InputBase
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="small"
                  sx={{
                    flex: 1,
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
              </Box> */}

              {/* <Grid container rowSpacing={0} columnSpacing={2}> */}

              <Box className="row gx-2 contact-main-box">
                {filteredContacts.length > 0 ? (
                  filteredContacts.map((contact, index) => (
                    <Box
                      item
                      // xs={12}
                      // sm={12}
                      // md={6}
                      // lg={6}
                      // xl={4}
                      // xxl={4}
                      className="col-xxl-3 col-xl-4 col-md-6 col-sm-12 d-flex"
                      key={index}
                    >
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                        }}
                        className="contact-box"
                        // onClick={() => handleCardClick(contact)}
                        onDoubleClick={() => handleCardClick(contact)}
                      >
                        <ul className="contact-list" style={{ width: "100%" }}>
                          <li>
                            <span className="poppins-semibold pe-2">
                              <FormatListNumberedIcon /> Originator Number:{" "}
                            </span>
                            <span className='text-gray'>{highlightText(contact.OriginatorNo)}</span>
                          </li>
                          <li>
                            <span className="poppins-semibold pe-2">
                              <ApartmentIcon /> Company Name:
                            </span>
                            <span className='text-gray'>
                              {" "}
                              {highlightText(contact["Company Name"])}
                            </span>
                          </li>
                          <li>
                            <span className="poppins-semibold pe-2">
                              <PersonIcon /> First Name:
                            </span>
                            <span className='text-gray'> {highlightText(contact["First Name"])}</span>
                          </li>
                          <li>
                            <span className="poppins-semibold pe-2">
                              <PersonIcon /> Last Name:
                            </span>
                            <span className='text-gray'> {highlightText(contact["Last Name"])}</span>
                          </li>
                          <li>
                            <span className="poppins-semibold pe-2">
                              <EmailIcon /> Email:
                            </span>
                            <span className='text-gray'>{highlightText(contact["E-Mail"])}</span>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <div>No Data Found</div>
                  </Grid>
                )}
              </Box>
            </>
          )}

          {activeBtn === 2 && (
            <DataGrid
              dataSource={filteredContacts}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
              className="table-grid table-height"
              onRowDblClick={handleRowDoubleClick}
              width="100%"
              // onContentReady={onContentReady}
            >
              {/* <GroupPanel visible={true} /> */}

              {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
              
              {/* <Grouping autoExpandAll={false} /> */}
              <Column
                dataField="OriginatorNo"
                caption="Originator No"
                dataType="string"
              />
              <Column
                dataField="Company Name"
                caption="Company Name"
                dataType="string"
              />
              <Column
                dataField="First Name"
                caption="First Name"
                dataType="string"
              />
              <Column
                dataField="Last Name"
                caption="Last Name"
                dataType="string"
              />
              <Column dataField="E-Mail" caption="E Mail" dataType="string" />
              <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
              <Paging defaultPageSize={20} />
            </DataGrid>
          )}
        </>
      ) : (
        <>
          <NoData message={"No Data Here"} />
        </>
      )}
    </Box>
  );
}
