import { useLocation } from 'react-router-dom';
import { useCustomerId } from '../redux/get_email_form_url';

export const useLocalStorage = (req) => {
    const cMail = useCustomerId();

    //console.log("heloo....",cMail)

    const agrno = localStorage.getItem('agrno');
    const accid = localStorage.getItem('accid');
    const accId = localStorage.getItem('accid');
    const password = localStorage.getItem('password');
    const FolderId = localStorage.getItem('FolderId');
    const Email = localStorage.getItem('Email');
    const email = localStorage.getItem('Email');
    const ProjectId = localStorage.getItem('ProjectId');
    const ViewerToken = localStorage.getItem('ViewerToken');
    const OnboardingGUIDForSurveys = sessionStorage.getItem('OnboardingGUIDForSurveys');

    const EmailId = cMail; // You could replace this with localStorage.getItem('EmailId') if necessary

    const EmailClient = localStorage.getItem('EmailClient')
    const LoginClient = localStorage.getItem('LoginClient')
    const accIdClient = localStorage.getItem('accIdClient')
    const passwordClient = localStorage.getItem('passwordClient')
    if (req === 'client') return { EmailClient, accIdClient, passwordClient }
    if (req === 'required') return { agrno, password, Email };
    if (req === "accid") return { accid, password, email, EmailId };
    if (req === "accId") return { accId, password, email, EmailId };

    return {
        agrno,
        password,
        FolderId,
        Email,
        ProjectId,
        ViewerToken,
        EmailId,
        OnboardingGUIDForSurveys
    };
};
