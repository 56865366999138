import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AllCountriesList } from '../custom-hook/AllCountriesList';

export default function CountryDropdown() {
  return (
    <Box className="custom-autocomplete">
      <Autocomplete
        id="country-select-demo"
        sx={{ width: 300 }}
        options={AllCountriesList}
        autoHighlight
        getOptionLabel={(option) => option.phone}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a country"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            size='small'
          />
        )}
      />
    </Box>
  );
}
