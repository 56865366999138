import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomDatePicker from "../components/CustomDatePicker";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import {
  ApproveRequestedDocument_Json_Redux,
  DisapproveRequestedDocument_Json_Redux,
  fetchdocuRequestData,
} from "../redux/api_helper";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../redux/dev_sonam_api_helper";
import { setOpenApproveModal } from "../redux/modalSlice";

function ApproveDateModal({ open, location }) {
  // const searchParams = new URLSearchParams(location.search);
  // const customerId = searchParams.get("customerId");
  // const EmailID = searchParams.get("EmailID");
  // const location = useLocation();

  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (location && location?.state) {
      setCustomerId(location?.state?.customerId);
    }
  }, [location]);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  // State to manage the checkbox and input value
  const [isChecked, setIsChecked] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [notesText, setNotesText] = React.useState("");

  // const [open, setOpen] = React.useState(false);
  let approvalData = useSelector((state) => state.modals);

  let type = approvalData.type;
  let data = approvalData.approveData;
  let id = approvalData.id;
  let objReq = useLocalStorage("accid");
  let newObject = {
    ...objReq,
    accId: objReq.accid,
    username: objReq.EmailId,
    sPassword: id,
    filterBy: "All",
  };
  delete newObject.accid;

  const userBody = {
    accId: localStorage.getItem("accid"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
    emailid: customerId,
  };

  const handleClose = () => {
    // setOpen(false);
    dispatch(setOpenApproveModal({ data: [], isOpen: false }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  // Handle input change
  const handleDateChange = (e, value) => {
    setInputValue(dayjs(e).format("YYYY/MM/DD"));
  };

  //Set notes for disapproved
  const handleNotesChange = (e) => {
    setNotesText(e.target.value);
  };

  const handleClick = (type, data) => {
    setLoading(true);
    if (type === "a") {
      let expiryDate = isChecked ? inputValue : "";
      let newObjectReq = {
        ...objReq,
        accId: objReq.accid,
        emailid: data.EmailId,
        templateID: data.DocTempId,
        docid: data.DocId,
        expiry: expiryDate,
      };
      delete newObjectReq.accid;
      delete newObjectReq.EmailId;
      dispatch(
        ApproveRequestedDocument_Json_Redux(newObjectReq, function (res) {
          if (res) {
            //Approved : Updated Status for request docu status
            let updateDocuObject = {
              ...userBody,
              sPassword: "0",
              docTempId: data.DocTempId,
              docID: data.DocId,
              statusId: approvalData?.type == "a" ? 4 : 5,
            };
            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                updateDocuObject,
                function (response) {
                  // Show success message
                  setLoading(false);
                  //Refresh the data that updated from status
                  dispatch(fetchdocuRequestData(customerId));
                  dispatch(setOpenApproveModal({ data: [], isOpen: false }));

                  toast.success("Document approved.");
                  setIsChecked(false);
                }
              )
            );
          } else {
            // Show error message
            setLoading(false);
            toast.error("Docx not approved due to something wrong.");
          }
        })
      );
    }
    if (type === "d") {
      let notesData = notesText ? notesText : "";
      let newObjectReq = {
        ...objReq,
        accId: objReq.accid,
        emailid: data.EmailId,
        templateID: data.DocTempId,
        docid: data.DocId,
        note: notesData,
      };
      delete newObjectReq.accid;

      dispatch(
        DisapproveRequestedDocument_Json_Redux(newObjectReq, function (res) {
          if (res) {
            //Approved : Updated Status for request docu status
            let updateDocuObject = {
              ...userBody,
              sPassword: "0",
              docTempId: data.DocTempId,
              docID: data.DocId,
              statusId: 5,
            };
            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                updateDocuObject,
                function (response) {
                  setLoading(false);
                  //Refresh the data that updated from status
                  dispatch(fetchdocuRequestData(customerId));
                  dispatch(setOpenApproveModal({ data: [], isOpen: false }));

                  toast.success("Docx has been disapprove successfully.");
                }
              )
            );
            // Show success message
          }
          // if (res) {
          //  //Dispproved : Updated Status for request docu status
          //  let updateDocuObject = {  accId: objReq.accid, email : objReq.email,password:objReq.password, emailid: data.EmailId,sPassword:'0', docTempId: data.DocTempId, docID: data.DocId,statusId:5}
          //  dispatch(UpdateRequestedDocStatusAction_Json_Requested(updateDocuObject, function (response)
          //  { console.log("Status Updated for Disapproved Requested : " , response)}));
          //     // Show success message
          //     setLoading(false);
          //     dispatch(PortalUsersRequestedDocs2_Json_Redux(newObject, function (resp)
          //     { }));
          //     dispatch(setOpenApproveModal({ data: [], isOpen: false }));
          //     toast.success("Docx has been disapprove successfully.");

          // }
          else {
            // Show error message
            setLoading(false);
            toast.error("Docx not disapprove due to something wrong.");
          }
        })
      );
    }
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Expiry Date Selection"}</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {loading ? "Loading..." : ""}

            {approvalData?.type && approvalData?.type == "a" ? (
              <Grid container spacing={3} className="align-items-end">
                {isChecked && (
                  <Grid item lg={5} xs={5} md={5}>
                    <Box className="mb-3">
                      <Typography variant="body2" className="mb-1">
                        Enter Expiry Date
                      </Typography>
                      <CustomDatePicker
                        value={inputValue}
                        onChange={(e, value) => handleDateChange(e, value)}
                        comFun={handleDateChange}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item lg={7} xs={7} md={7}>
                  {/* <Typography variant='body2' className='mb-1'>Set document expiry date</Typography> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Set document expiry date"
                    className="mb-2 pb-1"
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {approvalData?.type && approvalData?.type == "d" ? (
              <Box className="mb-4 w-100">
                <Typography variant="body2" className="mb-1">
                  What is the reason for disapproving this document?
                </Typography>
                <textarea className="textarea" onChange={handleNotesChange} />
              </Box>
            ) : (
              ""
            )}
          </DialogContentText>

          <DialogActions className="pt-4 px-0">
            <Button
              onClick={() => handleClick(type, data)}
              className="btn-blue"
              variant="outlined"
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              className="btn-red"
              autoFocus
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ApproveDateModal;
