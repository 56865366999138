import { Box, Checkbox, FormControlLabel, Grid, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUpload from './FileUpload';
import { portalUser } from '../redux/AxiosInstance';
import { useLocalStorage } from '../custom-hook/useLocalStorage';
import { toast } from 'react-toastify';

function AMLCheck() {
    const { accIdClient, EmailClient, passwordClient } = useLocalStorage('client')
    const { OnboardingGUIDForSurveys } = useLocalStorage()
    const [expanded, setExpanded] = useState(false);
    const [selectBankFront, setSelectedBankFront] = useState([]);
    const [selectBankBack, setSelectedBankBack] = useState([]);
    const [selectPassportFront, setSelectedPassportFront] = useState([]);
    const [selectPassportBack, setSelectedPassportBack] = useState([]);
    const [selectDriveLicenceFront, setSelectedDriveLicenceFront] = useState([]);
    const [selectDriveLicenceBack, setSelectedDriveLicenceBack] = useState([]);
    const [selectNINOFront, setSelectedNINOFront] = useState([]);
    const [selectNINOBack, setSelectedNINOBack] = useState([]);
    const [formFilled, setFormFilled] = useState({
        bankAccount: '',
        bankSrCode: '',
        passportNumber: '',
        driveLic: '',
        niNumber: '',
    });
    const [isBankAccountChecked, setIsBankAccountChecked] = useState(true);
    const [isPassportChecked, setIsPassportChecked] = useState(true);
    const [isDriveLicenceChecked, setIsDriveLicenceChecked] = useState(true);
    const [isNINOChecked, setIsNINOChecked] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const regexPatterns = {
        bankAccount: /^[0-9]{10,15}$/,
        bankSrCode: /^[A-Za-z0-9]{5,10}$/,
        passportNumber: /^[A-Za-z0-9]{6,9}$/,
        driveLic: /^[A-Za-z0-9]{5,15}$/,
        niNumber: /^[A-Za-z]{2}[0-9]{6}[A-Za-z]{1}$/,
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleTextChange = (e, type) => {
        const { value } = e.target;
        setFormFilled((prev) => ({
            ...prev,
            [type]: value,
        }));
    };
    const validateInput = (type) => {
        let isValid = false;
        switch (type) {
            case 'bankAccount':
                isValid = regexPatterns.bankAccount.test(formFilled.bankAccount);
                break;
            case 'bankSrCode':
                isValid = regexPatterns.bankSrCode.test(formFilled.bankSrCode);
                break;
            case 'passportNumber':
                isValid = regexPatterns.passportNumber.test(formFilled.passportNumber);
                break;
            case 'driveLic':
                isValid = regexPatterns.driveLic.test(formFilled.driveLic);
                break;
            case 'niNumber':
                isValid = regexPatterns.niNumber.test(formFilled.niNumber);
                break;
            default:
                break;
        }

        return isValid;
    };
    const handleSubmit = async () => {
        if (formFilled.bankAccount) {
            if (!formFilled.bankSrCode) {
                toast.error('Bank SR Code is required.');
                return;
            }
        }
        const filledCount = Object.values(formFilled).filter(Boolean).length;
        if (filledCount < 1) return toast.error('Please fill at least 1 field.');
        try {
            setButtonLoading(true)
            const filteredPayload = {
                Accid: accIdClient,
                username: EmailClient,
                sPassword: passwordClient,
                Contactemail: EmailClient,
                bankAccount: formFilled.bankAccount,
                bankSrCode: formFilled.bankSrCode,
                passport: formFilled.passportNumber,
                niNumber: formFilled.niNumber,
                driveLic: formFilled.driveLic
            };
            const payload = constructPayload();
            const DocPayload = {
                accId: accIdClient,
                username: EmailClient,
                sPassword: passwordClient,
                OnboardingGUID: OnboardingGUIDForSurveys,
                AMLContactID: JSON.stringify(payload),
                CurrentStage: "",
                OnboardingCompletedOn: "",
                ProposalDocumentID: "",
                ProposalTaskID: "",
                EngagementDocumentID: "",
                EngagementTaskID: "",
                Status: "",
                SurveyDetails: "",
                RequestedDocumentID: "",
                ProjectID: "",
                InvoiceDetails: "",
                ContactNo: 0,
                FormLink: "",
            }
            const response = await portalUser.post('UpdatedPortalContactVerification_Json', filteredPayload);
            const response2 = await portalUser.post('Update_OnboardingDetailsByGUID_Json', DocPayload);

            if (response.data.d == 'True' && response2.data.d == "Success") {
                toast.success('AML check submitted successfully.');
                setFormFilled({
                    bankAccount: '',
                    bankSrCode: '',
                    passportNumber: '',
                    driveLic: '',
                    niNumber: '',
                })
                setSelectedBankFront([])
                setSelectedBankBack([])
                setSelectedPassportFront([])
                setSelectedPassportBack([])
                setSelectedDriveLicenceFront([])
                setSelectedDriveLicenceBack([])
                setSelectedNINOFront([])
                setSelectedNINOBack([])
            } else {
                toast.error('Failed to submit AML check.');
            }
        } catch (error) {
            toast.error('Failed to submit AML check.');
        } finally { setButtonLoading(false) }
    };
    let isBankReadyForVerification = selectBankFront.length > 0 && validateInput('bankAccount') && validateInput('bankSrCode');
    let isPassportReadyForVerification = selectPassportFront.length > 0 && validateInput('passportNumber');
    let isDriveLicenceReadyForVerification = selectDriveLicenceFront.length > 0 && validateInput('driveLic');
    let isNINOReadyForVerification = selectNINOFront.length > 0 && validateInput('niNumber');
    const isButtonEnabled = isBankReadyForVerification || isPassportReadyForVerification || isDriveLicenceReadyForVerification || isNINOReadyForVerification;
    useEffect(() => {
        setIsBankAccountChecked(selectBankBack.length === 0);
        setIsPassportChecked(selectPassportBack.length === 0);
        setIsDriveLicenceChecked(selectDriveLicenceBack.length === 0);
        setIsNINOChecked(selectNINOBack.length === 0);
    }, [selectBankBack, selectPassportBack, selectDriveLicenceBack, selectNINOBack]);

    const constructPayload = () => {
        const getFileSize = (selectedDocs) => selectedDocs.length > 0 ? selectedDocs[0]?.FileSize : null;
        const bankFrontId = getFileSize(selectBankFront);
        const bankBackId = getFileSize(selectBankBack) || (bankFrontId ? 'N/A' : null);
        const passportFrontId = getFileSize(selectPassportFront);
        const passportBackId = getFileSize(selectPassportBack) || (passportFrontId ? 'N/A' : null);
        const driveLicFrontId = getFileSize(selectDriveLicenceFront);
        const driveLicBackId = getFileSize(selectDriveLicenceBack) || (driveLicFrontId ? 'N/A' : null);
        const niFrontId = getFileSize(selectNINOFront);
        const niBackId = getFileSize(selectNINOBack) || (niFrontId ? 'N/A' : null);
        const objectPayload = {
            "BankAccount": bankFrontId || bankBackId ? { bankFrontId, bankBackId } : null,
            "Passport": passportFrontId || passportBackId ? { passportFrontId, passportBackId } : null,
            "DriveLic": driveLicFrontId || driveLicBackId ? { driveLicFrontId, driveLicBackId } : null,
            "NiNumber": niFrontId || niBackId ? { niFrontId, niBackId } : null
        };
        return objectPayload;
    };
    return (
        <Grid container spacing={2} className="pt-3">
            <Grid item xs={12}>
                <h2 className="title font-18">AML Check</h2>
                <Box className="main-accordian">
                    {/* Bank Account Accordion */}
                    <Accordion className="accordian-box" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Box className="font-12">
                                <FormControlLabel
                                    className='ms-1'
                                    sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                    control={<Checkbox checked={isBankReadyForVerification} size="small" />}
                                    label="Bank Account Number"
                                />
                            </Box>
                            {isBankReadyForVerification && <Typography className="badge text-bg-success">Ready for Verification</Typography>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="form-group mb-2 d-flex gap-2">
                                <TextField
                                    label="Bank Account Number"
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                    value={formFilled.bankAccount}
                                    onChange={(e) => handleTextChange(e, 'bankAccount')}
                                    onBlur={() => validateInput('bankAccount')}
                                    error={formFilled.bankAccount && !validateInput('bankAccount')}
                                    helperText={formFilled.bankAccount && !validateInput('bankAccount') ? 'Invalid Bank Account Number' : ''}
                                />
                                <TextField
                                    label="Bank SR Code"
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                    value={formFilled.bankSrCode}
                                    onChange={(e) => handleTextChange(e, 'bankSrCode')}
                                    onBlur={() => validateInput('bankSrCode')}
                                    error={formFilled.bankSrCode && !validateInput('bankSrCode')}
                                    helperText={formFilled.bankSrCode && !validateInput('bankSrCode') ? 'Invalid Sr Code' : ''}
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Front Side</label>
                                        <FileUpload key="bank-front" type="bank-front" selectedFiles={selectBankFront} setSelectedFiles={setSelectedBankFront} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Back Side</label>
                                        <FormControlLabel
                                            className='ms-1'
                                            sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                            control={<Checkbox checked={isBankAccountChecked} onChange={() => setSelectedBankBack([])} size="small" />}
                                            label="N/A"
                                        />
                                        <FileUpload
                                            key="bank-back"
                                            type="bank-back"
                                            selectedFiles={selectBankBack}
                                            setSelectedFiles={setSelectedBankBack}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* Passport Accordion */}
                    <Accordion className="accordian-box" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                            <Box className="font-12">
                                <FormControlLabel
                                    className='ms-1'
                                    sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                    control={<Checkbox checked={isPassportReadyForVerification} size="small" />}
                                    label="Passport"
                                />
                            </Box>
                            {isPassportReadyForVerification && <Typography className="badge text-bg-success">Ready for Verification</Typography>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="form-group mb-2">
                                <TextField
                                    label="Passport Number"
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                    value={formFilled.passportNumber}
                                    onChange={(e) => handleTextChange(e, 'passportNumber')}
                                    onBlur={() => validateInput('passportNumber')}
                                    error={formFilled.passportNumber && !validateInput('passportNumber')}
                                    helperText={formFilled.passportNumber && !validateInput('passportNumber') ? 'Invalid Passport Number' : ''}
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Front Side</label>
                                        <FileUpload key="passport-front" type="passport-front" selectedFiles={selectPassportFront} setSelectedFiles={setSelectedPassportFront} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Back Side</label>
                                        <FormControlLabel
                                            className='ms-1'
                                            sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                            control={<Checkbox onChange={() => setSelectedPassportBack([])} checked={isPassportChecked} size="small" />}
                                            label="N/A"
                                        />
                                        <FileUpload
                                            key="passport-back"
                                            type="passport-back"
                                            selectedFiles={selectPassportBack}
                                            setSelectedFiles={setSelectedPassportBack}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* Drive License Accordion */}
                    <Accordion className="accordian-box" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                            <Box className="font-12">
                                <FormControlLabel
                                    className='ms-1'
                                    sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                    control={<Checkbox checked={isDriveLicenceReadyForVerification} size="small" />}
                                    label="Drive License"
                                />
                            </Box>
                            {isDriveLicenceReadyForVerification && <Typography className="badge text-bg-success">Ready for Verification</Typography>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="form-group mb-2">
                                <TextField
                                    label="Drive License Number"
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                    value={formFilled.driveLic}
                                    onChange={(e) => handleTextChange(e, 'driveLic')}
                                    onBlur={() => validateInput('driveLic')}
                                    error={formFilled.driveLic && !validateInput('driveLic')}
                                    helperText={formFilled.driveLic && !validateInput('driveLic') ? 'Invalid License Number' : ''}
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Front Side</label>
                                        <FileUpload key="drive-licence-front" type="drive-licence-front" selectedFiles={selectDriveLicenceFront} setSelectedFiles={setSelectedDriveLicenceFront} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Back Side</label>
                                        <FormControlLabel
                                            className="mb-1"
                                            sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px", ml: '2px' }}
                                            control={<Checkbox onChange={() => setSelectedDriveLicenceBack([])} checked={isDriveLicenceChecked} size="small" />}
                                            label="N/A"
                                        />
                                        <FileUpload
                                            key="drive-licence-back"
                                            type="drive-licence-back"
                                            selectedFiles={selectDriveLicenceBack}
                                            setSelectedFiles={setSelectedDriveLicenceBack}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* NINO Accordion */}
                    <Accordion className="accordian-box" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Box className="font-12">
                                <FormControlLabel
                                    className='ms-1'
                                    sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                    control={<Checkbox checked={isNINOReadyForVerification} size="small" />}
                                    label="National Insurance Number"
                                />
                            </Box>
                            {isNINOReadyForVerification && <Typography className="badge text-bg-success">Ready for Verification</Typography>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="form-group mb-2">
                                <TextField
                                    label="National Insurance Number"
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                    value={formFilled.niNumber}
                                    onChange={(e) => handleTextChange(e, 'niNumber')}
                                    onBlur={() => validateInput('niNumber')}
                                    error={formFilled.niNumber && !validateInput('niNumber')}
                                    helperText={formFilled.niNumber && !validateInput('niNumber') ? 'Invalid NINO' : ''}
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Front Side</label>
                                        <FileUpload key="nino-front" type="nino-front" selectedFiles={selectNINOFront} setSelectedFiles={setSelectedNINOFront} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} xxl={6}>
                                    <Box className="form-group">
                                        <label className="font-13 fw-semibold">Upload Back Side</label>
                                        <FormControlLabel
                                            className="ms-1"
                                            sx={{ "& .MuiTypography-root": { fontSize: "14px", fontWeight: 600, }, height: "10px" }}
                                            control={<Checkbox onChange={() => setSelectedNINOBack([])} checked={isNINOChecked} size="small" />}
                                            label="N/A"
                                        />
                                        <FileUpload
                                            key="nino-back"
                                            type="nino-back"
                                            selectedFiles={selectNINOBack}
                                            setSelectedFiles={setSelectedNINOBack}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Button className="btn-blue" sx={{ mt: 2 }} disabled={!isButtonEnabled || buttonLoading} variant="contained" color="primary" onClick={handleSubmit}>
                        {buttonLoading ? "Loading" : "Submit"}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default AMLCheck;
