import { Box, Button, Grid, Menu, MenuItem, Typography, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ContactViewEdit } from '../../components/ContactViewEdit'
import EditUDFClientCard from '../../components/EditUDFClientCard'
import CustomeSignature from '../../components/CustomeSignature';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from 'react-redux';
import {
  clientBodyaccidData,
} from "../../utils/RequestsFunction";
import { ContectDetails_Redux, GetSignature_Json_Redux, Json_Portal_GetClientListByEmail_Redux } from '../../redux/ak_api_helper';
import { error } from 'jquery';
import { GetCRMContactUDFValueAction, GetOnboardingDetailsByMultipleClientIdPubUser } from '../../redux/dev_aditya_action';
import { setOrigantryNumber } from '../../redux/modalSlice';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';


const NewProfileSetup = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const fdgljdflkgj = useSelector(state=> state.dev_chetan.onBoardingDetails);
  const {onBoardingDetailsByClientId} = useSelector(state=> state.dev_chetan.onBoardingDetails);
  console.log("fdgljdflkgj", fdgljdflkgj);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // jayprakash working onbording process Start

  const { signature, signatureLoding } = useSelector((state) => state.ak);
  const { UdfNameId } = useSelector((state) => state.ak);
  const { udf1 } = useSelector((state) => state.ak);

  console.log('response result', udf1)

  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] = useState(true);
  const [selectedOption, setSelectedOption] = useState();
  const navigate = useNavigate();
  const [AllComponyDetailas, setAllComponyyDetailaDropDoun] = useState([])
  const dispatch = useDispatch();
   let profile = 'ProfileSetupPageOnbording'

  console.log('signatureLoding' , signatureLoding)


  let requestObjecGetUdfDetailas = {
    Accid: localStorage.getItem("accIdClient"),
    username: localStorage.getItem("EmailClient"),
    sPassword: localStorage.getItem("passwordClient"),
  }

  useEffect(() => {

    dispatch(GetSignature_Json_Redux(clientBodyaccidData));

    let result = onBoardingDetailsByClientId.filter((item) => {
      return item.Status == 'In-Progress'
    })
    
    if (result.length > 0) {

      setAllComponyyDetailaDropDoun(result)

      setSelectedOption(result[0])

      requestObjecGetUdfDetailas.ClientId = result[0]['CCode']

      dispatch(GetCRMContactUDFValueAction(requestObjecGetUdfDetailas, () => { }));

      dispatch(setOrigantryNumber(result[0]['CCode']))
    }
    else {
      // navigate('/client/portalmessage/inboxes')
    }
  }, [])

  const handleChangeSignature = async () => {
    setSignaturePreview(false);
    setSignatureSetup(true);
  };

  const base64String = signature;
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
  });

  const handleSelect = (event, value) => {
    try {
      if (value) {
        // setselectOrginitoNo(value);
        // dispatch(setOrigantryNumber(value.OriginatorNo));
        console.log('Set Selected Value', value)
        setSelectedOption(value);
        localStorage.setItem('onboardingCCCode', value['CCode'])
        requestObjecGetUdfDetailas.ClientId = value['CCode']
        dispatch(setOrigantryNumber(value['CCode']))
        dispatch(GetCRMContactUDFValueAction(requestObjecGetUdfDetailas, () => { }));
      } else {
        setSelectedOption({ "Company Name": "", OriginatorNo: null });
      }
    } catch (error) {
      console.error("An error occurred in h8andleSelect:", error);
    }
  };



  // jayprakash working onbording procee end

  return (
    <Grid container rowSpacing={0} columnSpacing={2} className='d-flex pt-3'>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        xxl={6}
        className='d-flex mb-3'
      >

        <Box className='card p-3 pb-2 w-100'>

          <Box className="mt-2">
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              size='small'
              className='ps-0 w-100 text-start justify-content-start border-bottom font-16 mb-2 rounded-0 text-black'
            >
              {selectedOption && selectedOption['CName'] || ''} <KeyboardArrowDownOutlinedIcon />
            </Button>
            <Menu
              id="basic-menu"
              className="custom-dropdown"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria -labelledby': 'basic-button',
              }}
            >

              {AllComponyDetailas.map((client) => (
                <MenuItem
                  key={client.CCode}
                  onClick={(event) => {
                    handleSelect(event, client);
                    handleClose();
                  }}
                >
                  {client["CName"]}
                </MenuItem>
              ))}
            </Menu>


            {/* <Button
              sx={{ cursor: "pointer" }}
              onClick={handleMenuClick}
              className="btn-blue"
              endIcon={<KeyboardArrowDownIcon />}
            >
              Company Name
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className="custom-dropdown"
            >
              {UdfNameId.map((client) => (
                <MenuItem
                  key={client.OriginatorNo}
                  onClick={(event) => {
                    handleSelect(event, client);
                    handleMenuClose();
                  }}
                >
                  {client["Company Name"]}
                </MenuItem>
              ))}
            </Menu> */}
          </Box>

          <ContactViewEdit profile={profile} />

        </Box>

      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        xxl={6}
        className='d-flex mb-3'
      >
        <Box className='card p-3 pb-2 w-100'>



        {signaturePreview ? (
  <>
    <Box className="d-flex justify-content-between">
      <Typography variant="h3" className="font-14 bold text-black">
        {"Signature Setting"}
      </Typography>

      <IconButton
        sx={{ marginTop: "-15px" }}
        onClick={handleChangeSignature}
      >
        <EditIcon />
      </IconButton>
    </Box>

    <Box>
      <Box
        sx={{
          border: "1px solid black",
          borderRadius: "10px",
          background: "#fff",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {base64String.length > 0 ? (
          <ImageBox
            component="img"
            src={`data:image/${format};base64,${base64String}`}
            alt="Base64 Image"
            sx={{ width: 300, height: 95 }}
          />
        ) : signatureLoding ? (
          <CircularProgress />
        ) : (
          <Typography>No Signature</Typography>
        )}
      </Box>
    </Box>
  </>
) : (
  <CustomeSignature
    setSignaturePreview={setSignaturePreview}
    setSignatureSetup={setSignatureSetup}
    setSignatureSetupVisibleSetting={setSignatureSetupVisibleSetting}
  />
)}



          {/* <CustomeSignature

            setSignaturePreview={setSignaturePreview}
            setSignatureSetup={setSignatureSetup}

          /> */}


        </Box>

      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        className='d-flex mb-3'
      >
        <Box className='card p-3 pb-2 w-100'>
          {udf1 && udf1.length > 0 && <EditUDFClientCard data={udf1} />}
        </Box>
      </Grid>


    </Grid>

  )
}

export default NewProfileSetup