import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
// import DiscountCell from './DiscountCell.tsx';
import "devextreme/dist/css/dx.light.css";
import { useNavigate } from "react-router-dom";
import CompaniesDetails from "./CompaniesDetails";
import ContactGrid from "./ContactGrid";

const breadcrumbItemsContact = [
  { label: "Connections", href: "/" },
  { label: "Contacts", href: "/material-ui/getting-started/installation/" },
  { label: "People" },
];
const breadcrumbItemsCompany = [
  { label: "Connections", href: "/" },
  { label: "Contacts", href: "/material-ui/getting-started/installation/" },
  { label: "Companies" },
];
function Contact({ pathurl, tab }) {
  let objReq = useLocalStorage("accid");
  let requiredObj = useLocalStorage("required");
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const allContacts = useSelector((state) => state.dev_chetan.allContacts);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(tab);
  const [getContactsList, setGetContactsList] = React.useState([]);

  const [varName, funcName] = useState();

  React.useEffect(() => {
    setValue(tab);
  }, [tab]);

  React.useEffect(() => {
    // setLoading(true);
    // dispatch(GetAllContactsJsonRedux(objReq, function (res) {
    //   setLoading(false);
    //   setGetContactsList(res);
    // }))
  }, [dispatch]);

  // React.useEffect(() => {
  //   if (allContacts.length === 0) {
  //     dispatch(getContactsListByFolder(requiredObj.FolderId));
  //   }
  // }, []);

  const handleChange = (event, newValue) => {
    if (newValue == 2) {
      navigate("/user/Contact/Companies");
    }
    if (newValue == 1) {
      navigate("/user/Contact/Contacts");
    }
    setValue(newValue);
  };
  const { pathname } = window.location;
  const getPageTitle = () => {
    switch (true) {
      case pathname.includes("templates"):
        return "Template List";
      case pathname.includes("portalmessage/sent"):
        return "Portal Sent Messages ";
      case pathname.includes("share-box"):
        return "Share Box";
      case pathname.includes("contacts/contact-details"):
        return "Contact Detail";
      case pathname.includes("contact/contacts"):
        return "Contact List";
      case pathname.includes("contact/companies"):
        return "Company List";
      case pathname.includes("user/client-details"):
        return "Company Details";
      case pathname.includes("incoming-message-viewer"):
        return "Incoming Message";
      case pathname.includes("all-requests"):
        return "All Request";
      default:
        return "Portal Messages";
    }
  };

  return (
    <Box className="clearfix">
      <Box className="row">
        <Box className="col-xxl-12 m-auto">
          <Box className="title mt-2 nowrap d-sm-none d-block font-16">{getPageTitle()}</Box>

          {/* <hr /> */}

          {pathurl === "Contacts" ? (
            <Box className="mt-sm-3 w-100" >
              <Box className="mt-0">
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    className="custom-tabs mb-0"
                  >
                    <Tab label="CONTACTS" value="1" />
                    <Tab label="COMPANIES" value="2" />
                  </TabList>
                  <TabPanel
                    value={value}
                    // className="white-box"
                    // style={{ overflow: "auto" }}
                    className="p-0"
                  >
                    {loading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <ContactGrid
                        dataSourceOptions={allContacts}
                        pathurl={pathurl}
                      />
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          ) : (
            <Box className="w-100" sx={{ width: "100%" }}>
              <Box className="mt-sm-3 mt-0">
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    className="custom-tabs mb-0"
                  >
                    <Tab label="CONTACTS" value="1" />
                    <Tab label="COMPANIES" value="2" />
                  </TabList>
                  <TabPanel value={value} className="white-box p-0">
                    <CompaniesDetails />
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Contact;
