import React from 'react'
import { useDispatch } from 'react-redux';
import { handleOpenContactModal } from '../redux/docuSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import CreateContect from '../pages/CreateContect';
import CreateCompany from '../pages/CreateCompany';
import { setOpenCompanyModal } from '../redux/modalSlice';
import CreateNewComoany from './CreateNewComoany';
// import CreateCompany from '../pages/CreateCompany';

export default function CompanyModal({open}) {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setOpenCompanyModal(false));
    };
  return (
    <div>  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-lg"
        fullWidth={true}
        maxWidth={"lg"}
  >
    <Box className="d-flex align-items-center justify-content-between modal-head">
      <Box className="clearfix">
        <DialogTitle>Create Company </DialogTitle>
      </Box>

      {/*  */}
      <Button onClick={handleClose} autoFocus>
        <CloseIcon />
      </Button>
    </Box>

    <DialogContent>
      <DialogContentText id="alert-dialog-description">
         <CreateNewComoany  handleClose={handleClose}/>
      </DialogContentText>

      {/* <DialogActions className='pt-4 px-0'>
        <Button onClick={handleClose} className='btn-red' variant="outlined">Cancel</Button>
       
        <Button onClick={handleClose} autoFocus variant="outlined" className='btn-blue'>
          Create Company 
        </Button>
      </DialogActions> */}
    </DialogContent>


  </Dialog></div>
  )
}
