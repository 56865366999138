import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { DraftFormDataReducer } from "../../../redux/adityaReducer";
import { sonamConfirmMessage } from "../../../redux/api_helper";
import {
  setComposePortalMgsObj_Redux,
  setPortalRequestDocuments_Redux,
  setRequestDocument_Redux,
} from "../../../redux/docuSlice";
import Attachments from "./Attachments";
import ComposePortalMessage from "./ComposePortalMessage";
import RequestDocuments from "./RequestDocuments";
import Summary from "./Summary";

const stepsData = [
  {
    id: 0,
    label: "Portal Message",
    comp: <ComposePortalMessage />,
    completed: false,
    hidden: false,
  },
  {
    id: 1,
    label: "Attachments",
    comp: <Attachments />,
    completed: false,
    hidden: false,
  },
  {
    id: 2,
    label: "Request Documents",
    comp: <RequestDocuments />,
    completed: false,
    hidden: false,
  },
  {
    id: 3,
    label: "Summary",
    comp: <Summary />,
    completed: false,
    hidden: false,
  },
];

function CreateNewRequest() {
  const dispatch = useDispatch();
  const formDataRef = useRef();

  const [activeStepId, setActiveStepId] = useState(0);
  const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
  const [steps, setSteps] = useState(stepsData);
  const [formData, setFormData] = useState({});

  const redx = useSelector((state) => state.docu);
  const {
    ComposePortalMgsObj,
    PortalRequestDocuments,
    RequestDocumnets,
    AttachmentTab,
  } = useSelector((state) => state.docu);

  const { draftFormData } = useSelector(({ aditya }) => aditya);

  useEffect(() => {
    formDataRef.current = {
      ComposePortalMgsObj,
      PortalRequestDocuments,
      RequestDocumnets,
      AttachmentTab,
    };
  }, [
    ComposePortalMgsObj,
    PortalRequestDocuments,
    RequestDocumnets,
    AttachmentTab,
  ]);

  useEffect(() => {
    handleReset();
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      if (updatedSteps[1]) updatedSteps[1].hidden = redx.NewRequest;
      if (updatedSteps[2]) updatedSteps[2].hidden = redx.NewMessage;
      return updatedSteps;
    });
  }, [redx.NewRequest, redx.NewMessage]);

  useEffect(() => {
    const latestFormData = formDataRef.current; // Use ref to access the latest data
    const cleanup = async () => {
      const text = "Do you want to save the current message as draft?";
      await dispatch(
        sonamConfirmMessage(text, async (confirm) => {
          if (confirm) {
            await dispatch(DraftFormDataReducer(latestFormData));
          }
          // Clear Redux states regardless of confirmation
          dispatch(setRequestDocument_Redux([]));
          dispatch(setPortalRequestDocuments_Redux([]));
          dispatch(setComposePortalMgsObj_Redux([]));
        })
      );
    };

    return () => {
      latestFormData?.ComposePortalMgsObj &&
        Object.keys(latestFormData?.ComposePortalMgsObj).length > 0 &&
        cleanup(); // Call cleanup on unmount
    };
  }, [dispatch]);

  const visibleSteps = steps.filter((step) => !step.hidden);
  const totalSteps = visibleSteps.length;

  const handleNext = (stepId) => {
    if (
      !ComposePortalMgsObj?.Subject ||
      ComposePortalMgsObj?.Subject.length < 1
    ) {
      toast.error("Please enter a subject line.");
      return;
    }

    if (
      !ComposePortalMgsObj?.ToEmail ||
      ComposePortalMgsObj?.ToEmail.length === 0
    ) {
      toast.error("Please select a recipient.");
      return;
    }

    if (
      !ComposePortalMgsObj?.Section ||
      Object.keys(ComposePortalMgsObj?.Section).length === 0
    ) {
      toast.error("Please select a Section.");
      return;
    }

    let newActiveStepId = activeStepId + 1;
    if (newActiveStepId < totalSteps) {
      setActiveStepId(newActiveStepId);
    } else {
      setIsAllStepsCompleted(true);
    }
  };

  const handleBack = () => {
    let newActiveStepId = activeStepId - 1;
    if (newActiveStepId >= 0) {
      setActiveStepId(newActiveStepId);
    }
  };

  const handleStep = (stepId) => () => {
    if (
      !ComposePortalMgsObj?.Subject ||
      ComposePortalMgsObj?.Subject.length < 1
    ) {
      toast.error("Please enter a subject line.");
      return;
    }

    if (
      !ComposePortalMgsObj?.ToEmail ||
      ComposePortalMgsObj?.ToEmail.length === 0
    ) {
      toast.error("Please select a recipient.");
      return;
    }

    if (
      !ComposePortalMgsObj?.Section ||
      Object.keys(ComposePortalMgsObj?.Section).length === 0
    ) {
      toast.error("Please select a Section.");
      return;
    }
    setActiveStepId(stepId);
  };

  const handleReset = () => {
    setActiveStepId(0);
    steps.forEach((step) => {
      step.completed = false;
    });
    setIsAllStepsCompleted(false);
  };

  const currentStep = visibleSteps[activeStepId];

  return (
    <Box className="container-fluid">
      <Box className="row">
        <Box className="col-xxl-12 col-xl-12 mt-2">
          <Box className="white-box">
            <Box className="step-form">
              <Stepper nonLinear activeStep={activeStepId}>
                {visibleSteps.map((step, index) => (
                  <Step key={step.label} completed={step.completed}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {step.label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {isAllStepsCompleted ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {currentStep?.comp}
                    <hr />
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        color="inherit"
                        disabled={activeStepId === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        className={"btn-black"}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStepId === totalSteps - 1 ? (
                        ""
                      ) : (
                        <Button
                          onClick={handleNext}
                          className="btn-blue"
                          endIcon={<EastIcon />}
                        >
                          {activeStepId === totalSteps - 1 ? "Finish" : "Next"}
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateNewRequest;
