import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { setOpenCalandlyInstructionModal, setOpenIntegrationModal } from '../redux/modalSlice';
import { Box, Checkbox, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import AutoComplete from '../components/AutoComplete';
import DocuTextField from '../cmp-utils/DocuTextField';
import { JsonGetCalendlyCredentials, JsonUpdateCalendlyCredentials } from '../redux/ak_api_helper';
import { useNavigate } from 'react-router-dom';
import { event } from 'jquery';
import { toast } from 'react-toastify';
import SendIcon from "@mui/icons-material/Send";

function IntegrationModal({ open }) {

  const [eventVisible, setEventVisible] = useState(false)
  const [clientKey, setCleinetKey] = useState(null)
  const [secretKey, setSecretKey] = useState(null)
  const [dropdounData, setDropdounData] = useState([])
  const [mainUrl, setMainUrl] = useState(null)
  const [sedulingUrl, setSedulingUrl] = useState(null)
  const [eventChackVisble, setEventChackVisible] = useState(false)
  console.log('dropdounData', dropdounData)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // call Get clientKey and secretKey key 


  let obj = {
    agrno: localStorage.getItem('accIdClient'),
    Email: localStorage.getItem('EmailClient'),
    password: localStorage.getItem('passwordClient'),
  }

  useEffect(() => {
    // dispatch(JsonGetCalendlyCredentials(obj))

    setTimeout(() => {

      setCleinetKey(localStorage && localStorage.getItem('CalendlyClientkey'))
      setSecretKey(localStorage && localStorage.getItem('CalendlySecretKey'))

    }, 2000);

  }, [])

  const validateCleintKey = () => {
    // navigate('/user/redirect-calandly')

    try {
      let myUrl = 'http://localhost:3000/user/redirect-calandly'
      let url = `https://auth.calendly.com/oauth/authorize?client_id=${clientKey}&response_type=code&redirect_uri=${myUrl}`;

      localStorage.setItem("RedirectURl", myUrl);
      const windowFeatures = "width=500,height=500";
      window.open(url, "_blank", windowFeatures);

    }
    catch (error) {
      console.log("Network Error" + error);
    }
  }



  function getTokenValidation() {
    const validationStatus = localStorage.getItem("ValidationCalandly");

    if (validationStatus) {
      localStorage.removeItem('ValidationCalandly')
      clearInterval(interval);
      setTimeout(() => {
        getEventList()

      }, 2000);
    }
  }


  function getBaseUrl(url) {

    const urlObj = new URL(url);
    console.log('Url Object', urlObj)
    return `${urlObj.protocol}//${urlObj.hostname}${urlObj.pathname.split('/')[1] ? '/' + urlObj.pathname.split('/')[1] : ''}`;
  }



  const getEventList = () => {



    const requestBody = {
      user: localStorage.getItem("owner") ? localStorage.getItem("owner") : "",
      token: localStorage.getItem("AccessToken")
        ? localStorage.getItem("AccessToken")
        : "",
    };
    fetch("https://digitalpractice.net/calendly/getEventTypes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.removeItem('ValidationCalandly')
        console.log('Data  Recive ', data)
        setEventChackVisible(true)
        let mainData = data.data[0].scheduling_url;
        mainData = getBaseUrl(mainData)
        console.log('jp recive Data', mainData)
        setDropdounData(data.data)
        setMainUrl(mainData)

        interval = setInterval(getTokenValidation, 1000);


        // // mainData = mainData.replace("/projectmeeting", "");
        // UrlObject.url = mainData;
        // console.log("Event List ", mainData);
        // JsonUpdateCalendlyCredentials(1);
        // CreteDropDoun(data.data);
      })
      .catch((error) => console.error("Error:", error));
  }

  let interval = setInterval(getTokenValidation, 1000);


  const handleDropDounData = (event, value) => {
    console.log("handleDropDounData List", event, value.scheduling_url);
    setSedulingUrl(value.scheduling_url)
  }

  const [selectedCheckbox, setSelectedCheckbox] = useState('showAllEvent');

  const handleCheckboxChange = (event) => {

    // setSelectedCheckbox(event.target.name)

    console.log('event.target' , event.target)

    if (selectedCheckbox === event.target.name) {

      console.log('event.target.name', event.target.name)
      setSelectedCheckbox(null);

     

    } else {
      setEventVisible(!eventVisible)
      setSelectedCheckbox(event.target.name);
      console.log('event.target.name jp', event.target.name)

      if(event.target.name == 'showAllEvent' ){
        obj.ClientKey = clientKey;
        obj.SecretKey = secretKey;
        obj.RedirectUrl = mainUrl;
        dispatch(JsonUpdateCalendlyCredentials(obj))
      }


    }
  };

  const hanldeSubmit = () => {

    obj.ClientKey = clientKey;
    obj.SecretKey = secretKey;
    obj.RedirectUrl = sedulingUrl;

    const result = dispatch(JsonUpdateCalendlyCredentials(obj));

    result
      .then((response) => {
        console.log('First response', response);
        return response; 
      })
      .then((data) => {
        // console.log('Data received: by jp', data);
        if(data == 'true'){
          toast.success("Calendly integrated successfully !");
          setSelectedCheckbox('showAllEvent')
          localStorage.removeItem('AccessToken')
          localStorage.removeItem('owner')
          localStorage.removeItem('code')
        }
      })
      .catch((error) => {
        console.error('Error:', error); 
      });


    ContacthandleClose();
  }



  const ContacthandleClose = () => {
    dispatch(setOpenIntegrationModal(false))
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('owner')
    localStorage.removeItem('code')
    setEventChackVisible(false)
  };



  const openHelp = () => {
    // window.open('https://www.docusoft.net/')
    dispatch(setOpenCalandlyInstructionModal(true))
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={ContacthandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
        sx={{ "& .MuiDialog-paper": { width: "50%" } }}
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Calendly Integration"}</DialogTitle>
          </Box>
          <Button onClick={ContacthandleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent className=' white-box '>

          <Box sx={{ flexGrow: 1 }} >
            <Grid container spacing={2}>
              {/* <Grid item xs={6} md={6}>
         <Box className= ' mt-3'>
              <AutoComplete
                  lable="Calendly"
                  className="w-100"
                  ComData={DemoArray ? DemoArray : []}
                  target="Calendly"
                //   comFun={getForwardUsernObject}
                  // defaultValue={
                  //   Object.keys(mainObject).length !== 0 && mainObject.FromUser
                  //     ? mainObject?.FromUser
                  //     : ""
                  // }
                ></AutoComplete>
         </Box>
        </Grid> */}
              <Grid item xs={12} md={12}>
                <Box>
                  <DocuTextField size={'small'} label={'Secret Key'} defaultValue={clientKey} fieldValue={clientKey} onChange={(event) => {
                    setCleinetKey(event.target.value);
                    console.log('Secret Key:', event.target.value);
                  }} />
                  <DocuTextField size={'small'} label={'Client Key'} defaultValue={secretKey}  fieldValue={secretKey} onChange={(event) => {
                    setSecretKey(event.target.value);
                    console.log('Secret Key:', event.target.value);
                  }}/>
                </Box>

                <Box className=' d-flex justify-content-end '>

                  <Button
                    id="basic-button"
                    aria-haspopup="true"
                    className="btn-blue "
                    onClick={validateCleintKey}
                  >
                    Validate Key
                  </Button>

                </Box>

                <div className=" d-flex justify-content-end mt-2">
                  <Typography
                    className="font-12 text-primary  underlined-typography me-3 mt-1"
                    onClick={openHelp}
                  >
                    Help
                  </Typography>
                  <Button
                    startIcon={<SendIcon />}
                    id="basic-button"
                    aria-haspopup="true"
                    className="btn-blue "
                    //   onClick={BookMeetingBuuton}
                    onClick={ContacthandleClose}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>

            </Grid>
          </Box>

        </DialogContent>
      </Dialog>
    </>
  )
}

export default IntegrationModal